import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Switch, Route } from "react-router-dom";

/** load components **/
import PageHeader from "@components/Layout/PageHeader";
import VehicleInfoBlock from "@components/Vehicle/General/VehicleInfoBlock";
import VehicleGeneral from "@containers/Vehicles/Tabs/VehicleGeneral";

import * as vehiclesActions from "@store/vehicles/actions";

/** load helpers **/
import { t } from "@helpers/i18n";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import FlashAlertIcon from "mdi-react/FlashAlertIcon";
import CarIcon from "mdi-react/CarIcon";
import Navigation from "@components/ProfileNavigation/ProfileNavigation.component";
import VehiclesCarCheckComponent from "@containers/Vehicles/Tabs/VehiclesCarCheck";
import VehicleDamagesComponent from "@containers/Vehicles/Tabs/VehicleDamages";
import VehicleTags from '@containers/Vehicles/Tabs/VehicleTags';
import VehicleRegistration from "@containers/Vehicles/Tabs/VehicleRegistration";
import {activeTabIndex} from "@services/utils";

const Vehicle = () => {
  const { pathname } = useLocation();

  const { id } = useParams();
  const dispatch = useDispatch();

  const { vehicle } = useSelector((state) => state.vehicles);

  useEffect(() => {
    dispatch(vehiclesActions.vehicleGeneralInfo({ id }));
    dispatch(vehiclesActions.loadVehicleLocation({id}));
  }, [id]);


  /** Vehicle tabs **/
  const routes = [
    {
      key: "general-info",
      icon: <AccountOutlineIcon />,
      path: `/vehicle/${id}/info`,
    },
    {
      key: "vehicle-registration",
      icon: <CardAccountDetailsOutlineIcon />,
      path: `/vehicle/${id}/info/registration`,
    },
    {
      key: "car-checks",
      icon: <CarIcon />,
      path: `/vehicle/${id}/info/car-checks`,
    },
    {
      key: "damages",
      icon: <FlashAlertIcon />,
      path: `/vehicle/${id}/info/damages`,
    },
  ];

  const renderHeader = () => {
    return (
      <Navigation routes={routes} activeIndex={activeTabIndex(routes, pathname)} horizontal={true} />
    );
  };

  return (
    <div id="vehicle-container" className="details-container">
      <PageHeader text={t("back")} profile={true} backLink={'/vehicles'}>
        <div className="header-info-row">
          <VehicleInfoBlock
            avatar={vehicle?.carAvatar || ""}
            tags={vehicle?.tags || []}
            name={vehicle?.manufacturer || ""}
            type={vehicle?.vehicleModelType || ""}
            license={vehicle?.licencePlate || ""}/>
          {renderHeader()}
        </div>
      </PageHeader>
      <div className={'flex-1 relative'}>
        <Switch>
          <Route
            path={`/vehicle/:id/info`}
            exact
            component={VehicleGeneral} />
          <Route
            path={`/vehicle/:id/info/registration`}
            exact
            component={VehicleRegistration}
          />
          <Route
            path={`/vehicle/:id/info/geo-fence`}
            exact
            component={() => <h1>Geofence</h1>}
          />
          <Route
            path={`/vehicle/:id/info/car-checks`}
            exact
            component={VehiclesCarCheckComponent}
          />
          <Route
            path={`/vehicle/:id/info/damages`}
            exact
            component={VehicleDamagesComponent}
          />
          <Route
            path={'/vehicle/:id/info/tags'}
            exact
            component={VehicleTags} />
        </Switch>
      </div>
    </div>
  );
};

export default Vehicle;
