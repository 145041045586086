import React, { useState } from "react";
import SignUp from './SignUp/index';
import { useDispatch, useSelector } from "react-redux";
import * as profileActions from '@store/profile/actions';


/** Load components **/
import InfoPopup from "./SignUp/PopupInfo";

import { useHistory } from "react-router-dom";
import {Spinner} from "@components/Spinner";
import dayjs from "dayjs";
import {DATE_FORMATS} from "@helpers/constants";


const SignUpContainer = () => {

  const {loading} = useSelector(state => state.profile);
  /** Component states **/
  const [popupVisible, setPopupVisible] = useState(false); // show should popup after registration
  const [signUpResponse, setSignUpResponse] = useState({}); // set popup modal data

  // store dispatch action
  const dispatch = useDispatch();

  // use history hook
  const history = useHistory();

  /** load popup info modal **/
  const popupInfo = (serverResponse) => {
    setPopupVisible(true);
    setSignUpResponse(serverResponse);
  };

  /** navigate to login page **/
  const logIn = () => {
    history.push('/');
  };

  const createUser = (userData) => {
    const { digit1, digit2, digit3, digit4, digit5, digit6 } = userData;
    const {
      email,
      phone,
      password,
      name,
      surname,
      addressStreet,
      addressNumber,
      addressZipcode,
      addressCity,
      birthday,
      stations } = userData;
    const customerNumber = digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
    const address = {
      street: addressStreet,
      number: addressNumber,
      zipcode: addressZipcode,
      city: addressCity,
    };
    const payloadUserData = {
      id: null,
      name,
      surname,
      birthday: dayjs(birthday).format(DATE_FORMATS.full_YMD_dash.toUpperCase()),
      stations: stations.value,
      customerNumber,
      address,
      language: '',
      email,
      phone,
      password,
    };
    dispatch(profileActions.setUser({
      userData: payloadUserData, callBack: popupInfo
    }));
  };


  return (<div className={'register'}>
    {loading &&  <Spinner />}
    <SignUp signUpUserAction={createUser} />
    <InfoPopup visible={popupVisible} response={signUpResponse} closePopup={logIn} />
  </div>);
};

export default SignUpContainer;
