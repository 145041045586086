export const DAMAGE_GET_REQUEST = 'DAMAGE_GET_REQUEST';
export const DAMAGE_GET_SUCCESS = 'DAMAGE_GET_SUCCESS';
export const DAMAGE_GET_FAIL = 'DAMAGE_GET_FAIL';

export const DAMAGE_FILTER_GET_REQUEST = 'DAMAGE_FILTER_GET_REQUEST';
export const DAMAGE_FILTER_GET_SUCCESS = 'DAMAGE_FILTER_GET_SUCCESS';
export const DAMAGE_FILTER_GET_FAIL = 'DAMAGE_FILTER_GET_FAIL';

export const DAMAGE_GET_MORE_REQUEST = 'DAMAGE_GET_MORE_REQUEST';
export const DAMAGE_GET_MORE_SUCCESS = 'DAMAGE_GET_MORE_SUCCESS';
export const DAMAGE_GET_MORE_FAIL = 'DAMAGE_GET_MORE_FAIL';

export const DAMAGE_GET_BY_ID_REQUEST = 'DAMAGE_GET_BY_ID_REQUEST';
export const DAMAGE_GET_BY_ID_SUCCESS = 'DAMAGE_GET_BY_ID_SUCCESS';
export const DAMAGE_GET_BY_ID_FAIL = 'DAMAGE_GET_BY_ID_FAIL';

export const DAMAGE_GET_BY_ID_DETAILED_REQUEST = 'DAMAGE_GET_BY_ID_DETAILED_REQUEST';
export const DAMAGE_GET_BY_ID_DETAILED_SUCCESS = 'DAMAGE_GET_BY_ID_DETAILED_SUCCESS';
export const DAMAGE_GET_BY_ID_DETAILED_FAIL = 'DAMAGE_GET_BY_ID_DETAILED_FAIL';

export const DAMAGE_GET_BY_ID_IMG_DETAILED_CLEAR = 'DAMAGE_GET_BY_ID_IMG_DETAILED_CLEAR';

export const DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST = 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST';
export const DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS = 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS';
export const DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL = 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL';

export const DAMAGE_GET_BY_ID_IMG_DETAILED_REQUEST = 'DAMAGE_GET_BY_ID_IMG_DETAILED_REQUEST';
export const DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS = 'DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS';
export const DAMAGE_GET_BY_ID_IMG_DETAILED_FAIL = 'DAMAGE_GET_BY_ID_IMG_DETAILED_FAIL';

export const DAMAGE_GET_INCOMPLETE_REQUEST = 'DAMAGE_GET_INCOMPLETE_REQUEST';
export const DAMAGE_GET_INCOMPLETE_SUCCESS = 'DAMAGE_GET_INCOMPLETE_SUCCESS';
export const DAMAGE_GET_INCOMPLETE_FAIL = 'DAMAGE_GET_INCOMPLETE_FAIL';

export const DAMAGE_UPLOAD_ALL_IMAGES_REQUEST = 'DAMAGE_UPLOAD_ALL_IMAGES_REQUEST';
export const DAMAGE_UPLOAD_ALL_IMAGES_SUCCESS = 'DAMAGE_UPLOAD_ALL_IMAGES_SUCCESS';
export const DAMAGE_UPLOAD_ALL_IMAGES_FAIL = 'DAMAGE_UPLOAD_ALL_IMAGES_FAIL';

export const DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST = 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST';
export const DAMAGE_UPLOAD_IMAGE_INCOMPLETE_SUCCESS = 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_SUCCESS';
export const DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL = 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL';

export const DAMAGE_COMPLETE_REQUEST = 'DAMAGE_COMPLETE_REQUEST';
export const DAMAGE_COMPLETE_SUCCESS = 'DAMAGE_COMPLETE_SUCCESS';
export const DAMAGE_COMPLETE_FAIL = 'DAMAGE_COMPLETE_FAIL';

export const GET_EXPORT_DAMAGE_REQUEST = 'GET_EXPORT_DAMAGE_REQUEST';
export const GET_EXPORT_DAMAGE_SUCCESS = 'GET_EXPORT_DAMAGE_SUCCESS';
export const GET_EXPORT_DAMAGE_FAIL = 'GET_EXPORT_DAMAGE_FAIL';

export const GET_IMG_REQUEST = 'GET_IMG_REQUEST';
export const GET_IMG_REQUEST_SUCCESS = 'GET_IMG_REQUEST_SUCCESS';
export const GET_IMG_REQUEST_EMPTY = 'GET_IMG_REQUEST_EMPTY';
export const GET_IMG_REQUEST_FAIL = 'GET_IMG_REQUEST_FAIL';

export const GET_IMG_REQUEST_ARRAY = 'GET_IMG_REQUEST_ARRAY';
export const GET_IMG_REQUEST_ARRAY_SUCCESS = 'GET_IMG_REQUEST_ARRAY_SUCCESS';
export const GET_IMG_REQUEST_ARRAY_EMPTY = 'GET_IMG_REQUEST_ARRAY_EMPTY';
export const GET_IMG_REQUEST_ARRAY_FAIL = 'GET_IMG_REQUEST_ARRAY_FAIL';

export const CLEAR_IMG_REQUEST = 'CLEAR_IMG_REQUEST';
export const SET_CURRENT_DAMAGE = 'SET_CURRENT_DAMAGE';
export const CLEAN_CURRENT_DAMAGE = 'CLEAN_CURRENT_DAMAGE';

export const REPORT_DAMAGE_REQUEST = 'REPORT_DAMAGE_REQUEST';
export const REPORT_DAMAGE_SUCCESS = 'REPORT_DAMAGE_SUCCESS';
export const REPORT_DAMAGE_FAIL = 'REPORT_DAMAGE_FAIL';

export const SEND_RECLAMATION_RESET = 'SEND_RECLAMATION_RESET';
export const SEND_RECLAMATION_REQUEST = 'SEND_RECLAMATION_REQUEST';
export const SEND_RECLAMATION_SUCCESS = 'SEND_RECLAMATION_SUCCESS';
export const SEND_RECLAMATION_FAIL = 'SEND_RECLAMATION_FAIL';

export const REMOVE_DAMAGE_SUCCESS = 'REMOVE_DAMAGE_SUCCESS';

export const DAMAGE_ADD_INFO_REQUEST = 'DAMAGE_ADD_INFO_REQUEST';
export const DAMAGE_ADD_INFO_SUCCESS = 'DAMAGE_ADD_INFO_SUCCESS';
export const DAMAGE_ADD_INFO_FAIL = 'DAMAGE_ADD_INFO_FAIL';


export const DAMAGE_UPLOAD_INFO_IMAGES_REQUEST = 'DAMAGE_UPLOAD_INFO_IMAGES_REQUEST';
export const DAMAGE_UPLOAD_INFO_IMAGES_SUCCESS = 'DAMAGE_UPLOAD_INFO_IMAGES_SUCCESS';
export const DAMAGE_UPLOAD_INFO_IMAGES_FAIL = 'DAMAGE_UPLOAD_INFO_IMAGES_FAIL';

export const DAMAGE_UPLOAD_COMPLETE_REQUEST = 'DAMAGE_UPLOAD_COMPLETE_REQUEST';
export const DAMAGE_UPLOAD_COMPLETE_SUCCESS = 'DAMAGE_UPLOAD_COMPLETE_SUCCESS';
export const DAMAGE_UPLOAD_COMPLETE_FAIL = 'DAMAGE_UPLOAD_COMPLETE_FAIL';
