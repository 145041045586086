/** Load regex validations **/
import {
  EMAIL_REGEX,
  PHONE_REGEX
} from "@constants";

export const damageLocationValidation = (stepData) => {
  const validateField = (fieldName) => {
    if (!stepData[fieldName]) {
      return 'field_required';
    }
    return null;
  };
  const damageLocationValidation = {
    driverName: validateField('driverName'),
    street: validateField('street'),
    number: validateField('number'),
    zipcode: validateField('zipcode'),
    city: validateField('city'),
    country: validateField('country'),
    accidentDateAndTime: validateField('accidentDateAndTime')
  };

  // the data is valid if none of the fields contains the error message
  damageLocationValidation['isValid'] = Object.values(damageLocationValidation).filter(value => !!value).length === 0;

  return damageLocationValidation;
};

export const vehicleInformationValidation = (stepData) => {
  const validateField = (fieldName) => {
    if (fieldName === 'damageImages' && stepData[fieldName]?.length < 3) {
      return 'damage_images_error';
    }

    // this will come to play once it's implemented on the backend

    if (fieldName === 'policeInformation') {
      if (stepData?.policeInvolved === 'no') {
        return null;
      }
      return stepData['policeInformation']?.length ? null : 'field_required';
    }

    if (!stepData[fieldName]) {
      return 'field_required';
    }
    return null;
  };

  const vehicleInfoValidation = {
    licensePlate: validateField('licensePlate'),
    mileage: validateField('mileage'),
    damageType: validateField('damageType'),
    damageImages: validateField('damageImages'),
    policeImages: validateField('policeImages'),
    description: validateField('description'),
    policeInvolved: validateField('policeInvolved'),
    policeInformation: validateField('policeInformation'),
  };

  // the data is valid if none of the fields contains the error message
  vehicleInfoValidation['isValid'] = Object.values(vehicleInfoValidation).filter(value => !!value).length === 0;

  return vehicleInfoValidation;
};

export const thirdPartyValidation = (hasThirdParty, stepData) => {

  const validateField = (fieldName) => {
    if (fieldName === 'thirdPartyDamageImages' && stepData[fieldName].length > 0) {
      if (stepData[fieldName].length < 3) {
        return 'damage_images_error';
      }
    }
    if (fieldName === 'thirdPartyLicensePlate') {
      if (stepData[fieldName]?.length > 0 && stepData[fieldName]?.length < 8) {
        return 'field_required';
      } else {
        return null;
      }
    }
    if (fieldName === 'thirdPartyEmail') {
      if (stepData[fieldName].length > 0 && !stepData[fieldName].match(EMAIL_REGEX)) {
        return 'invalid_address';
      } else {
        return null;
      }
    }
    if (fieldName === 'thirdPartyPhone' && stepData[fieldName].length > 0) {
      if (!stepData[fieldName].match(PHONE_REGEX)) {
        return 'invalid_phone_number';
      } else {
        return null;
      }
    }
    if (!stepData[fieldName]) {
      return 'field_required';
    }
    return null;
  };

  const thirdPartyValidation = {
    thirdPartyLicensePlate: validateField('thirdPartyLicensePlate'),
    thirdPartyPhone: validateField('thirdPartyPhone'),
    thirdPartyEmail: validateField('thirdPartyEmail'),
    thirdPartyDamageImages: validateField('thirdPartyDamageImages'),
  };



  // the data is valid if none of the fields contains the error message
  thirdPartyValidation['isValid'] = hasThirdParty ? Object.values(thirdPartyValidation).filter(value => !!value).length === 0 : true;
  return thirdPartyValidation;
};
