import React from 'react';

/** Load styles **/
import './style.scss';

const CarCheckImage = ({ imgSrc = '', alt = 'carCheck', onImageClick = () => {} }) => {
  return (
    <div className={'car-check-wrapper'} onClick={onImageClick}>
      <img className="car-check-image" src={`data:image/png;base64, ${imgSrc}`} alt={alt} />
    </div>
  );
};

export default CarCheckImage;
