import React from 'react';

/** load icon **/
import LogoutIcon from "mdi-react/LogoutIcon";

/** load translation tool **/
import { t } from "@helpers/i18n";

import {useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as authActions from "@store/auth/actions";


/** load styles **/
import './style.scss';


const Logout = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  /** Logout User action **/
  const logOutUser = () => {
    dispatch(authActions.authLogout());
    history.push('/');
  };

  return (
    <div
      className={"bottom-actions"}
      onClick={logOutUser}
    >
      <LogoutIcon />
      <div className="label">
        {t("logout")}
      </div>
    </div>
  );
};

export default Logout;
