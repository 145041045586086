import React, { useState } from 'react';
/** Loading components **/
import TripView from '@containers/Trips/Trip/TripDetails/TripView';
import TripEdit from '@containers/Trips/Trip/TripDetails/TripEdit';

const TripForms = ({ trip = {}, drawDirections = () => {}, onTripSave = () => {} }) => {
  /**
   * local state
   */
  const [isEdit, setIsEdit] = useState(false); // detect if is edit action

  /**
   * Driver Edit toggle form display
   * @param flag
   */
  const toggleFormDisplay = (flag) => {
    setIsEdit(flag);
  };

  const onTripSaveAction = (tripDetails) => {
    if (tripDetails.driver === null && tripDetails.type === null) {
      // close edit form
      setIsEdit(false);
    } else {
      onTripSave(tripDetails);
    }
  };

  /**
   * Draw again Map for whole trip once edit form is closed
   */
  const onCloseEditForm = () => {
    toggleFormDisplay(false);
    drawDirections();
  };

  return (
    <div className={'form-container h-100'}>
      {isEdit ? (
        <TripEdit trip={trip} onSaveTrip={onTripSaveAction} onClose={onCloseEditForm} />
      ) : (
        <TripView trip={trip} drawDirections={drawDirections} onEditClick={() => toggleFormDisplay(true)} />
      )}
    </div>
  );
};

export default TripForms;
