import { Api } from "@api";

export const fetchDailyRentReportFile = async (id, type, name) => {
  try {
    const { data } = await Api.media.getContractPdf({ id, type, name });
    if(!data.blob){
      throw new Error('Document blob not found in response');
    };
    return data;
  } catch (error) {
    console.error('Error while fetching data pdf: ', error.message);
    return false;
  }
};

export const downloadDailyRentReport = (file) => {
  const linkSource = `data:application/pdf;base64,${file.blob}`;
  const downloadLink = document.createElement("a");
  const fileName = file?.name ?? 'Daily-rent-car-check-report';
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};