import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as tripActions from '@store/trips/actions';

/** Loading MUI components **/
import Grid from '@mui/material/Grid';

/** Loading components **/
import TripMap from '@components/Trip/SingleTrip/TripMap/TripMap.component';
import TripForms from '@containers/Trips/Trip/TripDetails/TripForm';

/** Loading styles **/
import './TripDetails.styles.scss';

const TripDetails = (props) => {
  const { trip } = props;
  const { id, tripDate } = useParams();

  /**
   * mapRef object and dispatch
   * @type {React.MutableRefObject<null>}
   */
  const mapRef = useRef(null);
  const dispatch = useDispatch();

  /**
   * format Trip object to draw route on map
   * @param {object} trip
   */
  const formatWholeTrip = (trip) => {
    const { tracks } = trip;
    const allWaypoints = tracks.map((track) => {
      if (track.waypoints && track.waypoints.length) {
        return track.waypoints;
      }

      // sometimes waypoints array is empty so return start & stop position
      return [track['starting-position'], track['stop-position']];
    });

    return allWaypoints.flat().filter((val) => val !== undefined && val !== null);
  };

  /**
   * format single Track to draw route on map
   * @param {object} track
   */
  const formatSingleTrack = (track) => {
    let wayPoints = [];
    if (track?.waypoints) {
      wayPoints = track?.waypoints.filter((val) => val !== undefined);
      // waypoints can contain
      if (wayPoints.length < 3) {
        wayPoints.unshift(track['starting-position']);
        wayPoints.push(track['stop-position']);
      }
    } else {
      wayPoints.unshift(track['starting-position']);
      wayPoints.push(track['stop-position']);
    }
    return wayPoints;
  };

  /**
   * Send waypoints to Map container
   * @param track
   */
  const drawDirections = (track) => {
    const cords = track ? formatSingleTrack(track) : formatWholeTrip(trip);
    mapRef.current.drawDirections(cords);
  };

  /**
   * Draw whole trip on map, when details initialy loaded
   * called from child component
   */
  const drawTrip = () => {
    const cords = formatWholeTrip(trip);
    mapRef.current.drawDirections(cords);
  };

  /**
   * Saving edit tracks or whole day trip
   * @param {object} tripDetails
   */
  const onSaveTripDetails = (tripDetails) => {
    if (tripDetails.wholeTrip) {
      const { driver, type } = tripDetails;
      dispatch(
        tripActions.tripUpdate({
          carId: id,
          driver,
          tripType: type,
          trips: [],
          tripDate
        })
      );
    } else {
      const { driver, type } = tripDetails;
      dispatch(
        tripActions.tripUpdate({
          carId: id,
          driver,
          tripType: type,
          trips: tripDetails.editedTrips,
          tripDate
        })
      );
    }
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={7}>
        <div id="trip-details" className={'details-content flex flex-col justify-between p-b-2 relative'}>
          <TripForms trip={trip} drawDirections={drawDirections} onTripSave={onSaveTripDetails} />
        </div>
      </Grid>
      <Grid item xs={5}>
        <TripMap ref={mapRef} drawTrip={drawTrip} />
      </Grid>
    </Grid>
  );
};

export default TripDetails;
