import React from 'react';

/** load components **/
import InfoBlock from "@components/User/InfoBlock";
import Avatar from "@components/User/Avatar";

/** load styles **/
import './style.scss';

const UserProfileBlock = (props) => {

  const { name, surname, avatar, customerNumber } = props;

  return (<div className={'header-profile-user'}>
    <InfoBlock
      name={`${name} ${surname}`}
      avatarElement={
        <Avatar
          src={avatar}
        />
      }
      vertical={true}
      bottomElement={
        <div className="customer-id">
          {customerNumber}
        </div>
      }
    />
  </div>);
};

export default UserProfileBlock;
