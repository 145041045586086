import React, {memo} from "react";

/** Loading components **/
import {LicensePlate} from "@components/Car/LicensePlate";

/** Loading helper functions and formatters **/
import {t} from "@helpers/i18n";
import {format} from "date-fns";

/** Loading styles **/
import './AppointmentSummary.styles.scss';


const AppointmentSummary = (props) => {

  /**
   * extracting props
   */
  const {
    serviceOrder,
    dateDetails,
    garageDetails,
    profile,
    isRequest,
    serviceType,
    tireInformation
  } = props;


  /**
   * It takes the serviceType array, checks if it contains the numbers 1, 2, 3, or 4,
   * and if it does, it pushes the corresponding service name to the services array
   * @returns An array of p elements with the className of service-label.
   */
  const serviceRender = () => {
    const services = [];
    if(serviceType.includes('1')){
      services.push(t('tire-change'));
    }
    if (serviceType.includes('2')) {
      services.push(t('service_inspection'));
    }
    if (serviceType.includes('3')) {
      services.push(t('brakes'));
    }
    if (serviceType.includes('4')) {
      services.push(t('other'));
    }
    return services.map((service, k) => <p className={'service-label'} key={k}>{service}</p>);
  };

  return(
    <div>
      <div className={'summary-main-container'}>
        <h3 className={'card-title'}>{t("summary")}</h3>
        <p style={{marginTop:"20px"}}>{`${profile.name} ${profile.surname}`}</p>
        <div style={{margin: '10px 0'}}>
          <LicensePlate
            country="D"
            license={dateDetails.licensePlate.toUpperCase()}
          />
        </div>

        {serviceRender()}

        <p className={'input-label'}>{t("preferred_dates")}</p>
        {!isRequest && <p>{format(new Date(dateDetails.appointmentDate), 'dd.MM.yyyy, HH:mm')}</p>}
        {isRequest &&
            <>
              <p>{format(new Date(dateDetails.wishDate1), 'dd.MM.yyyy, HH:mm')}</p>
              <p>{format(new Date(dateDetails.wishDate2), 'dd.MM.yyyy, HH:mm')}</p>
              <p>{format(new Date(dateDetails.wishDate3), 'dd.MM.yyyy, HH:mm')}</p>
            </>
        }
        <p className={'input-label'}>{t("preferred_garage")}</p>
        <p>{garageDetails.garageName}</p>
        <p>{garageDetails.streetAndNumber}</p>
        <p>{`${garageDetails.zipcode} ${garageDetails.city}`}</p>
        <p className={'input-label'}>{t("confirmation_to")}</p>
        <p>{profile.username}</p>
        {(serviceOrder.orderDescription && serviceOrder.orderDescription !== 'default' || serviceOrder.message) && <p className={'input-label'}>{t("service_inspection")}</p>}
        {serviceOrder.orderDescription && serviceOrder.orderDescription !== 'default' && <p>{t('job_desc')}: {serviceOrder.orderDescription}</p>}
        {serviceOrder.message && <p>{t('comment')}: {serviceOrder.message}</p>}

        {(tireInformation && (tireInformation?.tyre_fl.state || tireInformation?.tyre_fr.state || tireInformation?.tyre_rl.state || tireInformation?.tyre_rr.state || tireInformation?.message ))
        &&
        <>
          <p className={'input-label'}>{t("tire-change")}</p>
          {tireInformation.tyre_fl.state && <p>{t('front-left')}: {tireInformation.tyre_fl.val} mm</p>}
          {tireInformation.tyre_fr.state && <p>{t('front-right')}: {tireInformation.tyre_fr.val} mm</p>}
          {tireInformation.tyre_rl.state && <p>{t('rear-left')}: {tireInformation.tyre_rl.val} mm</p>}
          {tireInformation.tyre_rr.state && <p>{t('rear-right')}: {tireInformation.tyre_rr.val} mm</p>}
          {tireInformation.message && <p>{t('comment')}: {tireInformation.message}</p>}
        </>
        }

      </div>
    </div>
  );
};

export default memo(AppointmentSummary);
