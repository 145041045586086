import React from 'react';

/** load components **/
import { LicensePlate } from '@components/Car/LicensePlate';

/** load svg-s and formatters **/
import ArrowUp from '@assets/svg/vehicles/angel-up.svg';
import ArrowDown from '@assets/svg/vehicles/angel-down.svg';
import { mileageFormatter } from '@services/formatters';

/** load styless **/
import './VehicleInfo.styles.scss';

const VehicleInfo = ({ mileage = '0', vehicleId = '', icon, expanded = false, onExpandButtonClick = () => {} }) => {
  return (
    <>
      <p>{mileageFormatter(mileage)} km</p>
      <p>
        {icon.leftIcon}
        {icon.rightIcon}
        {icon.checkIcon}{' '}
      </p>
      <div style={{ display: 'flex' }}>
        <img
          className={'vehicle-expand-arrow'}
          src={expanded ? ArrowUp : ArrowDown}
          alt={'arrow'}
          onClick={onExpandButtonClick}
        />
        <LicensePlate country="D" license={vehicleId} />
      </div>
    </>
  );
};

export default VehicleInfo;
