import React from 'react';

import CloseIcon from 'mdi-react/CloseIcon';
import './Modal.style.scss';

const Modal = ({ isVisible = false, className, children, onClose = () => {} }) => {
  return (
    isVisible && (
      <div className={`modal ${className}`}>
        <div className="modal-container">
          <div className="modal-header">
            <div className="close-button" onClick={onClose}>
              <CloseIcon size={64} />
            </div>
          </div>
          <div className="modal-content">{children}</div>
          <div className="modal-footer"></div>
        </div>
      </div>
    )
  );
};

export default Modal;
