// Importing necessary hooks and components from React and related packages.
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useLocation, Switch, Route} from "react-router-dom";

// Importing helper function and utility function.
import {t} from "@helpers/i18n";
import {activeTabIndex} from "@services/utils";

// Importing Redux actions.
import * as dailyRentActions from "@store/dailyRent/actions";

// Importing Icons from mdi-react package.
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EuroIcon from "mdi-react/EuroIcon";
import CarIcon from "mdi-react/CarIcon";

// Importing custom components and containers.
import AnimatedLoader from '@components/Common/AnimatedLoader';
import PageHeader from "@components/Layout/PageHeader";
import ProfileInfoBlock from "@components/ProfileInfoBlock";
import ProfileNavigation from "@components/ProfileNavigation/ProfileNavigation.component";
import DailyRentReservation from "@containers/DailyRent/DailyRentDetails/DailyRentReservation";
import DailyRentContract from "@containers/DailyRent/DailyRentDetails/DailyRentContract";
import DailyRentCarCheckReport from "./DailyRentCarCheckReport";

const DailyRentDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {id, rentalType} = params;
  const { pathname } = useLocation();
  const { auth } = useSelector(state => state.auth);
  const { loading = true, dailyRentDetail } = useSelector(state => state.dailyRent);

  useEffect(() => {
    dispatch(dailyRentActions.dailyRentDetailsGet({ userId: auth.user.id, dailyRentId: id, rentalType }));
  }, [auth.user.id, id, rentalType]);

  /** DailyRent tabs **/
  const routes = [
    {
      key: `dailyRentSubMenu.${rentalType}`,
      icon: <AccountOutlineIcon />,
      path: `/daily-rent/${rentalType}/${id}`,
    },
    {
      key: "dailyRentSubMenu.invoices",
      icon: <EuroIcon />,
      path: `/invoices`,
      state: {contractId: dailyRentDetail?.contractId, reservationId: dailyRentDetail?.reservationId}
    },
    {
      key: "dailyRentSubMenu.handover",
      icon: <CarIcon />,
      path: `/daily-rent/${rentalType}/${id}/handover-report/${ dailyRentDetail?.handoverCarcheckId }`,
    },
    {
      key: "dailyRentSubMenu.return",
      icon: <CarIcon />,
      path: `/daily-rent/${rentalType}/${id}/return-report/${ dailyRentDetail?.returnCarcheckId }`,
    },
  ];

  const renderHeader = () => (
    <div className="header-info-row">
      <ProfileInfoBlock
        name={`${auth?.user?.name} ${auth?.user?.surname}`}
        customerNumber={auth?.info?.customerNumber}
        company={auth?.info?.company}
        avatar={auth?.user?.avatar}
        licensePlate={dailyRentDetail?.vehicle?.licencePlate}
      />
      <ProfileNavigation
        routes={routes}
        activeIndex={activeTabIndex(routes, pathname)}
        horizontal={true}
      />
    </div>
  );

  const renderDailyRentContainer = () => (
    <div id="daily-rent-container" className="details-container">
      <PageHeader text={t("back")} profile={true} backLink={'/daily-rent'}>
        {renderHeader()}
      </PageHeader>
      <div className={'flex-1'}>
        <Switch>
          <Route
            path={`/daily-rent/:rentalType/:id`}
            exact
            render={(props) => rentalType === 'reservation' ? <DailyRentReservation {...props} dailyRentDetail={dailyRentDetail} /> : <DailyRentContract {...props} dailyRentDetail={dailyRentDetail}/>}
          />
          <Route
            path={`/daily-rent/:renatalType/:id/handover-report/:carCheckId`}
            exact
            render={() => <DailyRentCarCheckReport reportType="handover-report" />}
          />
          <Route
            path={`/daily-rent/:rentalType/:id/return-report/:carCheckId`}
            exact
            render={() => <DailyRentCarCheckReport reportType="return-report" />}
          />
        </Switch>
      </div>
    </div>
  );

  return (
    <>
      {loading ? <AnimatedLoader /> : renderDailyRentContainer()}
    </>
  );
};

export default DailyRentDetails;
