import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const ScreenLoader = (props) => {
  const { isVisible = false, className = '' } = props;

  if (isVisible) {
    return (
      <div className={`modal ${className} flex items-center justify-center`}>
        <div className="modal-content">
          <TailSpin color="#FFF" height={80} width={80} />
        </div>
      </div>
    );
  }
  return <></>;
};

export default ScreenLoader;
