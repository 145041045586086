import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";

import * as employeesActions from "@store/employees/actions";
import * as employeesConstants from '@store/employees/constants';

function* getEmployees(action) {
  try {
    const { userId, page, filter, search } = action.payload;
    let query = `${userId}/drivers?page=${page}${filter}`;

    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.employees.getEmployees, query);
    yield put(employeesActions.employeeGetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(employeesActions.employeeGetFail(err));
  }
}

function* loadMoreEmployees(action) {
  try {
    const { userId, page, filter, search } = action.payload;
    let query = `${userId}/drivers?page=${page}${filter}`;
    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.employees.getEmployees, query);

    yield put(employeesActions.employeeLoadMoreSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(employeesActions.employeeLoadMoreFail(err));
  }
}

function* getEmployeeById(action) {
  try {
    const {userId, employeeId} = action.payload;
    const {data} = yield call(Api.employees.getSingleEmployee, {employeeId, userId});

    yield put(employeesActions.employeeGetByIdSuccess(data[0]));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(employeesActions.employeeGetByIdFail(err));
  }
}

function* getEmployeeCarChecks(action) {
  try {
    const {employeeId, page = 0} = action.payload;
    
    const {data} = yield call(Api.employees.getEmployeeCarChecks, {employeeId, page});

    yield put({type: employeesConstants.EMPLOYEE_GET_CAR_CHECKS_SUCCESS, payload: data});

  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: employeesConstants.EMPLOYEE_GET_CAR_CHECKS_FAIL});
  }
}

function* loadMoreEmployeeCarChecks(action) {
  try {
    const {employeeId, page = 0} = action.payload;
    const {data} = yield call(Api.employees.getEmployeeCarChecks, {employeeId, page});

    yield put({type: employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_SUCCESS, payload: data});

  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_FAIL});
  }
}

function* getEmployeeDamages(action) {
  try {
    const {employeeId, page = 0} = action.payload;
    const {data} = yield call(Api.employees.getEmployeeDamages, {employeeId, page});

    yield put({type: employeesConstants.EMPLOYEE_GET_DAMAGES_SUCCESS, payload: data});

  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: employeesConstants.EMPLOYEE_GET_DAMAGES_FAIL});
  }
}


function* loadMoreEmployeeDamages(action) {
  try {
    const {employeeId, page = 0} = action.payload;
    const {data} = yield call(Api.employees.getEmployeeDamages, {employeeId, page});

    yield put({type: employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_SUCCESS, payload: data});

  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_FAIL});
  }
}

export function* employeeSagas() {
  yield takeLatest(employeesConstants.EMPLOYEE_GET_REQUEST, getEmployees);
  yield takeLatest(employeesConstants.EMPLOYEE_LOAD_MORE_REQUEST, loadMoreEmployees);
  yield takeLatest(employeesConstants.EMPLOYEE_GET_BY_ID_REQUEST, getEmployeeById);
  yield takeLatest(employeesConstants.EMPLOYEE_GET_CAR_CHECKS_REQUEST, getEmployeeCarChecks);
  yield takeLatest(employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_REQUEST, loadMoreEmployeeCarChecks);
  yield takeLatest(employeesConstants.EMPLOYEE_GET_DAMAGES_REQUEST, getEmployeeDamages);
  yield takeLatest(employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_REQUEST, loadMoreEmployeeDamages);
}
