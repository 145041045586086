import {allowedTyreValues} from "@constants";

// Common validation functions
const validateRequiredField = (fieldName, data) => (!data[fieldName] || data[fieldName] === 'default') ? 'field_required' : null;
const validateAllowedTireValues = (fieldName, data) => (data[fieldName]?.state && !allowedTyreValues.includes(data[fieldName]?.val)) ? 'tire_validation' : null;

// Higher-order function to generate validation functions
const makeValidator = (fields, validationFunction) => (stepData, otherSelected = true) => {
  const errors = {};

  fields.forEach(fieldName => {
    errors[fieldName] = validationFunction(fieldName, stepData);
  });

  errors['isValid'] = otherSelected ? !Object.values(errors).some(value => !!value) : true;

  return errors;
};

// Specific validation functions
export const appointmentServiceInspectionValidation = makeValidator(['orderDescription'], validateRequiredField);
export const appointmentDateDetailsValidation = makeValidator(['licensePlate', 'appointmentDate', 'wishDate1', 'wishDate2', 'wishDate3'], validateRequiredField);
export const appointmentGarageDetailsValidation = makeValidator(['garageName', 'streetAndNumber', 'zipcode', 'city'], validateRequiredField);
export const appointmentTireValidation = makeValidator(['tyre_fl', 'tyre_fr', 'tyre_rl', 'tyre_rr'], validateAllowedTireValues);
