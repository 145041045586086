import {format, parseISO} from "date-fns";

export const extractTime = (date) => {
  if (date && date !== '0000-00-00' && date !== '0000-00-00 00:00:00') {
    return format(parseISO(date), 'HH:mm');
  } else {
    return '--:--';
  }
};

export const toHoursAndMinutes = (totalMinutes) => {
  if (totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (hours) {
      return `${ hours }h ${ minutes }min`;
    } else {
      return `${ minutes }min`;
    }
  } else {
    return '-';
  }
};

export const toHoursMinSec = (totalSeconds) => {
  if (totalSeconds) {
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (hours) {
      return `${ hours }h ${ minutes }min ${ seconds }s`;
    } else if (minutes) {
      return `${ minutes }min ${ seconds }s`;
    } else {
      return `${ seconds }s`;
    }
  } else {
    return '-';
  }
};
