import React from 'react';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import { ContractFilterFormatter } from '@services/formatters/contracts/ContractsFilter.formatter';
import { t } from '@helpers/i18n';
import ContractFilterForm from '@components/Contracts/Filter/FilterForm/ContractFilterForm.component';

const ContractFilter = ({
  filterValues = [],
  visible = false,
  contractDates = {},
  onDateChange = () => {},
  filterChanged = () => {},
  applyFilter = () => {}
}) => {
  const filterChange = (e) => {
    const updatedFilters = ContractFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible && (
      <div className={'list-filter'}>
        <ContractFilterForm
          contractDates={contractDates}
          filters={filterValues}
          onChange={filterChange}
          onDateChange={onDateChange}
        />
        <ApplyButton onClick={applyFilter} text={t('apply')} />
      </div>
    )
  );
};

export default ContractFilter;
