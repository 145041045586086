import React from 'react';
/** Load components **/
import AlertCmp from '@components/Common/Alert';
/** Load translation helper **/
import { t } from '@helpers/i18n';

/** Load error constants **/
import { CUSTOMER_NUMBER, USERNAME_EXISTING } from '../../../../constants';

const InfoPopup = ({ visible = false, response = {}, closePopup = () => {} }) => {
  const { status, error_key } = response;

  const renderPopup = () => {
    if (status === 200) {
      return <AlertCmp visible={visible} title={t('sign_up_successfully')} onClick={closePopup} />;
    } else {
      if (error_key === USERNAME_EXISTING) {
        return <AlertCmp visible={visible} title={t('sign_up_another_email')} onClick={closePopup} />;
      }
      if (error_key === CUSTOMER_NUMBER) {
        return <AlertCmp visible={visible} title={t('error_customer_number_invalid')} onClick={closePopup} />;
      } else {
        return <AlertCmp visible={visible} title={error_key} onClick={closePopup} />;
      }
    }
  };

  return renderPopup();
};

export default InfoPopup;
