import React, { useEffect, useState, useRef, memo } from 'react';

import { Field, Formik } from 'formik';
import { Input } from '@components/Common/Input';
import { t } from '@helpers/i18n';
import Toggler from "@components/Common/Toggler";
import BlockingOverlayComponent from '@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component';
import { License } from "@components/Common/LicensePlate";
import MultipleUploadComponent from '@components/Common/MultipleUpload';
import Slider from '@components/Common/Slider';
import { toBase64 } from '@services/utils';
import { Grid } from '@mui/material';
import './VehicleThirdParty.styles.scss';
import Close from "mdi-react/CloseIcon";

const VehicleThirdParty = (props) => {

  const { stepData, hasThirdParty, setHasThirdParty, onChange, isDisabled, error } = props;

  const initialState = {
    thirdPartyLicensePlate: '',
    thirdPartyName: '',
    thirdPartySurname: '',
    thirdPartyStreet: '',
    thirdPartyStreetNum: '',
    thirdPartyZipCode: '',
    thirdPartyCity: '',
    thirdPartyCountry: '',
    thirdPartyPhone: '',
    thirdPartyEmail: '',
    thirdPartyDamageImages: []
  };


  /**
     * Local state for all changes in the step.
     *
     * On mount should be set to global data from parent,
     * so the form can be prefilled with data when going in between steps
     */
  const [data, setData] = useState(stepData || initialState);

  const [removedFiles, setRemovedFiles] = useState([]);

  const sliderRef = useRef();

  const [slides, setSlides] = useState(new Array([]).fill(null));



  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  /**
   * Toogle form visibility
   */
  const onToggleChange = () => {
    setHasThirdParty(!hasThirdParty);
    setData(initialState);
  };

  /**
   * Initial slider call when user clicks on image
   * @param {number} index
   **/
  const onPreviewImage = (index) => {
    sliderRef.current.previewImage(index);
  };

  const renderUploadedImages = () => {
    return stepData.thirdPartyDamageImages;
  };
  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = async (index) => {
    const filteredImages = renderUploadedImages();
    const image = await toBase64(filteredImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };



  /**
   * File upload field handler
   * @param fileList - newly uploaded files
   * @param currentValues - previously uploaded files
   * @param setFieldValue - form field value setter
   */
  const onFileUpload = (fileList, currentValues, setFieldValue) => {
    const fileArray = Array.from(fileList);
    // will compare with current files & return non duplicated images
    const withoutDuplicates = fileArray.filter((file, index) => {
      if (stepData.thirdPartyDamageImages) {
        const dmgImgs = stepData?.thirdPartyDamageImages.find((dmgImg) => {
          return (dmgImg.name === file.name) && (dmgImg.size === file.size) && (dmgImg.type === file.type);

        });
        return !dmgImgs;
      } else {
        return true;
      }
    });
    setFieldValue('thirdPartyDamageImages', [...currentValues, ...withoutDuplicates]);
    const slides = Array([...currentValues, ...withoutDuplicates].length).fill(null);
    setSlides(slides);
  };

  const onFileRemove = (index, setFieldValue) => {
    const updatedImages = stepData.thirdPartyDamageImages.filter((file, fileIndex) => fileIndex !== index);
    setFieldValue('thirdPartyDamageImages', updatedImages);
    const slides = Array(updatedImages.length).fill(null);
    setSlides(slides);
  };

  /**
   *
   * @param {string} propertyName
   * @param value
   */
  const onFieldChange = (propertyName, value) => {
    setData({ ...data, [propertyName]: value });
  };

  return (
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent />}
      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={
          {
            thirdPartyLicensePlate: data.thirdPartyLicensePlate,
            thirdPartyName: data.thirdPartyName,
            thirdPartySurname: data.thirdPartySurname,
            thirdPartyStreet: data.thirdPartyStreet,
            thirdPartyStreetNum: data.thirdPartyStreetNum,
            thirdPartyZipCode: data.thirdPartyZipCode,
            thirdPartyCity: data.thirdPartyCity,
            thirdPartyCountry: data.thirdPartyCountry,
            thirdPartyPhone: data.thirdPartyPhone,
            thirdPartyEmail: data.thirdPartyEmail,
            thirdPartyDamageImages: data.thirdPartyDamageImages
          }
        }>
        {({ values }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <div className={'third-party-form'}>
                <Grid container spacing={1} alignItems="center"
                  justifyContent="center">
                  <Grid item xs={11}>
                    <h3 className={'card-title'}>{t('opponent-exist')}</h3>
                  </Grid>
                  <Grid item xs={1}>
                    <Toggler onChange={onToggleChange} checked={hasThirdParty} />
                  </Grid>
                  {hasThirdParty && <>
                    <Grid item xs={12} style={{marginBottom:"10px"}}>
                      <p className="input-label">{`${t("license-plate")}`}</p>
                      <License license={values.thirdPartyLicensePlate} licenseChange={(value) => onFieldChange('thirdPartyLicensePlate', value)} />
                      {error?.thirdPartyLicensePlate && <p className={'error-message-loc'}>{t(error?.thirdPartyLicensePlate)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("name")}
                        name="thirdPartyName"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyName}
                        onChange={(e) => onFieldChange('thirdPartyName', e.target.value)}
                      />
                      {error?.thirdPartyName && <p className={'error-message'}>{t(error?.thirdPartyName)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("surname")}
                        name="thirdPartySurname"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartySurname}
                        onChange={(e) => onFieldChange('thirdPartySurname', e.target.value)}
                      />
                      {error?.thirdPartySurname && <p className={'error-message'}>{t(error?.thirdPartySurname)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("street")}
                        name="thirdPartyStreet"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyStreet}
                        onChange={(e) => onFieldChange('thirdPartyStreet', e.target.value)}
                      />
                      {error?.thirdPartyStreet && <p className={'error-message'}>{t(error?.thirdPartyStreet)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("house-number")}
                        name="thirdPartyStreetNum"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyStreetNum}
                        onChange={(e) => onFieldChange('thirdPartyStreetNum', e.target.value)}
                      />
                      {error?.thirdPartyStreetNum && <p className={'error-message'}>{t(error?.thirdPartyStreetNum)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("zipcode")}
                        name="thirdPartyZipCode"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyZipCode}
                        onChange={(e) => onFieldChange('thirdPartyZipCode', e.target.value)}
                      />
                      {error?.thirdPartyZipCode && <p className={'error-message'}>{t(error?.thirdPartyZipCode)}</p>}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t("city")}
                        name="thirdPartyCity"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyCity}
                        onChange={(e) => onFieldChange('thirdPartyCity', e.target.value)}
                      />
                      {error?.thirdPartyCity && <p className={'error-message'}>{t(error?.thirdPartyCity)}</p>}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        label={t("country")}
                        name="thirdPartyCountry"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '10px' }}
                        value={values.thirdPartyCountry}
                        onChange={(e) => onFieldChange('thirdPartyCountry', e.target.value)}
                      />
                      {error?.thirdPartyCountry && <p className={'error-message'}>{t(error?.thirdPartyCountry)}</p>}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        label={`${t("phone")} *`}
                        name="thirdPartyPhone"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '20px' }}
                        value={values.thirdPartyPhone}
                        onChange={(e) => onFieldChange('thirdPartyPhone', e.target.value)}
                      />
                      {error?.thirdPartyPhone && <p className={'error-message'}>{t(error?.thirdPartyPhone)}</p>}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        label={t("email_short")}
                        name="thirdPartyEmail"
                        component={Input}
                        className={'dark-blue-input'}
                        style={{ marginBottom: '20px' }}
                        value={values.thirdPartyEmail}
                        onChange={(e) => onFieldChange('thirdPartyEmail', e.target.value)}
                      />
                      {error?.thirdPartyEmail && <p className={'error-message'}>{t(error?.thirdPartyEmail)}</p>}
                    </Grid>
                    <Grid item xs={12}>
                      {!!values?.thirdPartyDamageImages?.length &&
                        <>
                          <p className="input-label">{`${t("photos_of_the_damage_length")} *`}</p>

                          <div className={'damage-image-list'}>
                            {renderUploadedImages().map((file, index) => (
                              <div className={'image-container'} key={index}>
                                <img alt={'damage-image'} src={window.URL.createObjectURL(file)} onClick={() => onPreviewImage(index)} />
                                <span className={'img-remove'} onClick={() => onFileRemove(index, onFieldChange)}><Close /></span>
                              </div>
                            ))}
                          </div>
                          {error?.thirdPartyDamageImages && <p className={'error-message'}>{t(error?.thirdPartyDamageImages)}</p>}
                        </>
                      }
                      {/*<p className="input-label">{t("photos_of_the_damage_length")}</p>*/}
                      <MultipleUploadComponent
                        name={'thirdPartyDamageImages'}
                        withoutPreview
                        value={values.thirdPartyDamageImages || []}
                        onChange={(e) => onFileUpload(e.target.files, values.thirdPartyDamageImages || [], onFieldChange)}
                        onRemove={(index) => onFileRemove(index)}
                      />
                    </Grid>
                  </>}
                </Grid>
              </div>
            </form>
          );
        }}
      </Formik>
      {
        <Slider
          ref={sliderRef}
          slides={slides}
          setSlides={setSlides}
          imageAPI={loadFullImage}
        />
      }
    </div>
  );
};


export default memo(VehicleThirdParty);
