import React from 'react';

/** load components **/
import Toggler from '@components/Common/Toggler';
import FilterDatePicker from '@components/Common/FilterDatePicker';

/** Load translation helper **/
import { t } from '@helpers/i18n';

const DamageFilter = ({ settings, onChange, dateFrom, dateTo, onDateChange }) => {
  return (
    <div className="filter-rows">
      <div className="row">
        <div className="toggle">
          <label>
            <p>{t('show_all')}</p>
            <Toggler onChange={() => onChange('all')} checked={settings[0].value} />
          </label>
          <label>
            <p>{t('open')}</p>
            <Toggler onChange={() => onChange('pending')} checked={settings[1].value} />
          </label>
          <label>
            <p>{t('in_process')}</p>
            <Toggler onChange={() => onChange('in_process')} checked={settings[2].value} />
          </label>
          <label>
            <p>{t('in_workshop')}</p>
            <Toggler onChange={() => onChange('in_workshop')} checked={settings[3].value} />
          </label>
          <label>
            <p>{t('regulated')}</p>
            <Toggler onChange={() => onChange('regulated')} checked={settings[4].value} />
          </label>
          <label>
            <p>{t('completed')}</p>
            <Toggler onChange={() => onChange('completed')} checked={settings[5].value} />
          </label>
          <label>
            <p>{t('missing_data')}</p>
            <Toggler onChange={() => onChange('missing_data')} checked={settings[6].value} />
          </label>
          <label>
            <p>{t('reclamation')}</p>
            <Toggler onChange={() => onChange('reclamation')} checked={settings[7].value} />
          </label>
          <label>
            <p>{t('damages_between')}</p>
            <Toggler onChange={() => onChange('damages_between')} checked={settings[8].value} />
          </label>
          {settings[8].value && (
            <div className="date-picker">
              <FilterDatePicker dateFrom={dateFrom} dateTo={dateTo} onDateChange={onDateChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DamageFilter;
