import React from 'react';
import LoaderIcon from '../../../assets/svg/loader.svg';

import './Loader.styles.scss';

/** Simple loader user for loading Car check detailed view **/
const Loader = () => {
  return (
    <div className={'skeleton'}>
      <img src={LoaderIcon} alt={'loader'} />
    </div>
  );
};

export default Loader;
