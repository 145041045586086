import React from 'react';
import './index.scss';
import classnames from 'classnames';

const TaskSelector = ({ data = [], onClick = () => {}, active = null }) => {
  const renderTasks = () => {
    return data.map((el) => {
      return (
        <div
          id={el.id}
          key={el.id}
          className={classnames('task-box', { active: active.includes(el.id.toString()) })}
          onClick={onClick}>
          <div className="icon-block">
            <img src={el.icon} alt="icon" />
          </div>
          <span>{el.label}</span>
        </div>
      );
    });
  };

  return <div className="task-selector">{renderTasks()}</div>;
};

export default TaskSelector;
