import { mileageFormatter } from '@services/formatters';

export default class VehicleInfoDTO {
  constructor(vehicleInfo, locationInfo) {
    this.manufacturer = vehicleInfo?.manufacturer || '';
    this.licencePlate = vehicleInfo?.licencePlate || '';
    this.city = locationInfo?.city || '';
    this.tags = vehicleInfo?.tags.length ? vehicleInfo?.tags.map((tag) => tag.name).join(', ') : '';
    this.imei = `${locationInfo?.imei}` || '';
    this.vehicleType = vehicleInfo?.type || '';
    this.modelType = vehicleInfo?.vehicleModelType || '';
    this.vehicleClass = vehicleInfo?.vehicleClass || '';
    this.driver =
      vehicleInfo?.driver && vehicleInfo?.driver.name && vehicleInfo?.driver.surname
        ? `${vehicleInfo?.driver.name} ${vehicleInfo?.driver.surname}`
        : '';
    this.inspectionStatus = vehicleInfo?.carInspectionStatus?.status;
    this.nextInspectionAtKm = vehicleInfo?.carInspectionStatus?.nextInspectionAtKm
      ? `${mileageFormatter(Math.round(vehicleInfo?.carInspectionStatus?.nextInspectionAtKm))} km`
      : '';
    this.nextInspectionIn = vehicleInfo?.carInspectionStatus?.nextInspectionIn || '';
    this.nextInspectionAtDate = vehicleInfo?.carInspectionStatus?.nextInspectionAtDate || '';
    this.place = locationInfo?.place || '';
    this.fuelLevel = locationInfo && locationInfo['fuel-level'] ? `${locationInfo['fuel-level']} %` : '';
    this.kilometrage = locationInfo?.kilometrage ? `${mileageFormatter(Math.round(locationInfo?.kilometrage))} km` : '';
    this.speed = locationInfo?.speed ? `${locationInfo?.speed} km/h` : 0;
    this.location =
      locationInfo?.latitude && locationInfo?.longitude
        ? { lat: locationInfo?.latitude, lng: locationInfo?.longitude }
        : null;
  }
}
