import * as profileConstants from './constants';

const initialState = {
  loading: false,
  license: '',
  status: '',
  update: '',
  profile: {},
  tags: [],
  putAvatar: ''
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case profileConstants.PROFILE_GET_REQUEST:
    case profileConstants.PROFILE_GET_LICENSE_REQUEST:
    case profileConstants.PROFILE_UPDATE_REQUEST:
    case profileConstants.PROFILE_DELETE_REQUEST:
    case profileConstants.PUT_AVATAR_REQUEST:
    case profileConstants.SET_USER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case profileConstants.PROFILE_GET_TAGS_REQUEST: {
      return {
        ...state
      };
    }
    case profileConstants.PROFILE_GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload
      };
    }
    case profileConstants.PROFILE_GET_TAGS_FAIL: {
      return {
        ...state
      };
    }

    case profileConstants.SET_USER_SUCCESS:
      return {
        ...state,
        status: action.payload,
        loading: false
      };

    case profileConstants.PUT_AVATAR_SUCCESS:
      return {
        ...state,
        putAvatar: true
      };

    case profileConstants.SET_USER_FAIL:
      return {
        ...state,
        status: action.payload,
        loading: false
      };

    case profileConstants.PUT_AVATAR_FAIL:
      return {
        ...state,
        putAvatar: false
      };

    case profileConstants.PUT_AVATAR_CLEAR:
      return {
        ...state,
        putAvatar: ''
      };

    case profileConstants.SET_USER_CLEAR:
      return {
        ...state,
        status: ''
      };

    case profileConstants.PROFILE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload
      };

    case profileConstants.PROFILE_UPDATE_SUCCESS:
    case profileConstants.PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        update: action.payload
      };

    case profileConstants.PROFILE_GET_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        license: action.payload
      };

    case profileConstants.PROFILE_GET_FAIL:
    case profileConstants.PROFILE_UPDATE_FAIL:
    case profileConstants.PROFILE_DELETE_FAIL:
    case profileConstants.PROFILE_GET_LICENSE_FAIL:
      return {
        ...state,
        update: action.payload,
        loading: false
      };

    case profileConstants.PROFILE_CLEAR:
      return {
        ...state,
        loading: false,
        save: ''
      };

    default:
      return state;
  }
}
