import * as dailyRentConstants from './constants';

const initialState = {
  dailyRents: [],
  dailyRentDetail: null,
  loading: false,
  loadingMore: false,
  lastPage: false,
  reportLoading: false,
  handoverReport: {},
  returnReport: {}
};

export default function dailyRent(state = initialState, action) {
  switch (action.type) {
    case dailyRentConstants.DAILY_RENT_GET_REQUEST:
      return {
        ...state,
        loading: true,
        dailyRents: []
      };

    case dailyRentConstants.DAILY_RENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyRents: action.payload,
        lastPage: action.payload.length === 0 || action.payload[0]?.pageTotal - 1 === action.payload[0]?.pageNumber
      };

    case dailyRentConstants.DAILY_RENT_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case dailyRentConstants.DAILY_RENT_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case dailyRentConstants.DAILY_RENT_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        dailyRents: [...state.dailyRents, ...action.payload],
        lastPage: action.payload.length === 0 || action.payload[0]?.pageTotal - 1 === action.payload[0]?.pageNumber
      };

    case dailyRentConstants.DAILY_RENT_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case dailyRentConstants.DAILY_RENT_GET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dailyRentConstants.DAILY_RENT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyRentDetail: action.payload
      };

    case dailyRentConstants.DAILY_RENT_GET_DETAILS_FAIL:
      return {
        ...state,
        loading: false
      };

    case dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_REQUEST:
    case dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_REQUEST:
      return {
        ...state,
        reportLoading: true
      };

    case dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_SUCCESS:
      return {
        ...state,
        reportLoading: false,
        handoverReport: action.payload
      };

    case dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_FAIL:
      return {
        ...state,
        reportLoading: false,
        handoverReport: {}
      };

    case dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_SUCCESS:
      return {
        ...state,
        reportLoading: false,
        returnReport: action.payload
      };

    case dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_FAIL:
      return {
        ...state,
        reportLoading: false,
        returnReport: {}
      };

    default:
      return state;
  }
}
