import * as garageConstants from '@store/garages/constants';
import { errorHandler } from "@helpers/saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { Api } from "@api";

function* getGarages(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.garages.getGarages, id);

    yield put({ type: garageConstants.GARAGES_GET_SUCCESS, payload: data });
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: garageConstants.GARAGES_GET_FAIL });
  }
}

export function* garageSagas() {
  yield takeLatest(garageConstants.GARAGES_GET_REQUEST, getGarages);
}
