import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field, Formik, Form} from "formik";
import {t} from "@helpers/i18n";
import {Grid} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {Input} from "@components/Common/Input";
import {Button} from "@components/Common/Button";
import {Spinner} from "@components/Spinner";
import Eye from "@assets/svg/eye.svg";
import KeyIcon from "mdi-react/KeyIcon";
import {BUTTON_TYPES, EMAIL_REGEX} from "@constants";
import {authChallengeResetPassword} from "@store/auth/actions";
import * as Yup from 'yup';
import './ResetPassword.styles.scss';

const ResetPassword = () => {

  const {search} = useLocation();
  const tokenUrl = new URLSearchParams(search);
  const token = tokenUrl.get('token');
  const { loading } = useSelector(state => state.auth);
  const [showPassword, setShowPassword] = useState(false); // pass field visibility
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false); // confirm pass field visibility
  const dispatch = useDispatch();
  const history = useHistory();

  const validateResetForm =
        Yup.object().shape({
          reset_token: Yup.string()
            .required('field_required'),
          email:Yup.string()
            .matches(EMAIL_REGEX, {message: 'invalid_address'})
            .required('field_required'),
          password: Yup.string()
            .min(6, 'invalid_password')
            .max(50, 'invalid_password!')
            .required('field_required'),
          repeat_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'passwords_must_match')
            .required('field_required')
        });

  const togglePasswordReset = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordResetRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };

  const redirectToLogin = () => {
    history.push("/");
  };

  const handleSubmit = (values) => {
    dispatch(authChallengeResetPassword({...values, token, callback: redirectToLogin}));
  };

  const resetForm = () => (
    <Formik
      initialValues={{
        reset_token: token,
        email:'',
        password:'',
        repeat_password: ''}}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      validationSchema={validateResetForm}
    >
      {({ values, setFieldValue, touched, setFieldTouched, isValid, errors }) => (
        <Form>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={5}>
              {t("reset_token").concat(':')}
            </Grid>
            <Grid item xs={7}>
              <Field
                name={'reset_token'}
                component={Input}
                disabled
              />
              {touched.reset_token && errors.reset_token && <p className={'error-message'}>{t(errors.reset_token)}</p>}
            </Grid>
            <Grid item xs={5}>
              {t("email").concat(':')}
            </Grid>
            <Grid item xs={7}>
              <Field
                name={'email'}
                component={Input}
              />
              {touched.email && errors.email && <p className={'error-message'}>{t(errors.email)}</p>}
            </Grid>
            <Grid item xs={5}>
              {t("new-password").concat(':')}
            </Grid>
            <Grid item xs={7}>
              <div className={'forgot-eye'}>
                <Field
                  name={'password'}
                  inputType={showPassword ? "text" : "password"}
                  component={Input}
                />
                <img src={Eye} onClick={togglePasswordReset} alt="eye" />
              </div>
              {touched.password && errors.password && <p className={'error-message'}>{t(errors.password)}</p>}
            </Grid>
            <Grid item xs={5}>
              {t("repeat_password").concat(':')}
            </Grid>
            <Grid item xs={7}>
              <div className={'forgot-eye'}>
                <Field
                  name={'repeat_password'}
                  inputType={showPasswordRepeat ? "text" : "password"}
                  component={Input}
                />
                <img src={Eye} onClick={togglePasswordResetRepeat} alt="eye" />
              </div>
              {touched.repeat_password && errors.repeat_password && <p className={'error-message'}>{t(errors.repeat_password)}</p>}
            </Grid>
          </Grid>
          <div className={'reset-password-bottom'}>
            <Button
              isDisabled={!isValid}
              isSubmit
              icon={<KeyIcon />}
              type={BUTTON_TYPES.WARNING}
              text={t('change-password')}
            />
          </div>
        </Form>)}
    </Formik>
  );

  return (
    <div  className={'reset-password'}>
      {loading && <Spinner />}
      <div className={'reset-password-title'}>{t('reset_password')}</div>
      {resetForm()}
    </div>
  );
};

export default ResetPassword;