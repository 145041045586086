import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const useModuleDetector = () => {
  const location = useLocation();
  const [moduleInfo, setModuleInfo] = useState({currentModule: '', previousModule: ''});

  useEffect(() => {
    const pathNameItems = location.pathname.split('/');
    if(pathNameItems?.length > 1) {
      setModuleInfo({currentModule: pathNameItems[1], previousModule: moduleInfo.currentModule});
    } else {
      setModuleInfo({currentModule: '', previousModule: moduleInfo.currentModule});
    }
  }, [location.pathname]);

  return moduleInfo;
};

export default useModuleDetector;
