import './index.scss';
import CheckIcon from 'mdi-react/CheckIcon';
import React from 'react';
import classnames from 'classnames';

const ApplyButton = ({ text = '', onClick = () => {} }) => {
  return (
    <button className={classnames('button', 'applyButton')} type="submit" onClick={onClick}>
      {text && (
        <div className="apply-text">
          <CheckIcon />
          <span className="apply-span">{text}</span>
        </div>
      )}
    </button>
  );
};

export default ApplyButton;
