import React from 'react';
/** load icons **/
import BriefcaseBusiness from '@assets/svg/trips/briefcase-with-text.svg';
import AccountStar from '@assets/svg/trips/account-star.svg';

import './TripType.styles.scss';

const tripTypes = {
  business: BriefcaseBusiness,
  private: AccountStar
};

const TripType = ({ currentType = '', type = '', onClick = () => {} }) => {
  return (
    <button className={`trip-type ${currentType === type ? 'active' : ''}`} onClick={() => onClick(type)}>
      <img src={tripTypes[type]} alt="briefcase-business" />
    </button>
  );
};

export default TripType;
