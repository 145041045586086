import { call, all } from "redux-saga/effects";

import { appSagas } from "./app/sagas";
import { authSagas } from "./auth/sagas";
import { mapSagas } from "./map/sagas";
import { profileSagas } from "./profile/sagas";
import { ticketSagas } from "./tickets/sagas";
import { tripSagas } from "./trips/sagas";
import { employeeSagas } from "./employees/sagas";
import { damageSagas } from "./damages/sagas";
import { vehicleSagas } from "./vehicles/sagas";
import { carcheckSagas } from "./carcheck/sagas";
import { appointmentSagas } from "./appointments/sagas";
import { invoiceSagas } from "./invoices/sagas";
import {reportingSagas} from './reporting/sagas';
import {garageSagas} from './garages/sagas';
import {timeTrackingSaga} from './tracking/sagas';
import {dailyRentSagas} from "@store/dailyRent/sagas";
import {contractsSagas} from "@store/contracts/sagas";

function* sagas() {
  yield all([
    call(appSagas),
    call(authSagas),
    call(mapSagas),
    call(profileSagas),
    call(ticketSagas),
    call(tripSagas),
    call(employeeSagas),
    call(damageSagas),
    call(vehicleSagas),
    call(carcheckSagas),
    call(appointmentSagas),
    call(invoiceSagas),
    call(reportingSagas),
    call(garageSagas),
    call(timeTrackingSaga),
    call(dailyRentSagas),
    call(contractsSagas)
  ]);
}

export {sagas};
