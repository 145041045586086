import React from 'react';
/** load formatters **/
import { dateFormatter } from '@services/formatters/util/DateTime.formatters';
import DATE_FORMATS from '@helpers/constants/dateFormats';
import { InvoiceSumFormatter } from '@services/formatters/invoices/UnvoiceCard.formatter';

const InvoiceRightBlock = ({ invoice = {} }) => {
  const { invoiceDate } = invoice;
  const invoiceFormattedDate = dateFormatter(invoiceDate, DATE_FORMATS.full_DMY_dot);
  const invoiceSum = InvoiceSumFormatter(invoice.sum_total_with_vat);

  return (
    <div className={'flex flex-col h-100 justify-evenly'}>
      <p>{invoiceFormattedDate}</p>
      <p> {invoiceSum}</p>
    </div>
  );
};

export default InvoiceRightBlock;
