import React, { useEffect, useState } from 'react';
import ReportingCardComponent from '@components/Reporting/ReportingCard/ReportingCard.component';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import './WeekendMileageTable.styles.scss';
import { mileageFormatter } from '@services/formatters';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: 'right',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    fontSize: 14,
    overflowY: 'auto'
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const WeekendMileageTable = (props) => {
  const { titleComponent, selectedVehicles, weekendMileage, chartColors, getMonthLabel, month } = props;
  const [summedTableData, setSummedTableData] = useState({});

  useEffect(() => {
    const totalData = {
      drivenKilometerSaturdays: 0,
      drivenKilometerSundays: 0,
      totalKilometerWeekend: 0
    };
    selectedVehicles.forEach((vehicleLicencePlate) => {
      Object.keys(weekendMileage[vehicleLicencePlate]).map((key) => {
        totalData[key] += +weekendMileage[vehicleLicencePlate][key];
      });
    });
    setSummedTableData(totalData);
  }, [selectedVehicles]);

  const formatMileageData = (data) => {
    if (!data) {
      return 0;
    }
    return mileageFormatter(Math.round(data));
  };

  return (
    <Grid item xs={12} xl={6}>
      <ReportingCardComponent
        headerContentLeft={titleComponent('Wochenendfahrten')}
        headerContentRight={titleComponent(getMonthLabel(month))}
        additionalClass={'chart-container'}>
        <TableContainer style={{ maxHeight: '90%' }}>
          <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: 'black', color: 'white' }}>Fahrzeug</TableCell>
                <StyledTableCell align="right">
                  {'Samstag'}
                  <br /> km
                </StyledTableCell>
                <StyledTableCell align="right">
                  {'Sonntag'}
                  <br /> km
                </StyledTableCell>
                <StyledTableCell align="right">
                  {'Gesamt'}
                  <small>je Fahrzeug</small> <br /> km{' '}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedVehicles.map((vehicleLicencePlate, index) => (
                <StyledTableRow key={vehicleLicencePlate}>
                  <TableCell>
                    <div
                      className={'licence-plate-table-cell'}
                      style={{ borderBottom: `4px solid ${chartColors[index % chartColors.length]}` }}>
                      <strong>{vehicleLicencePlate}</strong>
                    </div>
                  </TableCell>
                  <StyledTableCell align="right">
                    {formatMileageData(weekendMileage[vehicleLicencePlate]?.drivenKilometerSaturdays)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatMileageData(weekendMileage[vehicleLicencePlate]?.drivenKilometerSundays)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatMileageData(weekendMileage[vehicleLicencePlate]?.totalKilometerWeekend)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <TableCell>
                  <div>
                    <strong>Gesamt</strong>
                    <p>{selectedVehicles.length} Fahrzeuge</p>
                  </div>
                </TableCell>
                <StyledTableCell align="right">
                  {formatMileageData(summedTableData?.drivenKilometerSaturdays)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMileageData(summedTableData?.drivenKilometerSundays)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMileageData(summedTableData?.totalKilometerWeekend)}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ReportingCardComponent>
    </Grid>
  );
};

export default WeekendMileageTable;
