import MapContainer from "@containers/Map";
import DailyRentListHolder from "@containers/DailyRent/DailyRentListHolder";

const DailyRentList = () => {

  return (
    <div id={'daily-rent-list'} className={'list-wrapper'}>
      <MapContainer />
      <DailyRentListHolder />
    </div>
  );
};

export default DailyRentList;
