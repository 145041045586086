import React from 'react';
/** load components **/
import Calendar from '@components/Common/Calender';
/** load icons **/
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

import './AppointmentCalendar.styles.scss';

const modifierStyles = {
  confirm: {},
  pending: {},
  canceled: {},
  default: {}
};

const modifiersClassNames = {
  confirm: 'confirm',
  pending: 'pending',
  canceled: 'canceled',
  default: 'default'
};

const AppointmentCalendar = ({
  range = {},
  setRange = () => {},
  appointments = [],
  toggleSidebar = () => {},
  onMonthChange = () => {}
}) => {
  /**
   * Remap Appointment modifiers based on
   * Appointment appState
   **/
  const loadAppointments = () => {
    const calendarModifiers = {
      confirm: [],
      pending: [],
      canceled: [],
      default: []
    };
    appointments.forEach((appointment) => {
      // maps appState to appropriate calendar modifier
      if (calendarModifiers[appointment.appState]) {
        // if has date, appointment is created & date will be passed, else wishDate will be passed
        calendarModifiers[appointment.appState].push(new Date(appointment.date || appointment.wishDate1));
      } else {
        // if no modifier is found appointment will be pushed to default modifier state
        calendarModifiers['default'].push(new Date(appointment.date || appointment.wishDate1));
      }
    });
    return calendarModifiers;
  };

  return (
    <div className={'datepicker-block status-none'}>
      <span onClick={toggleSidebar} className={'datepicker-tongue'}>
        <ChevronLeftIcon />
      </span>
      <Calendar
        mode={'range'}
        defaultMonth={new Date()}
        onMonthChange={onMonthChange}
        range={range}
        setRange={setRange}
        modifiers={loadAppointments()}
        modifiersClassNames={modifiersClassNames}
        modifierStyles={modifierStyles}
      />
    </div>
  );
};

export default AppointmentCalendar;
