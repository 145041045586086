import React from 'react';
/** load components **/
import Avatar from '@components/User/Avatar';
import EuroIcon from 'mdi-react/EuroIcon';

import { t } from '@helpers/i18n';

const InvoiceLeftBlock = ({ invoice = {} }) => {
  const { read } = invoice;

  /**
   * Render proper icon based on
   * if invoice is read or unread
   * @returns {JSX.Element}
   **/
  const getInvoiceIcon = () => {
    return read ? <EuroIcon /> : <p className={'invoice-new-icon'}>{t('lbl_invoice_new')}</p>;
  };

  return <Avatar className={'invoice-avatar'} iconBackgroundColor={'#c9c9c9'} small missingIcon={getInvoiceIcon()} />;
};

export default InvoiceLeftBlock;
