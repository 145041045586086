import React from 'react';
import { extractTime, toHoursMinSec } from '@helpers/common/tracking';
import LongDistanceDifferentSizeDots from '@assets/svg/trips/long-distance-different-size-dots.svg';
import Line from '@assets/svg/trips/horizontal-line.svg';
import ClockTimeFiveOutlineIcon from 'mdi-react/ClockTimeFiveOutlineIcon';
import { t } from '@helpers/i18n';
import './TimeTrackingFrame.styles.scss';

const TimeTrackingFrame = (props) => {
  const { begin = '', end = '', seconds, beginAddress, endAddress, inProgress } = props;
  return (
    <div className={'tracking-frame'}>
      <div className={'left-block'}>
        <div>
          <span className={'time-span'}>{extractTime(begin)}</span>
          <img src={Line} alt={'horizontal-line'} />
        </div>
        <div className={'recorded-time'}>
          <ClockTimeFiveOutlineIcon size={16} />
          <span>{toHoursMinSec(seconds)}</span>
        </div>
        <div>
          <span className={'time-span'}>{extractTime(end)}</span>
          <img src={Line} alt={'horizontal-line'} />
        </div>
      </div>
      <img src={LongDistanceDifferentSizeDots} alt={'long-dots'} />
      <div className={'right-block'}>
        <div className={'start-stop'}>
          <div>{t('start-tracking')}</div>
          <div>{inProgress ? t('tracking_in_progress') : t('stop-tracking')}</div>
        </div>
        <div className={'tracking-address'}>
          <div>{beginAddress.replace('\n', ', ')}</div>
          <div>{!inProgress && endAddress.replace('\n', ', ')}</div>
        </div>
      </div>
    </div>
  );
};

export default TimeTrackingFrame;
