import React from 'react';
import Toggle from 'react-toggle';

import './Toggler.styles.scss';

const Toggler = ({ id = '', label = '', checked = false, onChange = () => {} }) => {
  return (
    <label className="toggler">
      <div>{label}</div>
      <Toggle id={id} onChange={onChange} checked={checked} icons={false} />
    </label>
  );
};

export default Toggler;
