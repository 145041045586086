import {
  MAP_GET_VEHICLE_LOCATIONS_REQUEST,
  MAP_GET_VEHICLE_LOCATIONS_SUCCESS,
  MAP_GET_VEHICLE_LOCATIONS_FAIL,
  MAP_GET_STATION_LOCATIONS_REQUEST,
  MAP_GET_STATION_LOCATIONS_SUCCESS,
  MAP_GET_STATION_LOCATIONS_FAIL,
  MAP_GET_SERVICE_PARTNER_LOCATIONS_REQUEST,
  MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS,
  MAP_GET_SERVICE_PARTNER_LOCATIONS_FAIL,
  MAP_CLEAR_SERVICE_PARTNER_LOCATIONS,
  MAP_CLEAR_STATION_LOCATIONS,
  MAP_GET_DRIVERS_REQUEST,
  MAP_GET_DRIVERS_SUCCESS,
  MAP_GET_DRIVERS_FAIL,
  GET_ACTIVE_VEHICLE_TRIP_REQUSET,
  GET_ACTIVE_VEHICLE_TRIP_SUCCESS,
  GET_ACTIVE_VEHICLE_TRIP_FAIL
} from "./constants";

const initialState = {
  loading: false,
  servicePartners: [],
  stations: [],
  vehicles: [],
  drivers: [],
  activeVehicleTrip: null,
  activeVehicleTripLoad: false
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case MAP_GET_VEHICLE_LOCATIONS_REQUEST:
    case MAP_GET_STATION_LOCATIONS_REQUEST:
    case MAP_GET_SERVICE_PARTNER_LOCATIONS_REQUEST:
    case MAP_GET_DRIVERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ACTIVE_VEHICLE_TRIP_REQUSET:
      return {
        ...state,
        activeVehicleTripLoad: true
      };

    case MAP_CLEAR_SERVICE_PARTNER_LOCATIONS:
      return {
        ...state,
        loading: false,
        servicePartners: [],
      };

    case MAP_CLEAR_STATION_LOCATIONS:
      return {
        ...state,
        loading: false,
        stations: [],
      };

    case MAP_GET_VEHICLE_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: action.payload,
      };

    case MAP_GET_STATION_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        stations: action.payload,
      };

    case MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        servicePartners: action.payload,
      };

    case MAP_GET_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload,
      };

    case MAP_GET_VEHICLE_LOCATIONS_FAIL:
    case MAP_GET_STATION_LOCATIONS_FAIL:
    case MAP_GET_SERVICE_PARTNER_LOCATIONS_FAIL:
    case MAP_GET_DRIVERS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_ACTIVE_VEHICLE_TRIP_SUCCESS:
      return {
        ...state,
        activeVehicleTrip: action.payload,
        activeVehicleTripLoad: false
      };

    case GET_ACTIVE_VEHICLE_TRIP_FAIL:
      return {
        ...state,
        activeVehicleTrip: null,
        activeVehicleTripLoad: false
      };

    default:
      return state;
  }
}
