import React from 'react';
import MapCmp from '@components/Common/Map/MapCmp';
import DriverPopupCmp from '@components/Common/Map/DriverPopup';
import { MapFormatters } from '@services/formatters/profile/map';

const VehicleMap = (props) => {
  const { generalInfo, vehicle } = props;
  const { speed, driver, city, place } = generalInfo || {};

  const { manufacturer, vehicleModelType, tags } = vehicle || {};

  const popupProps = {
    speed,
    driver,
    city,
    place,
    tags,
    userCar: `${manufacturer ?? ''} ${vehicleModelType ?? ''}`,
    fuelLevel: generalInfo ? generalInfo['fuel-level'] : ''
  };

  const vehicleHasLocation = MapFormatters.hasLocation(generalInfo);

  const vehicleLocation = MapFormatters.getLocation(generalInfo);

  const renderMarker = () => {
    return <DriverPopupCmp {...popupProps} />;
  };

  return <MapCmp hasLocation={vehicleHasLocation} location={vehicleLocation} renderMapMarkers={renderMarker} />;
};

export default VehicleMap;
