import React from 'react';
import Loader from '@assets/svg/loader.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import './Carousel.styles.scss';

const Carousel = ({ slides, activeIndex, onSlideChange, imageSrcLink }) => {
  return (
    <Swiper
      modules={[Navigation]}
      slidesPerView={1}
      navigation
      allowTouchMove={false}
      initialSlide={activeIndex}
      onSlideChange={(swiper) => onSlideChange && onSlideChange(swiper.activeIndex)}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {slide ? (
            imageSrcLink ? (
              <img className="car-check-image" src={slide} alt="image" />
            ) : (
              <img className="car-check-image" src={`data:image/jpeg;base64,${slide}`} alt="image" />
            )
          ) : (
            <img className="loader-image" src={Loader} alt="loader" />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
