import * as contractConstants from './constants';

export const contractGet = payload => ({ type: contractConstants.CONTRACT_GET_REQUEST, payload });
export const contractGetSuccess = payload => ({ type: contractConstants.CONTRACT_GET_SUCCESS, payload });
export const contractGetFail = error => ({ type: contractConstants.CONTRACT_GET_FAIL, error });

export const contractLoadMore = payload => ({ type: contractConstants.CONTRACT_LOAD_MORE_REQUEST, payload });
export const contractLoadMoreSuccess = payload => ({ type: contractConstants.CONTRACT_LOAD_MORE_SUCCESS, payload });
export const contractLoadMoreFail = error => ({ type: contractConstants.CONTRACT_LOAD_MORE_FAIL, error });

export const contractDetailsGet = payload => ({ type: contractConstants.CONTRACT_GET_DETAILS_REQUEST, payload });
export const contractDetailsGetSuccess = payload => ({ type: contractConstants.CONTRACT_GET_DETAILS_SUCCESS, payload });
export const contractDetailsGetFail = error => ({ type: contractConstants.CONTRACT_GET_DETAILS_FAIL, error });
