import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
/** load components **/
import { Field, Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { CmpInput } from '@components/Common/CmpInput/CmpInput.component';
import CmpDatePicker from '@components/Common/DatePicker';
import CmpUpload from '@components/Common/Upload';
import { Button } from '@components/Common/Button';
import NationalitySelect from '@components/User/EditDriversLicense/NationalitySelect';
import {
  BUTTON_TYPES,
  DRIVERS_LICENSE_NUMBER_REGEX,
  LANGUAGE_BG,
  LANGUAGE_DE,
  LANGUAGE_EN,
  LANGUAGE_PL,
  LANGUAGE_RO,
  LANGUAGE_TR
} from '@constants';
/** load icons **/
import CheckIcon from 'mdi-react/CheckIcon';
/** load tt-tool **/
import { t } from '@helpers/i18n';
import countriesBG from '@assets/countries/countries_bg-BG.json';
import countriesDE from '@assets/countries/countries_de-DE.json';
import countriesGB from '@assets/countries/countries_en-GB.json';
import countriesPL from '@assets/countries/countries_pl-PL.json';
import countriesRO from '@assets/countries/countries_ro-RO.json';
import countriesTR from '@assets/countries/countries_tr-TR.json';
import './index.scss';

const EditDriverLicense = ({ license = {}, loading = false, onSave = () => {}, onPreviewImage }) => {
  const { profile } = useSelector((state) => state.profile);
  const { id, name, surname, issueDate, expirationDate, birthday, nationality, licenceIdentifier } = profile;

  const countries = useMemo(() => {
    switch (profile.language) {
      case LANGUAGE_DE:
        return countriesDE;
      case LANGUAGE_BG:
        return countriesBG;
      case LANGUAGE_PL:
        return countriesPL;
      case LANGUAGE_RO:
        return countriesRO;
      case LANGUAGE_TR:
        return countriesTR;
      case LANGUAGE_EN:
      default:
        return countriesGB;
    }
  }, [profile]);

  const validationSchema = Yup.object().shape({
    nationality: Yup.object().required('field_required'),
    licenceIdentifier: Yup.string()
      .matches(DRIVERS_LICENSE_NUMBER_REGEX, { message: 'invalid_driver_licence' })
      .required('field_required')
  });

  const preSelectedNationality = useMemo(
    () => countries?.find((country) => country.value === nationality) ?? null,
    [nationality]
  );

  return (
    <div className={'edit-license-container'}>
      <Formik
        initialValues={{
          id,
          name,
          surname,
          nationality: preSelectedNationality,
          issueDate,
          expirationDate,
          birthday,
          frontImage: null,
          backImage: null,
          licenceIdentifier: licenceIdentifier ?? ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSave(values);
        }}>
        {({ values, setFieldValue, touched, errors }) => (
          <Form className="details-content flex flex-col justify-between p-b-2">
            <div className={'driver-license-form'}>
              <Grid container spacing={1} className={'left-column'}>
                <Grid item xs={12}>
                  <Field name="name" label={t('name')} component={CmpInput} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="surname" label={t('surname')} component={CmpInput} />
                </Grid>
                <Grid item xs={12}>
                  <CmpDatePicker
                    name="birthday"
                    label={t('birthday')}
                    value={values.birthday}
                    onChange={setFieldValue}
                    customLabelStyle={{ fontSize: '14px', fontWeight: 400 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="nationality"
                    nationality={preSelectedNationality}
                    countries={countries}
                    component={NationalitySelect}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name="licenceIdentifier" label={t('number')} component={CmpInput} />
                  {errors.licenceIdentifier && touched.licenceIdentifier ? (
                    <p className="error-text">{t(`${errors.licenceIdentifier}`)}</p>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <CmpDatePicker
                    name="issueDate"
                    label={t('date-of-issue')}
                    value={values.issueDate}
                    onChange={setFieldValue}
                    customLabelStyle={{ fontSize: '14px', fontWeight: 400 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CmpDatePicker
                    name="expirationDate"
                    label={t('expiry-date')}
                    value={values.expirationDate}
                    onChange={setFieldValue}
                    customLabelStyle={{ fontSize: '14px', fontWeight: 400 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="label">{t('license_front_upload')}</p>
                  <CmpUpload
                    name={'frontImage'}
                    value={values.frontImage}
                    onChange={(e) => setFieldValue('frontImage', e.target.files[0])}
                    onRemove={() => setFieldValue('frontImage', null)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="label">{t('license_back_upload')}</p>
                  <CmpUpload
                    label={t('license_back_upload')}
                    name={'backImage'}
                    value={values.backImage}
                    onChange={(e) => setFieldValue('backImage', e.target.files[0])}
                    onRemove={() => setFieldValue('backImage', null)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} className={'column-pictures'}>
                {Boolean(license?.frontImage?.blob) && (
                  <div className={'license-container'}>
                    <img
                      className="license-img"
                      onClick={() => onPreviewImage(0)}
                      src={`data:image/png;base64, ${license.frontImage.blob}`}
                      alt="license-front"
                    />
                  </div>
                )}
                {Boolean(license?.backImage?.blob) && (
                  <div className={'license-container'}>
                    <img
                      className="license-img"
                      onClick={() => onPreviewImage(1)}
                      src={`data:image/png;base64, ${license.backImage.blob}`}
                      alt="license-back"
                    />
                  </div>
                )}
              </Grid>
            </div>
            <Button
              className={'self-center save-button-text'}
              icon={<CheckIcon />}
              isSubmit
              isDisabled={Array.isArray(errors) || Object.values(errors).toString() != ''}
              type={BUTTON_TYPES.SUCCESS}
              isLoading={loading}
              text={t('save')}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditDriverLicense;
