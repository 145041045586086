import React, { useMemo } from 'react';
import PageHeader from '@components/Layout/PageHeader';
import { t } from '@helpers/i18n';
import './TicketPageTemplate.style.scss';

const TicketPageTemplateComponent = ({ children }) => {
  /**
   * Renders page header
   * @type {JSX.Element}
   */
  const renderHeader = useMemo(
    () => (
      <PageHeader text={t('back')} profile={true} backLink={'/tickets'}>
        <div className="header-ticket-row">
          <div className={'ticket-heading-title'}>{t('tickets')}</div>
        </div>
      </PageHeader>
    ),
    []
  );

  return (
    <div className={'details-container'}>
      {renderHeader}
      {children}
    </div>
  );
};

export default TicketPageTemplateComponent;
