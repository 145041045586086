import {call, put, all, takeLatest} from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";

import {
  CARCHECK_GET_REQUEST,
  CARCHECK_GET_SUCCESS,
  CARCHECK_GET_FAIL,

  CARCHECK_SINGLE_GET_REQUEST,
  CARCHECK_SINGLE_GET_SUCCESS,
  CARCHECK_SINGLE_GET_FAIL,

  CARCHECK_LOAD_MORE_REQUEST,
  CARCHECK_LOAD_MORE_SUCCESS,
  CARCHECK_LOAD_MORE_FAIL,
} from "./constants";

function* getCarChecks(action) {
  try {
    const { id, page, query  } = action.payload;
    const { data } = yield call(Api.carchecks.getCarChecks, { id, page: page, query  });

    yield put({ type: CARCHECK_GET_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: CARCHECK_GET_FAIL });
  }
}

function* loadMoreCarChecks(action) {
  try {
    const {id, query, page} = action.payload;
    const {data} = yield call(Api.carchecks.getCarChecks, {id, page, query});
    yield put({ type: CARCHECK_LOAD_MORE_SUCCESS, payload: data });
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: CARCHECK_LOAD_MORE_FAIL });
  }
}

function* getCharcheck(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.carchecks.getCarCheck, id);

    yield put({ type: CARCHECK_SINGLE_GET_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: CARCHECK_SINGLE_GET_FAIL });
  }
}

function* getCarchecksSaga() {
  yield takeLatest(CARCHECK_GET_REQUEST, getCarChecks);
}

function* loadMoreCarChecksSaga() {
  yield takeLatest(CARCHECK_LOAD_MORE_REQUEST, loadMoreCarChecks);
}

function* getCarcheckSaga() {
  yield takeLatest(CARCHECK_SINGLE_GET_REQUEST, getCharcheck);
}

export function* carcheckSagas() {
  yield all([
    call(getCarchecksSaga),
    call(getCarcheckSaga),
    call(loadMoreCarChecksSaga)
  ]);
}
