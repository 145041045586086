import React, { useMemo } from 'react';
import { DEFAULT_DATE_ZERO, NO_DATE } from '@constants';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import DataForm from '@components/Common/Form/DataForm';
import { Button } from '@components/Common/Button';
import { EmployeeLicenseImages } from '@components/Employee';
import { BUTTON_TYPES } from '@constants';
import { t, date } from '@helpers/i18n';
import { LicenseIcon } from '@services/formatters/profile/license';
import { getLocalizedCountryLabel } from '@services/utils/Profile.utils';

const DriversLicense = ({
  profile = {},
  license = {},
  auth = {},
  onEditClick = () => {},
  onPreviewImage = () => {}
}) => {
  const language = profile?.language ? profile.language : 'en-GB';

  const localizedCountryName = useMemo(
    () => getLocalizedCountryLabel(license.nationality, language),
    [license.nationality, language]
  );

  /** Renders Edit License button **/
  const renderEditButton = () => {
    return (
      profile.id === auth.info.id && (
        <Button
          type={BUTTON_TYPES.WARNING}
          icon={<CardAccountDetailsOutlineIcon />}
          text={t('update-driver-license')}
          className={'self-center'}
          onClick={onEditClick}
        />
      )
    );
  };

  /** Driving license form data **/
  const driversLicenseData = [
    {
      color: profile.state,
      name: t('state'),
      value: t(`driverLicenseStatus.${profile.state}`),
      icon: LicenseIcon.getIcon(profile.state)
    },
    {
      name: t('surname'),
      value: profile.surname ? profile.surname : '-'
    },
    {
      name: t('name'),
      value: profile.name ? profile.name : '-'
    },
    {
      name: t('number'),
      value: license.licenceIdentifier ? license.licenceIdentifier : '-'
    },
    {
      name: t('date-of-issue'),
      value:
        profile.issueDate && profile.issueDate !== NO_DATE && profile.issueDate !== DEFAULT_DATE_ZERO
          ? date(license.issueDate)
          : '-'
    },
    {
      name: t('expiry-date'),
      value:
        profile.expirationDate && profile.expirationDate !== NO_DATE && profile.expirationDate !== DEFAULT_DATE_ZERO
          ? date(license.expirationDate)
          : '-'
    },
    {
      name: t('birthday'),
      value: date(profile.birthday) && date(profile.birthday) !== NO_DATE ? date(profile.birthday) : '-'
    },
    {
      name: t('nationality'),
      value: localizedCountryName ?? '-'
    }
  ];

  return (
    <div className={'flex-1'}>
      <div className={'license-details details-content flex flex-col justify-between p-b-2'}>
        <EmployeeLicenseImages driverLicense={license} onImagePreview={onPreviewImage} />
        <DataForm data={driversLicenseData} />
        {renderEditButton()}
      </div>
    </div>
  );
};

export default DriversLicense;
