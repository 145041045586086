import React from 'react';

/** Load translation helper **/
import { t } from '@helpers/i18n';

/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';
import TicketFilterForm from './FilterForm';

const TicketFilter = (props) => {
  const {
    visible = false,
    filterValues = [],
    filterChange,
    applyFilter = () => {},
    dateFrom,
    dateTo,
    onDateChange = () => {}
  } = props;

  return (
    visible && (
      <div className={'list-filter'}>
        {/** Filter form **/}
        <TicketFilterForm
          filters={filterValues}
          onChange={(filterTitle) => filterChange(filterTitle)}
          onDateChange={onDateChange}
          dateTo={dateTo}
          dateFrom={dateFrom}
        />

        <ApplyButton onClick={applyFilter} text={t('apply')}></ApplyButton>
      </div>
    )
  );
};

export default TicketFilter;
