import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Button } from '@components/Common/Button';
import { Input } from '@components/Common/Input';
import { t } from '@helpers/i18n';
import MultipleUploadComponent from '@components/Common/MultipleUpload';
import Slider from '@components/Common/Slider';
import Close from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import { BUTTON_TYPES } from '@constants';
import { toBase64 } from '@services/utils';
import * as AdditionalInfoValidation from '@containers/Damages/Tabs/DamagePictures/Validations/AdditionalInformation.validator';
import './DamageAdditionForm.styles.scss';

const DamageAdditionForm = ({ onDamageCreate }) => {
  const sliderRef = useRef();

  const [slides, setSlides] = useState(new Array([]).fill(null));
  const [errors, setErrors] = useState({});
  const [damage, setDamage] = useState({
    archived: false,
    description: '',
    damageImages: []
  });

  /**
   * Trigger field change
   * @param {Boolean}  field - field name in dmg object
   * @param  {function} value - field value
   */
  const onFieldChange = (field, value) => {
    setDamage({
      ...damage,
      [field]: value
    });
  };

  const validateForm = (values) => {
    const formValidation = AdditionalInfoValidation.additionalInformationValidation(values);
    if (!formValidation.isValid) {
      return formValidation;
    }
    return false;
  };

  // trigger validation each time - form field changes
  useEffect(() => {
    if (Object.keys(errors).length) {
      const formErrors = validateForm(damage);
      if (formErrors) {
        setErrors(formErrors);
      }
    }
  }, [damage]);

  const onFileUpload = (fileList, currentValues) => {
    const fileArray = Array.from(fileList);
    // will compare with current files & return non duplicated images
    const withoutDuplicates = fileArray.filter((file) => {
      if (damage.images) {
        const dmgImgs = damage?.images.find((dmgImg) => {
          return dmgImg.name === file.name && dmgImg.size === file.size && dmgImg.type === file.type;
        });
        return !dmgImgs;
      } else {
        return true;
      }
    });
    setDamage({
      ...damage,
      ['damageImages']: [...currentValues, ...withoutDuplicates]
    });
    const slides = Array([...currentValues, ...withoutDuplicates].length).fill(null);
    setSlides(slides);
  };

  const onFileRemove = (index) => {
    const updatedImages = damage.damageImages.filter((file, fileIndex) => fileIndex !== index);

    setDamage({
      ...damage,
      ['damageImages']: updatedImages
    });
    const slides = Array(updatedImages.length).fill(null);
    setSlides(slides);
  };

  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = async (index) => {
    const image = await toBase64(damage.damageImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };

  const onFormSubmit = (values) => {
    // validate form
    const formErrors = validateForm(values);
    if (formErrors) {
      setErrors(formErrors);
    } else {
      // create damage
      onDamageCreate(values);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={onFormSubmit}
        initialValues={{
          archived: damage.archived,
          damageImages: damage.damageImages,
          description: damage.description
        }}>
        {({ values, handleChange, handleSubmit }) => {
          return (
            <form className={'addition-damage-form'} onSubmit={handleSubmit}>
              <FormControlLabel
                onChange={(e) => onFieldChange('archived', e.target.checked)}
                sx={{ alignItems: 'flex-start', marginTop: '20px', color: '#2D7695' }}
                control={<Checkbox checked={damage.checked} sx={{ paddingTop: 0 }} size="small" />}
                label={t('damage_upload_additional_info_confirm')}
              />
              <Field
                name="description"
                component={Input}
                type={'textArea'}
                rows={5}
                className={'dark-blue-input'}
                style={{ marginBottom: '20px', fontSize: '16px' }}
                value={damage.description}
                onChange={(e) => onFieldChange('description', e.target.value)}
              />
              {errors?.description && <p className={'error-message'}>{t(errors?.description)}</p>}
              <MultipleUploadComponent
                name={'damageImages'}
                withoutPreview
                value={values.damageImages}
                onChange={(e) => onFileUpload(e.target.files, values.damageImages, handleChange)}
              />
              {errors?.damageImages && <p className={'error-message'}>{t(errors?.damageImages)}</p>}
              <div className={'damage-image-list'}>
                {damage.damageImages.map((file, index) => {
                  return (
                    <div className={'image-container'} key={index}>
                      <img
                        alt={'damage-image'}
                        src={window.URL.createObjectURL(file)}
                        // this is commented out since its not defined but for future refactor we should know what it should do
                        // onClick={() => onPreviewImage(index)}
                        onClick={() => {}}
                      />
                      <span className={'img-remove'} onClick={() => onFileRemove(index, onFieldChange)}>
                        <Close />
                      </span>
                    </div>
                  );
                })}
              </div>
              <Button
                className="is-success"
                icon={<CheckIcon />}
                name="success"
                value="btn-success"
                type={BUTTON_TYPES.SUCCESS}
                text={t('save')}
                isSubmit
                isSmall
              />
            </form>
          );
        }}
      </Formik>
      {<Slider ref={sliderRef} slides={slides} setSlides={setSlides} imageAPI={loadFullImage} />}
    </>
  );
};

export default DamageAdditionForm;
