import React, {useState} from 'react';

/** load components **/
import Avatar from "@components/User/Avatar";
import DeleteAccount from "@components/User/DeleteAccount";
import SwipeableButton from "@components/SwipeableButton";

/** load tt-tool **/
import { t } from "@helpers/i18n";

/** load styles **/
import './style.scss';


const RemoveProfile = ({ avatar,  onSuccessDelete }) => {

  const [position, setPosition] = useState(50);
  /** change img position **/
  const onChangePosition = (args) => {
    const param = args.substring(0, args.length - 2);

    const updatedPosition = param / 2 + 40;
    setPosition(updatedPosition);
  };

  return (
    <div className="delete_account">
      <div>
        <p className="delete_account_title">{t('delete_account')}</p>
        <div className="delete_account_main">
          <div className="img-row" style={{ top: `${position}px` }}>
            <Avatar
              src={avatar}
            />
          </div>
        </div>
        <DeleteAccount />
      </div>
      <SwipeableButton
        onChangePosition={onChangePosition}
        onSuccess={onSuccessDelete}
        color="#E84D0E"
        text={t('delete_profile_btn')} />
    </div>
  );
};

export default RemoveProfile;
