import React from 'react';

import { NO_DATE } from '@constants';
import DataForm from '@components/Common/Form/DataForm';
import PageContent from '@components/Layout/PageContent';
import { date, t } from '@helpers/i18n';

const EmployeeDetails = ({ email, birthday, phone_company, builtAddress }) => {
  const personalInfoData = [
    {
      name: t('phone'),
      value: phone_company || '-'
    },
    {
      name: t('e-mail'),
      value: email || '-'
    },
    {
      name: t('address'),
      value: builtAddress || '-'
    },
    {
      name: t('birthday'),
      value: date(birthday) && date(birthday) !== NO_DATE ? date(birthday) : '-'
    }
  ];

  return (
    <PageContent>
      <DataForm data={personalInfoData} />
    </PageContent>
  );
};

export default EmployeeDetails;
