import React from 'react';

import Grid from '@mui/material/Grid';

/** Load translation helper **/
import { t } from "@helpers/i18n";

import {Redirect, useHistory} from "react-router-dom";
import {useSelector} from 'react-redux';

import './NotFound.styles.scss';


const NotFoundPage = () => {

  // use history hook
  const history = useHistory();
  const {auth: { user: loggedUser } } = useSelector(state => state.auth);


  // note: route guards will handle correct page to redirect user
  // so getting user from store can be removed
  const goBack = () => {
    history.push('/');
  };

  return (
    loggedUser ?
      <div className="bg-grey flex h-100">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <div id="notfound" className="text-center ">
            <h1>😮</h1>
            <h2 className={'text-xxl'}>{t('not_found_title')}</h2>
            <p>{t('not_found_subtitle')}</p>
            <button className={'goBackBtn'} onClick={goBack}>{t('not_found_btn')}</button>
          </div>
        </Grid>
      </div>
      :
      <Redirect to="/" />
  );
};

export default NotFoundPage;
