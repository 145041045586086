import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import { WorkingHours } from '@components/WorkingHours';
import { LicensePlate } from 'components/Car/LicensePlate';
import { useHistory } from 'react-router-dom';
import { t } from 'helpers/i18n';
import React, { useMemo } from 'react';
import CarIcon from 'mdi-react/CarIcon';
import WrenchIcon from 'mdi-react/BuildIcon';
import Battery from 'mdi-react/CarBatteryIcon';
import Car from 'mdi-react/CarConnectedIcon';
import Oil from 'mdi-react/OilIcon';
import Marker from 'mdi-react/MapMarkerIcon';
import Certificate from 'mdi-react/DiplomaIcon';
import Windschutzscheibe from 'mdi-react/CarWindshieldOutlineIcon';
import Reinigung from 'mdi-react/CleaningIcon';
import Reifenwechsel from 'mdi-react/CarTireAlertIcon';
import Defekt from 'mdi-react/FlashOffIcon';
import Smartrepair from 'mdi-react/DesktopClassicIcon';
import Panne from 'mdi-react/CarBrakeHoldIcon';
import Calendar from 'mdi-react/CalendarBlankIcon';
import Snow from 'mdi-react/SnowAdvisoryIcon';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import BremsenIcon from '../../assets/img/brake_abs.svg';
import './ServiceInfo.styles.scss';
import { dateFormatted } from '@services/formatters';
import ButtonGroup from '@components/Common/ButtonGroup';
import CalendarBlankOutlineIcon from 'mdi-react/CalendarBlankOutlineIcon';
import { APPOINTMENT_REQUEST_TYPES } from '@helpers/constants';

const ServiceInfo = ({ service }) => {
  const history = useHistory();

  const renderIcon = (serviceType) => {
    switch (serviceType) {
      case 'AU':
      case 'HU':
        return <CarIcon />;

      case 'Inspektion':
        return <WrenchIcon />;

      case 'Batterie':
      case 'Batterie laden':
        return <Battery />;

      case 'Bremsen':
        return <img className="abc" src={BremsenIcon} alt="icon" />;

      case 'Windschutzscheibe':
        return <Windschutzscheibe />;

      case 'Reifenwechsel':
        return <Reifenwechsel />;

      case 'Reinigung':
        return <Reinigung />;

      case 'Defekt':
        return <Defekt />;

      case 'Panne':
        return <Panne />;

      case 'Kühlung':
        return <Snow />;

      case 'UVV':
        return <Certificate />;

      case 'Ölservice':
        return <Oil />;

      case 'Smartrepair':
        return <Smartrepair />;

      case 'Smart-repair':
        return <Car />;

      case 'Telematik':
        return <Marker />;

      default:
        return '!';
    }
  };

  const renderServices = () => {
    service.services.sort();

    return service.services.map((service, index) => (
      <Tooltip
        key={index}
        placement="top"
        overlay={service}
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
        <a href="#" key={index} className="service tooltip-link">
          <div className="service-icon">{renderIcon(service)}</div>
          <div className="service-type">{service}</div>
        </a>
      </Tooltip>
    ));
  };

  const translateService = (service) => {
    return t(`garage-history-appointment-type`)[service];
  };

  const buttonGroup = useMemo(
    () => [
      {
        label: t('request_appointment'),
        icon: <Calendar />,
        action: () => history.push('/appointments', { type: APPOINTMENT_REQUEST_TYPES.REQUEST, service: service })
      },
      {
        label: t('report_appointment'),
        icon: <CalendarBlankOutlineIcon />,
        action: () => history.push('/appointments', { type: APPOINTMENT_REQUEST_TYPES.REPORT, service: service })
      }
    ],
    []
  );

  return (
    <div className="service-info">
      <div className="service-row">
        <div className="service-name">{service.title}</div>
        <p className="service-available">{t('available_services')}:</p>
        <div className="services">{service.services && renderServices()}</div>
        <div className="service-location">
          <div className="location-icon">
            <MapMarkerOutlineIcon />
          </div>
          <div className="location-text">
            {service.address.street} {service.address.number}, <br /> {service.address.zip} {service.address.city}
          </div>
        </div>
        {<WorkingHours station={false} data={service?.['business-hours']} />}
        <div className="service-history">{t('garage_history')}</div>
        {service.appointments &&
          service?.appointments.map((appointment, index) => {
            return (
              <div className={'garage-wrapper'} key={index}>
                <div className={'garage-row row'}>
                  <p>{dateFormatted(appointment.date)}</p>
                  <div>
                    {appointment?.services.map((service, index) => {
                      return <span key={index}>{translateService(service)}</span>;
                    })}
                  </div>
                  <div>
                    <LicensePlate country="D" license={appointment['license-plate']} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="location-text">
        <ButtonGroup className={'appointment-btn-group'} buttonGroup={buttonGroup} />
      </div>
    </div>
  );
};

export default ServiceInfo;
