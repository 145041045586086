const ICON_BACKGROUND_COLORS = {
  WHITE: "white",
  BLACK: "black",
  RED: "red",
  GREEN: "green",
  BLUE: "blue",
  YELLOW: "yellow",
  TRANSPARENT: "transparent",
  GREY: "grey",
  GREEN_WHITE: "green-white",
  EMPTY: "empty",
};

export default ICON_BACKGROUND_COLORS;
