import React from 'react';
import './TwoSidedButton.style.scss';
import classnames from 'classnames';
import { BUTTON_TYPES } from '@constants';

export const TwoSidedButton = ({
  textLeft = '',
  iconLeft = null,
  typeLeft = BUTTON_TYPES.LINK,
  isDisabledLeft = false,
  onClickLeft = () => {},
  textRight = '',
  iconRight = null,
  typeRight = BUTTON_TYPES.LINK,
  isDisabledRight = false,
  onClickRight = () => {}
}) => {
  return (
    <div className={'split-button-container'}>
      <button onClick={onClickLeft} disabled={isDisabledLeft} className={classnames('split-button-left', typeLeft)}>
        {iconLeft}
        <p>{textLeft}</p>
      </button>
      <button onClick={onClickRight} disabled={isDisabledRight} className={classnames('split-button-right', typeRight)}>
        {iconRight}
        <p>{textRight}</p>
      </button>
    </div>
  );
};
