import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import * as tripActions from "@store/trips/actions";

/** Loading components **/
import AnimatedLoader from "@components/Common/AnimatedLoader";
import TripDetails from "@containers/Trips/Trip/TripDetails";
import ProfileInfoBlock from "@components/ProfileInfoBlock";
import PageHeader from "@components/Layout/PageHeader";

/** Loading translation helper **/
import {t} from "@helpers/i18n";


const Trip = () => {

  /**
   * extract params
   * @type {{}}
   */
  const params = useParams();

  /**
   * Redux selectors
   * @type {*}
   */
  const dispatch = useDispatch();
  const { singleLoading, trip } = useSelector(state => state.trips);
  const { auth } = useSelector((state) => state.auth);

  /**
   * fetch trip by id effect
   */
  useEffect(() => {
    const { id, tripDate } = params;
    dispatch(tripActions.tripGetById({ carId: id, tripDate }));
  }, []);

  /**
   * render header for Trip details
   * @returns {JSX.Element}
   */
  const renderHeader = () => (
    <div className="header-info-row">
      <ProfileInfoBlock
        name={auth.user.name}
        surname={auth.user.surname}
        customerNumber={auth.user.customerNumber}
        company={auth.info.company}
        avatar={auth.user.avatar}
        licensePlate={auth.info.car.licencePlate}
      />
      <div className={'trip-heading-title'}>
        { t('title_loogbook') }
      </div>
    </div>
  );

  /**
   * render Loader
   * @returns {JSX.Element}
   */
  const renderAnimatedLoader = () => {
    return <AnimatedLoader />;
  };

  /**
   * render details page
   * @returns {JSX.Element}
   */
  const renderTripContainer = () => (
    <div id="trips-container" className="details-container">
      <PageHeader text={t("back")} profile={true} backLink={'/trips'}>
        {renderHeader()}
      </PageHeader>
      <div className={'flex-1'}>
        <TripDetails trip={trip} />
      </div>
    </div>
  );

  return(
    <>
      { singleLoading && renderAnimatedLoader()}
      { !singleLoading && renderTripContainer() }
    </>
  );
};

export default Trip;
