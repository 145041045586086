import React from 'react';

import GoogleMapReact from 'google-map-react';
import MapOverlay from '@components/Common/MapOverlay/MapOverlay.component';

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954
};

const defaultZoom = 11;

import './Map.styles.scss';

const MapCmp = ({ hasLocation, location, renderMapMarkers = () => {} }) => {
  const _renderMapOverlay = () => {
    return <MapOverlay />;
  };

  return (
    <div className="map">
      <div className={'map-row'}>
        {hasLocation ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
            defaultZoom={defaultZoom}
            center={location || defaultCenter}
            draggable={false}>
            {renderMapMarkers()}
          </GoogleMapReact>
        ) : (
          _renderMapOverlay()
        )}
      </div>
    </div>
  );
};

export default MapCmp;
