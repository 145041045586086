import * as tripConstants from './constants';


const initialState = {
  drivers:[],
  tracksPerDay:[],
  trip: null,
  loading: false,
  singleLoading: true,
  loadingMore: false,
  lastPage: false,
  listNeedsRefreshing: true,
};

export default function trips(state = initialState, action) {
  switch (action.type) {
    case tripConstants.TRIPS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        tracksPerDay: []
      };
    case tripConstants.TRIP_UPDATE_REQUEST:
      return {
        ...state,
        singleLoading: true,
      };
    case tripConstants.TRIP_UPDATE_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        listNeedsRefreshing: true,
      };
    case tripConstants.TRIP_UPDATE_FAIL:
      return {
        ...state,
        singleLoading: false,
      };
    case tripConstants.TRIPS_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore:true
      };
    case tripConstants.TRIPS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        listNeedsRefreshing: false,
        drivers: action.payload.drivers,
        tracksPerDay: action.payload.tracksPerDay,
        lastPage: action.payload.tracksPerDay.length === 0 || action.payload.tracksPerDay[0].pageTotal - 1 === parseInt(action.payload.tracksPerDay[0].pageNumber)
      };
    case tripConstants.TRIPS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        tracksPerDay: [...state.tracksPerDay, ...action.payload.tracksPerDay],
        drivers: [...state.drivers, ...action.payload.drivers],
        lastPage: action.payload.tracksPerDay.length === 0 || action.payload.tracksPerDay[0].pageTotal - 1 === parseInt(action.payload.tracksPerDay[0].pageNumber)
      };

    case tripConstants.TRIPS_GET_FAIL:
      return {
        ...state,
        loading: false,
        drivers: []
      };

    case tripConstants.RESET_TRIPS_LIST:
      return {
        ...state,
        listNeedsRefreshing: true,
        tracksPerDay: [],
      };
    case tripConstants.TRIP_GET_BY_ID_REQUEST:
      return {
        ...state,
        singleLoading: true
      };

    case tripConstants.TRIP_GET_BY_ID_SUCCESS:
      return {
        ...state,
        trip: action.payload,
        singleLoading: false,
      };

    case tripConstants.TRIP_GET_BY_ID_FAIL:
      return {
        ...state,
        trip: null,
        singleLoading: false,
      };
    case tripConstants.TRIPS_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };
    default:
      return state;
  }
}
