import {
  GET_REPORTING_DATA_FAILURE,
  GET_REPORTING_DATA_REQUEST,
  GET_REPORTING_DATA_SUCCESS,
  UPDATE_REPORTING_CONSTANTS_REQUEST,
  UPDATE_REPORTING_CONSTANTS_SUCCESS,
  UPDATE_REPORTING_CONSTANTS_FAILURE
} from './constants';
import {call, put, takeLatest} from 'redux-saga/effects';
import {Api} from '@api';


function* fetchReportData(action) {
  try {
    const {date} = action.payload;
    const {data} = yield call(Api.reporting.getReports, {date});
    yield put({type: GET_REPORTING_DATA_SUCCESS, payload: data});
  }
  catch (e) {
    yield put({type: GET_REPORTING_DATA_FAILURE});
  }
}

function* updateReportingConstants(action) {
  try {
    const {data, date} = action.payload;
    yield call(Api.reporting.updateReportConstants, {data: {...data, date}});
    const {data: reportData} = yield call(Api.reporting.getReports, {date});
    yield put({type: UPDATE_REPORTING_CONSTANTS_SUCCESS, payload: reportData});
  }
  catch (e) {
    yield put({type: UPDATE_REPORTING_CONSTANTS_FAILURE});
  }
}

export function* reportingSagas() {
  yield takeLatest(GET_REPORTING_DATA_REQUEST, fetchReportData);
  yield takeLatest(UPDATE_REPORTING_CONSTANTS_REQUEST, updateReportingConstants);
}
