const getWeekendMileageObject = (weekendMileage) => {
  const parsedData = {};
  weekendMileage.map(vehicleData => {
    parsedData[vehicleData.licencePlate] = vehicleData;
  });
  return parsedData;
};

export {
  getWeekendMileageObject,
};
