import React, { useState } from 'react';
import { useFormik } from 'formik';

/** Load components **/
import { Input } from '@components/Common/Input';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';

import { t } from '@helpers/i18n';

/** Load icons **/
import Eye from '../../../../assets/svg/eye.svg';
import LoginIcon from 'mdi-react/LoginIcon';

/** Load regex validations **/
import { PASSWORD, EMAIL_REGEX, PHONE_REGEX } from '@constants';
import Grid from '@mui/material/Grid';

const BaseDataForm = ({ registrationInfo = {}, nextStep = () => {}, goToLogin = () => {} }) => {
  const [showPassword, setShowPassword] = useState(false); // pass field visibility

  /** validation for Base data Form **/
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.email.match(EMAIL_REGEX)) {
      errors.email = 'EmailInvalid';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    }
    if (!values.phone.match(PHONE_REGEX)) {
      errors.phone = 'PhoneInvalid';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.password.match(PASSWORD)) {
      errors.password = 'PassInvalid';
    }
    return errors;
  };

  const { values, errors, handleSubmit, handleChange, dirty, isValid } = useFormik({
    initialValues: {
      email: registrationInfo.email,
      phone: registrationInfo.phone,
      password: registrationInfo.password
    },
    validate,
    onSubmit: (values) => {
      nextStep('privacy', values);
    },
    enableReinitialize: true
  });

  /** Toggle visibility of text in password input field **/
  const togglePasswordSign = () => {
    setShowPassword(!showPassword);
  };
  /**
   * Handle field value change
   * @param {Event} e
   */
  const fieldChange = (e) => {
    handleChange(e);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="right-title">{t('welcome_fleet')}</div>

        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            {/*<div className={'email_valid'}>*/}
            <Input
              name={'email'}
              defaultValue={values.email}
              label={
                <>
                  {t('email_required')}
                  <span>*</span>:
                </>
              }
              onChange={(e) => {
                fieldChange(e, 'email');
              }}
            />
            {errors.email && <span className="invalid">{t('invalid_address')}</span>}
            {/*</div>*/}
          </Grid>
          <Grid item xs={12}>
            {/*<div className={'email_valid'}>*/}
            <Input
              name={'phone'}
              defaultValue={values.phone}
              inputType={'tel'}
              label={
                <>
                  {t('phone_number_required')}
                  <span>*</span>:
                </>
              }
              onChange={(e) => {
                fieldChange(e, 'phone');
              }}
            />
            {errors.phone && <span className="invalid">{t('invalid_phone_number')}</span>}
            {/*</div>*/}
          </Grid>
          <Grid item xs={12}>
            <div className={'email_valid forgot-eye'}>
              <Input
                name={'password'}
                label={
                  <>
                    {t('password_required')}
                    <span>*</span>:
                  </>
                }
                inputType={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                  fieldChange(e, 'password');
                }}
              />
              <img src={Eye} onClick={togglePasswordSign} alt="eye" />
              {errors.password && <span className="invalid">{t('invalid_password')}</span>}
            </div>
          </Grid>
        </Grid>
        <div className="actions" style={{ marginTop: 20 }}>
          <Button
            isDisabled={!(isValid && dirty)}
            isSubmit
            icon={<LoginIcon />}
            type={BUTTON_TYPES.WARNING}
            text="Sign Up"
          />
          <div className="sign-up">
            <span>{t('do_you_have_an_account')}</span>
            &nbsp;
            <span className="sign-up-link" onClick={goToLogin}>
              {t('log_in')}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BaseDataForm;
