import * as timeTrackingConstants from "@store/tracking/constants";
const initialState = {
  loading: false,
  lastPage: false,
  loadingMore: false,
  trackings: [],
  drivers: [],
  started: false,
  stopped: false,
  trackingUser: [],
  loadingTimeTrackingDetails: true,
  timeTrackingDetails: {},
  localTime: null,
  alreadyStarted: false,
  totalTimeInSeconds: 0
};

export default function  tracking (state = initialState, action) {
  switch (action.type) {
    case timeTrackingConstants.START_TIME_TRACKING_REQUEST:
    case timeTrackingConstants.STOP_TIME_TRACKING_REQUEST:
    case timeTrackingConstants.REFRESH_TRACKING_STATUS_REQUEST:
      return state;

    case timeTrackingConstants.START_TIME_TRACKING_SUCCESS:
      return {
        ...state,
        started: true,
        stopped: false,
        localTime: new Date(),
        alreadyStarted: action.payload.alreadyStarted
      };

    case timeTrackingConstants.STOP_TIME_TRACKING_SUCCESS:
      return {
        ...state,
        started: false,
        stopped: true,
        localTime: Math.ceil((new Date() - state.localTime) / 60000)
      };

    case timeTrackingConstants.REFRESH_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        started: action.payload.started,
        localTime: new Date(action.payload.timeStarted)
      };

    case timeTrackingConstants.TIME_TRACKING_GET_REQUEST:
    case timeTrackingConstants.TIME_TRACKING_USER_GET_REQUEST:
      return {
        ...state,
        loading: true,
        trackingUser: []
      };

    case timeTrackingConstants.TIME_TRACKING_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        trackings: action.payload.trackings,
        drivers: action.payload.drivers,
        lastPage: action.payload.trackings.length === 0 || action.payload.trackings[0].pageTotal - 1 === action.payload.trackings[0].pageNumber
      };

    case timeTrackingConstants.TIME_TRACKING_USER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingUser: action.payload.trackings,
        totalTimeInSeconds: action.payload.totalTimeInSeconds,
        lastPage: action.payload.trackings.length === 0 || action.payload.trackings[0].pageTotal - 1 === action.payload.trackings[0].pageNumber
      };

    case timeTrackingConstants.TIME_TRACKING_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case timeTrackingConstants.TIME_TRACKING_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        trackings: [...state.trackings, ...action.payload.trackings],
        drivers: [...state.drivers, action.payload.drivers],
        lastPage: action.payload.trackings.length === 0 || action.payload.trackings[0].pageTotal - 1 === action.payload.trackings[0].pageNumber
      };

    case timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        trackingUser: [...state.trackingUser, ...action.payload.trackings],
        lastPage: action.payload.trackings.length === 0 || action.payload.trackings[0].pageTotal - 1 === action.payload.trackings[0].pageNumber
      };

    case timeTrackingConstants.TIME_TRACKING_GET_FAIL:
    case timeTrackingConstants.TIME_TRACKING_USER_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case timeTrackingConstants.START_TIME_TRACKING_FAIL:
      return {
        ...state,
        started: false
      };

    case timeTrackingConstants.STOP_TIME_TRACKING_FAIL:
      return {
        ...state,
        started: true
      };

    case timeTrackingConstants.TIME_TRACKING_LOAD_MORE_FAIL:
    case timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case timeTrackingConstants.TIME_TRACKING_GET_DETAILS_REQUEST:
      return {
        ...state,
        loadingTimeTrackingDetails: true
      };
    case timeTrackingConstants.TIME_TRACKING_GET_DETAILS_SUCCESS:
      return {
        ...state,
        loadingTimeTrackingDetails: false,
        timeTrackingDetails: action.payload
      };
    case timeTrackingConstants.TIME_TRACKING_GET_DETAILS_FAIL:
      return {
        ...state,
        loadingTimeTrackingDetails: false
      };

    case timeTrackingConstants.REFRESH_TRACKING_STATUS_FAIL:
      return state;

    default:
      return state;
  }
};
