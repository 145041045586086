import React, {useState} from "react";

/** Loading components **/
import ButtonGroup from "@components/Common/ButtonGroup";
import {Spinner} from "@components/Spinner";
import AlertCmp from "@components/Common/Alert";

/** Loading MUI components **/
import Grid from "@mui/material/Grid";
import WalletIcon from "mdi-react/WalletIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";

/** Loading helper functions and constants **/
import {t} from "@helpers/i18n";
import {dateFormatter} from "@services/formatters/util/DateTime.formatters";
import {currencyToLocaleFormatter} from "@services/formatters/util/Number.formatters";
import classNames from 'classnames';
import {isValidHttpUrl} from "@services/utils";
import {Api} from "@api";
import DATE_FORMATS from "@helpers/constants/dateFormats";

/** Loading styles **/
import './InvoiceDetails.styles.scss';

const InvoiceDetails = (props) => {

  /** Destructuring the props object. **/
  const {invoice} = props;

  /** Creating a local state for the component. **/
  const [loader, setLoader] = useState(false);
  const [downloadPdfNotAvailablePopup, setDownloadPdfNotAvailablePopup] = useState(false);
  const [paymentLinkNotAvailablePopup, setPaymentLinkNotAvailablePopup] = useState(false);

  /**
   * Downloading invoice as a pdf
   * @private
   */
  const downloadInvoicePdf = async () => {
    try {
      setLoader(true);
      const {data} = await Api.invoices.getInvoicePDF({invoiceId: invoice?.id});

      if (data?.blob) {
        const linkSource = `data:application/pdf;base64,${data.blob}`;
        const downloadLink = document.createElement("a");
        const fileName = data.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoader(false);
      }
      else {
        setDownloadPdfNotAvailablePopup(true);
        setLoader(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  /**
   * It opens a new tab with the URL passed to it
   * @param URL - The URL to the payment page.
   */
  const payNow = (URL) => {
    if(isValidHttpUrl(URL)){
      window.open(URL, "_blank");
    } else {
      setPaymentLinkNotAvailablePopup(true);
    }
  };

  /** An array of objects that are used to render the buttons in the ButtonGroup component. **/
  const buttonGroup = [
    {
      label: t('pay_now'),
      icon: <WalletIcon/>,
      action: () => payNow(invoice?.paymentLink)
    },
    {
      label:  t('download_invoice'),
      icon: <FileDocumentIcon/>,
      action: () => downloadInvoicePdf()
    },
  ];

  /**
   * It sets the state of the downloadPdfNotAvailablePopup to false
   */
  const closeDownloadPopup = () => {
    setDownloadPdfNotAvailablePopup(false);
  };

  /**
   * It sets the state of the payment link not available popup to false
   */
  const closePaymentPopup = () => {
    setPaymentLinkNotAvailablePopup(false);
  };

  /**
   * It renders a list of contracts, each contract has a list of invoice positions
   */
  const renderInvoicePositions = () => (
    <div className={'contracts-container'}>
      {invoice?.contracts?.map((contract, index) =>
        <div className={'single-contract'} key={index}>
          <div className={'licence bold'}>{contract?.vehicleId}</div>
          <div className={'positions'}>
            {contract?.invoicePositions?.map((invoicePosition, index) =>
              <div className={'position'} key={index}>
                <span className={'title'}>{invoicePosition?.title}</span>
                <span className={'sum'}>{currencyToLocaleFormatter(invoicePosition?.sum)}</span>
              </div>)}
          </div>
        </div>)}
    </div>
  );

  /**
   * It renders a list of invoice positions from general invoices
   */
  const renderGeneralInvoicePositions = () => (
    <>
      {invoice?.general?.invoicePositions && <div className={'invoice-subtitle additional-services-subtitle'}>
        {t('additional_services')}</div>}
      <div className={classNames('contracts-container', { 'margin-top-0': !invoice?.contracts?.length })}>
        <div className={'single-contract'}>
          { !!invoice?.contracts?.length && <div className={'licence bold'} /> }
          <div className={'positions'}>
            {invoice?.general?.invoicePositions?.map((invoicePosition, index) =>
              <div className={'position'} key={index}>
                <span className={'title'}>{invoicePosition?.title}</span>
                <span className={'sum'}>{currencyToLocaleFormatter(invoicePosition?.sum)}</span>
              </div>)}
          </div>
        </div>
      </div>
    </>);

  return(
    <div className={'invoice-details'}>
      {loader && <Spinner/>}
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <div className={'invoice-subtitle'}>{t('individual_invoice')}</div>
          <div className={'individual-invoices'}>
            <div className={'single'}>
              <span>{t('invoice_number')}</span>
              <span>{invoice?.invoiceNumber ?? '-'}</span>
            </div>
            <div className={'single'}>
              <span>{t('invoice_date')}</span>
              <span>{dateFormatter(invoice?.invoiceDate, DATE_FORMATS.full_DMY_dot) || '-'}</span>
            </div>
            <div className={'single'}>
              <span>{t('due_date')}</span>
              <span>{dateFormatter(invoice?.dueDate, DATE_FORMATS.full_DMY_dot) || '-'}</span>
            </div>
            <div className={'single sub-section'}>
              <span>{t('net_total')}</span>
              <span>{currencyToLocaleFormatter(invoice?.sum_total)}</span>
            </div>
            <div className={'single'}>
              <span>{t('vat')}</span>
              <span>{currencyToLocaleFormatter(invoice?.vat_total)}</span>
            </div>
            <div className={'single bold'}>
              <span>{t('gross_total')}</span>
              <span>{currencyToLocaleFormatter(invoice?.sum_total_with_vat)}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={'invoice-subtitle'}>{t('invoice_positions')}</div>
          {renderInvoicePositions()}
          {renderGeneralInvoicePositions()}
        </Grid>
      </Grid>
      <div className={'inv-buttons'}>
        <ButtonGroup className={'appointment-btn-group'} buttonGroup={buttonGroup} />
      </div>
      <AlertCmp
        visible={downloadPdfNotAvailablePopup}
        title={t('report_not_available')}
        onClick={closeDownloadPopup}/>
      <AlertCmp
        visible={paymentLinkNotAvailablePopup}
        title={t('payment_not_available')}
        onClick={closePaymentPopup}/>
    </div>
  );
};

export default InvoiceDetails;
