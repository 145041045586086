import React from 'react';

/** load components **/
import SimpleCard from '@components/Card/SimpleCard';
import ContentLoader from 'react-content-loader';

import './LoadingCard.styles.scss';

const LoadingCard = ({ hasBottom }) => {
  const renderLeft = () => {
    return (
      <ContentLoader
        speed={2}
        width={200}
        height={72}
        viewBox="0 0 200 100"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <circle cx="50" cy="50" r="45" />
        <rect x="100" y="28" rx="0" ry="0" width="240" height="20" />
        <rect x="100" y="54" rx="0" ry="0" width="240" height="20" />
      </ContentLoader>
    );
  };

  const renderRight = () => {
    return (
      <ContentLoader
        speed={2}
        width={200}
        height={72}
        viewBox="0 0 450 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="300" y="10" rx="0" ry="0" width="200" height="30" />
        <rect x="300" y="62" rx="0" ry="0" width="200" height="30" />
        <rect x="250" y="110" rx="8" ry="8" width="250" height="52" />
      </ContentLoader>
    );
  };

  const bottomContent = () => {
    return (
      <ContentLoader
        speed={2}
        width={326}
        height={40}
        viewBox="0 0 400 20"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="8" y="3" rx="0" ry="0" width="376" height="28" />
      </ContentLoader>
    );
  };

  return (
    <SimpleCard className={'loadingCard'} renderLeft={renderLeft} renderRight={renderRight}>
      {hasBottom && bottomContent()}
    </SimpleCard>
  );
};

export default LoadingCard;
