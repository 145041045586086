import React, { useRef, useState } from 'react';
import {useSelector} from "react-redux";

/** load components **/
import Grid from '@mui/material/Grid';
import Slider from '@components/Common/Slider';
import DamageAdditionalUpload from './DamageAdditionalUpload';
import DamageAdditionalInformation from "./DamageAdditionalInformation";
import AnimatedLoader from '@components/Common/AnimatedLoader';

import {media} from "@api/media";
import NoPermission from "@components/Common/NoPermission";

const DamagePictures  = () => {

  const sliderRef = useRef();

  const { damage = {images: []}, damageLoading } = useSelector(state => state.damages);

  const [slides, setSlides] = useState(new Array(damage.images.length).fill(null));

  /**
   * Call back method when damages image is clicked
   **/
  const onImageClick = (index) => {
    sliderRef.current.previewImage(index);
  };
  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = (index) => {
    return media.getFullImage({id: damage.id, name: damage.images[index]?.name, type: 'damage' , category:''});
  };

  /**
   *  Render Animated Loader
   **/
  const renderAnimatedLoader = () => {
    return (<AnimatedLoader />);
  };

  const renderDamagePictures = () =>
        <div className="pictures-container h-100">
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <div className={'damage-pictures-container'}>
                <Grid container spacing={2}>
                  {damage?.images?.map((image, key) => {
                    return (
                        <Grid item xs={3} key={key}>
                          <img className="damage-img"
                               onClick={() => {
                                 onImageClick(key, image.name);
                               }} src={`data:image/png;base64, ${image.blob}`} alt="damageImage"/>
                        </Grid>
                    );
                  })}
                </Grid>
                <DamageAdditionalInformation info={damage?.additionalInformation}/>
              </div>
            </Grid>
            <Grid item xs={5}>
              <DamageAdditionalUpload/>
            </Grid>
          </Grid>
          {
            <Slider
                ref={sliderRef}
                slides={slides}
                setSlides={setSlides}
                imageAPI={loadFullImage}
            />
          }
        </div>


  return (
      <>
        {damageLoading && renderAnimatedLoader()}
        {damage?.id ? renderDamagePictures() : <NoPermission />}
      </>
  );
};

export default DamagePictures;
