import React from 'react';

import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';

import './NoticeBlock.styles.scss';

const NoticeBlock = (props) => {
  const { className, children } = props;

  return (
    <div className={`notice-block ${className}`}>
      <InfoOutlineIcon />
      {children}
    </div>
  );
};

export default NoticeBlock;
