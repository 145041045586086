import React from 'react';

/** Load icons **/
import PersonIcon from 'mdi-react/AccountIcon';
import Check from 'mdi-react/CheckIcon';
import CarIcon from 'mdi-react/CarIcon';
import Close from 'mdi-react/CloseIcon';

/** Load translation helper **/
import { t } from '@helpers/i18n';

/** Load styles **/
import './style.scss';

const CarCheckCheckListPreview = ({ status = [], license }) => {
  const isEv = license[license.length - 1].toLowerCase() === 'e';

  const getCheckIconState = (state) => {
    return parseInt(state) === 1 ? <Check className="green" /> : <Close className="red" />;
  };

  const loadFuelIcons = (field) => {
    if (field.type === 'adblue' || field.type === 'fuel') {
      return (
        <div className={'fuel-levels'}>
          <PersonIcon color="white" />
          <p>{field.state && +field.value > 0 ? field.value : '-'}%</p>
          <CarIcon color="white" className={'ml-2'} />
          <p>{field.state && +field.valueTelemetry > 0 ? field.valueTelemetry : '-'}%</p>
        </div>
      );
    }
    return;
  };

  const renderRowPerType = (record) => {
    let dataRow;
    switch (record.type) {
      case 'tyre_fl':
      case 'tyre_fr':
      case 'tyre_rl':
      case 'tyre_rr':
      case 'windshield':
      case 'warning_triangle':
      case 'logbook_state':
      case 'safety_vest_state':
      case 'breakdown_kit_state':
      case 'green_badge_state':
      case 'lighting_state':
      case 'brake_light_state':
      case 'signal_horn_state':
      case 'oil_level_state':
        {
          dataRow = (
            <div className={'checklist-item'} key={record.type}>
              <span className="item-type">{t(record.type)}</span>
              <span className="item-icon">{getCheckIconState(record.state)}</span>
            </div>
          );
        }
        break;
      case 'charge_cable':
        {
          if (isEv) {
            dataRow = (
              <div className={'checklist-item'} key={record.type}>
                <span className="item-type">{t(record.type)}</span>
                <span className="item-icon">{getCheckIconState(record.state)}</span>
              </div>
            );
          }
        }
        break;
      case 'charge_level':
        {
          if (isEv) {
            dataRow = (
              <div className={'checklist-item'} key={record.type}>
                <span className="item-type">{t(record.type)}</span>
                <span className="item-icon">
                  <div className={'fuel-levels'}>
                    <PersonIcon color="white" />
                    <p>{record.state && +record.value > 0 ? record.value : '-'}%</p>
                  </div>
                </span>
              </div>
            );
          }
        }
        break;
      case 'adblue':
      case 'fuel': {
        if (isEv && record.type === 'adblue') {
          dataRow = <div key={record.type}></div>;
          break;
        }
        dataRow = (
          <div className={'checklist-item'} key={record.type}>
            <span className="item-type">{t(record.type)}</span>
            <span className="item-icon">{loadFuelIcons(record)}</span>
          </div>
        );
        break;
      }
      default:
        break;
    }
    return dataRow;
  };

  return status && <div className={'carCheck-checklist'}>{status.map((record) => renderRowPerType(record))}</div>;
};

export default CarCheckCheckListPreview;
