import React from "react";
import {useSelector} from "react-redux";

/** load components **/
import Grid from '@mui/material/Grid';
import AnimatedLoader from "@components/Common/AnimatedLoader";
import NoPermission from "@components/Common/NoPermission";
import { t } from "@helpers/i18n";
import "external-svg-loader";
import "./index.scss";

const DamagePartLight = () => {

  /**
   * Redux selectors
   */
  const {damage, damageLoading} = useSelector(state => state.damages);

  /**
   * Render Partlight Images
   */
  const getDamageImg = () => {
    if(damage?.partlight){
      return damage?.partlight?.map((el, key) => {
        return <Grid item xs={4} key={key}  alignSelf={'center'}>
          <svg
            data-src={el}
            fill="currentColor"
          />
        </Grid>;
      });
    }
  };

  /**
   *  Render Animated Loader
   **/
  const renderAnimatedLoader = () => {
    return (<AnimatedLoader />);
  };

  /**
   * Render damage positions classification
   * @returns {*}
   */
  const renderClassification = () => {
    return damage?.damagePositions?.map((position, key) => {
      const { description } = position;
      const desc = description ? description :'-';
      return (<p className="damage-content" key={key}>{ desc }</p>);
    });
  };

  /**
   * It renders a grid with two columns, the first column contains a grid with the damage images,
   * the second column contains the damage classification
   */
  const renderDamagePartlight = () =>
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          {getDamageImg()}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <div className={'damage-guidance'}>
          <p className="damage-classification">{t('damage_classification')}</p>
          {renderClassification()}
        </div>
      </Grid>
    </Grid>;

  return (
    <>
      {damageLoading && renderAnimatedLoader()}
      {damage?.id ? renderDamagePartlight() : <NoPermission />}
    </>
  );
};

export default DamagePartLight;
