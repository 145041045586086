export const DAILY_RENT_GET_DETAILS_REQUEST = 'DAILY_RENT_GET_DETAILS_REQUEST';
export const DAILY_RENT_GET_DETAILS_SUCCESS = 'DAILY_RENT_GET_DETAILS_SUCCESS';
export const DAILY_RENT_GET_DETAILS_FAIL = 'DAILY_RENT_GET_DETAILS_FAIL';

export const DAILY_RENT_LOAD_MORE_REQUEST = 'DAILY_RENT_LOAD_MORE_REQUEST';
export const DAILY_RENT_LOAD_MORE_SUCCESS = 'DAILY_RENT_LOAD_MORE_SUCCESS';
export const DAILY_RENT_LOAD_MORE_FAIL = 'DAILY_RENT_LOAD_MORE_FAIL';

export const DAILY_RENT_GET_REQUEST = 'DAILY_RENT_GET_REQUEST';
export const DAILY_RENT_GET_SUCCESS = 'DAILY_RENT_GET_SUCCESS';
export const DAILY_RENT_GET_FAIL = 'DAILY_RENT_GET_FAIL';

export const DAILY_RENT_GET_HANDOVER_REPORT_REQUEST = 'DAILY_RENT_GET_HANDOVER_REPORT_REQUEST';
export const DAILY_RENT_GET_HANDOVER_REPORT_SUCCESS = 'DAILY_RENT_GET_HANDOVER_REPORT_SUCCESS';
export const DAILY_RENT_GET_HANDOVER_REPORT_FAIL = 'DAILY_RENT_GET_HANDOVER_REPORT_FAIL';

export const DAILY_RENT_GET_RETURN_REPORT_REQUEST = 'DAILY_RENT_GET_RETURN_REPORT_REQUEST';
export const DAILY_RENT_GET_RETURN_REPORT_SUCCESS = 'DAILY_RENT_GET_RETURN_REPORT_SUCCESS';
export const DAILY_RENT_GET_RETURN_REPORT_FAIL = 'DAILY_RENT_GET_RETURN_REPORT_FAIL';