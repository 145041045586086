import React from 'react';
import './index.scss';
import classnames from 'classnames';
import { BUTTON_TYPES } from '@constants';

export const Button = ({
  text = '',
  type = BUTTON_TYPES.DANGER,
  icon,
  isSubmit = false,
  className,
  isDisabled = false,
  isFlat = false,
  isSmall = false,
  isLoading = false,
  onClick = () => {}
}) => {
  const renderIcon = () => (isLoading ? null : <>{icon}</>);

  return (
    <button
      className={classnames('button', type, className, {
        'is-small': isSmall,
        'is-flat': isFlat,
        'is-loading': isLoading,
        disabled: isDisabled
      })}
      type={isSubmit ? 'submit' : 'button'}
      disabled={isDisabled}
      onClick={onClick}>
      {icon && renderIcon()}
      {text && <div className="button-text">{text}</div>}
    </button>
  );
};
