import React from 'react';
import './index.scss';
import classnames from 'classnames';

export const ButtonList = (props) => {
  const { column = false } = props;

  return (
    <div
      className={classnames('button-list', {
        buttonColumn: column
      })}>
      {props.children}
    </div>
  );
};
