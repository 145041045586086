import React, { useState } from 'react';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { parse, format } from 'date-fns';
import { t } from 'helpers/i18n';
import classnames from 'classnames';

import './index.scss';

const WEEKDAYS_MAP = new Map([
  ['monday', 'Mon'],
  ['tuesday', 'Tue'],
  ['wednesday', 'Wed'],
  ['thursday', 'Thu'],
  ['friday', 'Fri'],
  ['saturday', 'Sat'],
  ['sunday', 'Sun']
]);

export const WorkingHours = ({ data, station }) => {
  const [expanded, setExpanded] = useState(false);

  /**
   * It takes an array of objects and a string as parameters and returns a string
   * @param data - The data that is returned from the API.
   * @param workTimePoint - This is the point in time you want to extract. It can be either "from" or
   * "to".
   * @returns the opening or closing time of a gas station.
   */
  const extractWorkingTime = (data, workTimePoint) => {
    if (data == []) {
      return '00:00';
    }

    const dayToday = new Date().getDay();

    let param1 = '';
    let param2 = '';
    let param3 = '';
    let timeForWorkingPointOfToday = '';

    switch (dayToday) {
      case 0:
        param1 = 'Sonntag';
        param3 = 'Mo - So';
        break;
      case 1:
        param1 = 'Montag';
        param2 = 'Mo - Fr';
        param3 = 'Mo - So';
        break;
      case 2:
        param1 = 'Dienstag';
        param2 = 'Mo - Fr';
        param3 = 'Mo - So';
        break;
      case 3:
        param1 = 'Mittwoch';
        param2 = 'Mo - Fr';
        param3 = 'Mo - So';
        break;
      case 4:
        param1 = 'Donnerstag';
        param2 = 'Mo - Fr';
        param3 = 'Mo - So';
        break;
      case 5:
        param1 = 'Freitag';
        param2 = 'Mo - Fr';
        param3 = 'Mo - So';
        break;
      case 6:
        param1 = 'Samstag';
        param3 = 'Mo - So';
        break;
      default:
        break;
    }

    const workingHoursInWeek = Object.values(data);

    timeForWorkingPointOfToday = workingHoursInWeek.find(
      (day) => day.day === param1 || day.day === param2 || day.day === param3
    )[`${workTimePoint}`];

    return timeForWorkingPointOfToday.substring(0, 5);
  };

  const openFrom = (weekDay) => {
    if (station) {
      return extractWorkingTime(data, 'from');
    }

    if (Array.isArray(data)) {
      return '00:00';
    } else {
      weekDay = weekDay || format(new Date(), 'eee');
      const { [weekDay]: day } = data;
      return Object.is(day?.from, 'closed') || !day?.from ? t('closed-now') : day?.from;
    }
  };

  const openTo = (weekDay) => {
    if (station) {
      return extractWorkingTime(data, 'to');
    }

    if (Array.isArray(data)) {
      return '23:59';
    } else {
      weekDay = weekDay || format(new Date(), 'eee');

      const { [weekDay]: day } = data;

      return day?.to || '';
    }
  };

  const isOpen = () => {
    const date = new Date();

    // TODO add logic for array working time

    return (
      date >= parse(openFrom(), 'HH:mm', new Date()) &&
      date <= parse(openTo() !== '00:00' ? openTo() : '23:59', 'HH:mm', new Date())
    );
  };

  const timeRange = (weekDay) => {
    const from = openFrom(weekDay);
    const to = openTo(weekDay);

    if (!to) {
      return from;
    }
    return `${from} - ${to}`;
  };

  const timeRangeArray = (from, to) => {
    if (!to) {
      return from;
    }
    return `${from} - ${to}`;
  };

  const renderArray = () => {
    const timeNow = new Date().getTime();

    return data.map((hour, key) => (
      <li key={timeNow + key} className="hours-line">
        <div className="hours-icon" />
        <div className="hours-day">{hour.day}</div>
        <div className="hours-hours">{timeRangeArray(hour.from, hour.to)}</div>
      </li>
    ));
  };

  const renderObject = () => {
    if (!data) {
      return null;
    }

    return (
      <>
        {Array.from(WEEKDAYS_MAP.entries()).map(([key, value], index) => (
          <div key={index} className="hours-line">
            <div className="hours-icon" />
            <div className="hours-day">{t(key)}</div>
            <div className="hours-hours">{timeRange(value)}</div>
          </div>
        ))}
      </>
    );
  };

  if (!data || data.length === 0) {
    return (
      <div className="working-hours">
        <div className="hours-line">
          <div className="hours-icon">
            <ClockOutlineIcon />
          </div>
          <div className="hours-day">{t('no-data')}</div>
        </div>
      </div>
    );
  }

  if (typeof data === 'string') {
    return (
      <div className="working-hours">
        <div className="hours-line">
          <div className="hours-icon">
            <ClockOutlineIcon />
          </div>
          <div className="hours-day">{data}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="working-hours">
      <div className="hours-line">
        <div className="hours-icon">
          <ClockOutlineIcon />
        </div>
        <div className="hours-day">{isOpen() ? t('open-now') : t('closed-now')}</div>
        <div className="hours-hours">{!expanded && timeRange()}</div>
        <ChevronDownIcon className={classnames('expand-icon', { expanded })} onClick={() => setExpanded(!expanded)} />
      </div>
      {expanded && (Array.isArray(data) ? renderArray() : renderObject())}
    </div>
  );
};
