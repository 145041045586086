import React from 'react';
/** Load components **/
import Toggler from '@components/Common/Toggler';

/** Load translation helper **/
import { t } from '@helpers/i18n';

const AppointmentFilterForm = ({ filters = [], onChange = () => {} }) => {
  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        {filters.map((filter) => (
          <label key={filter.id}>
            <p>{t(filter.filter)}</p>
            <Toggler id={filter.id.toString()} checked={filter.value} onChange={onChange} />
          </label>
        ))}
      </div>
    </div>
  );
};

export default AppointmentFilterForm;
