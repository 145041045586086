import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/** Load Components **/
import DriverSearch from './DriverSelectUpdate.component';
import LicensePopup from '@components/Vehicle/General/VehicleForm/DriverLicensePopup';

import { Api } from '@api';

const DriverDropdownUpdate = ({ search, setSearch, onDriverSelected }) => {
  /**
   * Redux selectors
   */
  const { auth } = useSelector((state) => state.auth); // todo add as prop

  /**
   * Local states
   */
  const [licenseImage, setLicenseImage] = useState(null);
  const [showLicenseImg, setShowLicenseImg] = useState(false);

  /**
   * Show License image toggle
   */
  const closePopup = () => {
    setShowLicenseImg(false);
  };

  /**
   * Create prop object for LicensePopup component
   * @type {{visible: boolean, licenseImg: unknown, closePopup: closePopup}}
   */
  const licensePopupProps = { visible: showLicenseImg, licenseImg: licenseImage, closePopup };

  /**
   * Load Drivers for Driver Search
   * @param search
   * @param page
   * @returns {Promise<*>}
   */
  const getDrivers = async ({ search, page }) => {
    // here we get dropdown list elements & can use it as state in parent
    const { data } = await Api.vehicles.loadDrivers(auth.user.id, `search=${search}&page=${page}`);
    // load drivers that only have name & surname
    const filteredDrivers = data.filter((driver) => driver.name && driver.surname);
    return filteredDrivers;
  };

  /**
   * Select Driver on Driver Search
   * @param item
   * @returns {Promise<void>}
   */
  const driverSelected = async (item) => {
    const { userId } = item;
    setShowLicenseImg(true);
    setLicenseImage(null);
    const { data } = await Api.vehicles.loadDriverRegistration(userId);
    const licenseImg = data.driverLicence.frontImage.blob;
    if (licenseImg) {
      setLicenseImage(licenseImg);
    } else {
      setShowLicenseImg(false);
    }
    onDriverSelected(item);
  };

  return (
    <div className={'driver-select-cmp flex relative'}>
      <DriverSearch
        search={search}
        setSearch={setSearch}
        getDrivers={getDrivers}
        itemSelected={driverSelected}
        licenseImage={licenseImage}
        showLicenseImg={showLicenseImg}
      />
      <LicensePopup {...licensePopupProps} />
    </div>
  );
};

export default DriverDropdownUpdate;
