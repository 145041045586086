import React, { useState } from 'react';

/** Load components **/
import DamageAdditionalInfo from './DamageAdditonalInfo';
import Loader from '../../../../../../../Common/Loader';

/** Load icons **/
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

/** load api call **/
import { Api } from 'api';

/** Load translation helper **/
import { t } from 'helpers/i18n';

/** Load styles **/
import './style.scss';
/** load formatters **/
import { dateFormatted } from '@services/formatters';

const DamageItem = ({ damage = {}, index = 0 }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [damageLoading, setDamageLoading] = useState(false);
  const [damageInfo, setDamageInfo] = useState(null);

  const { id, damageDate, description } = damage;

  const loadDamage = async (damageId) => {
    const { data } = await Api.damages.getDamage(damageId);
    return data;
  };

  const damageClicked = async (e) => {
    e.stopPropagation();

    if (!damageInfo) {
      setDamageLoading(true);
      try {
        const damage = await loadDamage(id);
        setDamageInfo(damage);
        setDamageLoading(false);
      } catch (err) {
        console.log('err', err);
      }
    }
    setShowInfo(!showInfo);
  };

  return (
    <div className={'damage-item'} key={index}>
      <div className={'damage-item-row'} onClick={damageClicked}>
        <div className={'row text-blue split'}>
          <p className={'bold'}>
            {' '}
            {t('damage')} {index + 1}
          </p>
          <p> {dateFormatted(damageDate)}</p>
        </div>
        <div className={'row text-blue split'}>
          <p>{description}</p>
          <div className={`arrow-icon ${showInfo && 'up'}`}>
            <ChevronDownIcon />
          </div>
        </div>
      </div>
      {damageLoading && (
        <div className={'damage-loader'}>
          <Loader />
        </div>
      )}
      {showInfo && <DamageAdditionalInfo info={damageInfo} />}
    </div>
  );
};

export default DamageItem;
