import React from 'react';

/** load components **/
import SideNavigation from "@components/SideBarNavigation";
import { sideMenuItems } from '../../../routes/sideMenuItems';

const SideMenu = () => {
  return (<div className={'side-menu'}>
    <SideNavigation routes={sideMenuItems} />
  </div>
  );
};


export default SideMenu;
