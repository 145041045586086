export const CONTRACT_GET_REQUEST = 'CONTRACT_GET_REQUEST';
export const CONTRACT_GET_SUCCESS = 'CONTRACT_GET_SUCCESS';
export const CONTRACT_GET_FAIL = 'CONTRACT_GET_FAIL';

export const CONTRACT_LOAD_MORE_REQUEST = 'CONTRACT_LOAD_MORE_REQUEST';
export const CONTRACT_LOAD_MORE_SUCCESS = 'CONTRACT_LOAD_MORE_SUCCESS';
export const CONTRACT_LOAD_MORE_FAIL = 'CONTRACT_LOAD_MORE_FAIL';

export const CONTRACT_GET_DETAILS_REQUEST = 'CONTRACT_GET_DETAILS_REQUEST';
export const CONTRACT_GET_DETAILS_SUCCESS = 'CONTRACT_GET_DETAILS_SUCCESS';
export const CONTRACT_GET_DETAILS_FAIL = 'CONTRACT_GET_DETAILS_FAIL';

export const CONTRACT_DETAILS_RESET = 'CONTRACT_DETAILS_RESET';
