import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import * as damagesConstants from './constants';
import { Api } from "@api";


function* getDamages(action) {
  try {
    const { userId, page, filter, search } = action.payload;

    let query = `${userId}/damage?page=${page}${filter}`;
    query = search ? query.concat(`&search=${search}`) : query;
    const { data: damages } = yield call(Api.damages.getDamages, query);

    yield put({ type: damagesConstants.DAMAGE_GET_SUCCESS, payload: damages });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_FAIL });
  }
}

function* loadMoreDamages(action) {
  try {
    const { userId, page, filter, search } = action.payload;

    let query = `${userId}/damage?page=${page}${filter}`;
    query = search ? query.concat(`&search=${search}`) : query;
    const { data: damages } = yield call(Api.damages.getDamages, query);

    yield put({ type: damagesConstants.DAMAGE_GET_MORE_SUCCESS, payload: damages });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_MORE_FAIL });
  }
}

function* getDamage(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.damages.getDamage, userId);

    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_FAIL });
  }
}

function* getDamageDetail(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.damages.getDamageDetail, userId);

    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_DETAILED_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_DETAILED_FAIL });
  }
}

function* getDamageDetailImage(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.damages.getDamageDetailImage, userId);

    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_BY_ID_IMG_DETAILED_FAIL });
  }
}

function* getImgByIdDamage(action) {
  try {
    const { data } = yield call(Api.damages.getImgByIdDamage, action.payload);

    yield put({ type: damagesConstants.GET_IMG_REQUEST_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.GET_IMG_REQUEST_FAIL });
  }
}

function* getImgByIdArray(action) {
  try {
    const { data } = yield call(Api.damages.getImgByIdDamage, action.payload);
    yield put({ type: damagesConstants.GET_IMG_REQUEST_ARRAY_SUCCESS, payload: {data: data, key: action.key}});
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.GET_IMG_REQUEST_ARRAY_FAIL });
  }
}

function* getDefaultPartlightPictures(action) {
  try {
    const { type, sides } = action.payload;
    const damagePictures = {};
    for (const side of sides) {
      const { data } = yield call(Api.damages.getDefaultPartlightPictures, type, side);
      damagePictures[side]=data;
    }

    yield put({
      type: damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS,
      payload: damagePictures,
    });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL });
  }
}

function* sendDamageReclamation(action) {
  try {
    const {payload} = action;
    yield call(Api.damages.sendDamageReclamation, payload);
    yield put({ type: damagesConstants.SEND_RECLAMATION_SUCCESS });
  } catch (e) {
    yield put({ type: damagesConstants.SEND_RECLAMATION_FAIL });
  }
}

function* uploadSingleDamageImage(action) {
  const {image, damage} = action.payload;
  try {
    const damageImageId = image.identifier.replace('xx', damage.id);
    const blob = {
      'blob': image.blob,
      'name': `carcheck_${ damageImageId }`,
      'type': 'carcheck'
    };
    yield call(Api.damages.uploadImage, {imageId: damageImageId, data: blob});
  } catch (err) {
    yield put({type: damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL});
    yield call(errorHandler, err);
  }
}

function* uploadDamageImages(action) {
  try {
    const {damage, successCallback} = action.payload;
    const damageImages = [...damage.images];
    for (let i = 0; i < damageImages.length; i++) {
      if (!damageImages[i].finished) {
        yield call(uploadSingleDamageImage, {type: damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST,
          payload: {image: damageImages[i], damage}});
      }
    }
    yield put({type: damagesConstants.DAMAGE_COMPLETE_REQUEST, payload: {successCallback, damage}});
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: damagesConstants.DAMAGE_UPLOAD_ALL_IMAGES_FAIL});
  }
}

function* uploadDamage(action) {
  try {
    const {requestData, successCallback} = action.payload;
    const request = {
      ...requestData,
      images: requestData.images.map(img => ({...img, blob: ''}))
    };
    const {data} = yield call(Api.damages.createDamage, request);
    yield put({type: damagesConstants.DAMAGE_UPLOAD_ALL_IMAGES_REQUEST, payload: {successCallback, damage: {...requestData, id: data.id}}});
  } catch (e) {
    yield call(errorHandler, e);
    yield put({type: damagesConstants.REPORT_DAMAGE_FAIL});
  }
}

function* finishDamage(action) {
  try {
    const {damage, successCallback} = action.payload;
    const {data} = yield call(Api.damages.finishDamage, {damageId: damage.id});
    yield put({type: damagesConstants.DAMAGE_COMPLETE_SUCCESS, payload: {damage, responseDamage: data}});
    yield call(successCallback);
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: damagesConstants.DAMAGE_COMPLETE_FAIL});
  }
}

function* addAdditionalDamageInformation(action) {
  try {
    const { information, id } = action.payload;
    const request = {
      ...information,
      images: information.images.map(img => ({...img, blob: ''}))
    };
    const { data } = yield call(Api.damages.createDamageInformation, id, request);
    yield put({type: damagesConstants.DAMAGE_UPLOAD_INFO_IMAGES_REQUEST, payload: {damage: {damageId :id, ...data }, images: information.images}});
    const res = yield call(Api.damages.createDamageInformation, id, information);
    // yield put({type: damagesConstants.DAMAGE_ADD_INFO_SUCCESS, payload: { responseDamage: data}});
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: damagesConstants.DAMAGE_ADD_INFO_FAIL});
  }
}



function* updateDamageInfoImages(action) {
  try {
    const { images, damage } = action.payload;
    for (let i = 0; i < images.length; i++) {
      if (!images[i].finished) {
        yield call(uploadSingleDamageImage, {type: damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST,
          payload: {image: images[i], damage: { id: damage.id }}});
      }
    }
    yield put({type: damagesConstants.DAMAGE_UPLOAD_COMPLETE_REQUEST, payload: {damage}});
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: damagesConstants.DAMAGE_ADD_INFO_FAIL});
  }
}

function* completeDamageInformation(action) {
  try {
    const {damage} = action.payload;
    yield call(Api.damages.completeDamageInformation, damage.id, damage);
    yield put({type: damagesConstants.DAMAGE_GET_BY_ID_REQUEST, payload: { userId: damage.damageId}});
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: damagesConstants.DAMAGE_ADD_INFO_FAIL});
  }
}
function* getDamagesSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_REQUEST, getDamages);
}

function* loadMoreDamagesSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_MORE_REQUEST, loadMoreDamages);
}

function* getDamageSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_BY_ID_REQUEST, getDamage);
}

function* getDamageDetailedSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_BY_ID_DETAILED_REQUEST, getDamageDetail);
}

function* getDamageDetailImageSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_BY_ID_IMG_DETAILED_REQUEST, getDamageDetailImage);
}

function* getImgByIdDamageSaga() {
  yield takeLatest(damagesConstants.GET_IMG_REQUEST, getImgByIdDamage);
}

function* getImgByIdArraySaga() {
  yield takeEvery(damagesConstants.GET_IMG_REQUEST_ARRAY, getImgByIdArray);
}

function* getDefaultPartlightPicturesSaga() {
  yield takeLatest(damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST, getDefaultPartlightPictures);
}

function* sendDamageReclamationSaga() {
  yield takeLatest(damagesConstants.SEND_RECLAMATION_REQUEST, sendDamageReclamation);
}


export function* damageSagas() {
  yield all([
    call(getDamagesSaga),
    call(loadMoreDamagesSaga),
    call(getDamageSaga),
    call(getDamageDetailedSaga),
    call(getDamageDetailImageSaga),
    call(getImgByIdDamageSaga),
    call(getImgByIdArraySaga),
    call(getDefaultPartlightPicturesSaga),
    call(sendDamageReclamationSaga),
    takeEvery(damagesConstants.DAMAGE_UPLOAD_ALL_IMAGES_REQUEST, uploadDamageImages),
    takeEvery(damagesConstants.DAMAGE_COMPLETE_REQUEST, finishDamage),
    takeEvery(damagesConstants.REPORT_DAMAGE_REQUEST, uploadDamage),
    takeEvery(damagesConstants.DAMAGE_ADD_INFO_REQUEST, addAdditionalDamageInformation),
    takeEvery(damagesConstants.DAMAGE_UPLOAD_INFO_IMAGES_REQUEST, updateDamageInfoImages ),
    takeEvery(damagesConstants.DAMAGE_UPLOAD_COMPLETE_REQUEST, completeDamageInformation )
  ]);
}
