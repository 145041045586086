import React from 'react';
import { Tooltip } from '@mui/material';
import './ReportingInfoTooltip.styles.scss';

const ReportingInfoTooltipComponent = (props) => {
  return (
    <Tooltip arrow placement="top" title={props.tooltipText}>
      <div className={'tooltip-button'}>?</div>
    </Tooltip>
  );
};

export default ReportingInfoTooltipComponent;
