/*
|  App
*/

export const LANGUAGE_BG = "bg-BG";
export const LANGUAGE_DE = "de-DE";
export const LANGUAGE_EN = "en-GB";
export const LANGUAGE_PL = "pl-PL";
export const LANGUAGE_RO = "ro-RO";
export const LANGUAGE_TR = "tr-TR";

export const translationKeys = {
  bg: LANGUAGE_BG,
  de: LANGUAGE_DE,
  en: LANGUAGE_EN,
  pl: LANGUAGE_PL,
  ro: LANGUAGE_RO,
  tr: LANGUAGE_TR,
};

export const LANGUAGE_DEFAULT = LANGUAGE_EN;
export const MISSING_TRANSLATION_MESSAGE = "Missing Translation";


export const LIGHT_COLOR_GREEN = "#95C11E";
export const LIGHT_COLOR_RED = "#E84D0E";


/*
|  Map
*/


export const MAP_GET_STATION_LOCATIONS_REQUEST =
  "MAP_GET_STATION_LOCATIONS_REQUEST";


export const MAP_CLEAR_SERVICE_PARTNER_LOCATIONS =
  "MAP_CLEAR_SERVICE_PARTNER_LOCATIONS";

export const MAP_CLEAR_STATION_LOCATIONS = "MAP_CLEAR_STATION_LOCATIONS";

export const MAP_COORDINATES_SET = "MAP_COORDINATES_SET";
export const MAP_COORDINATES_CLEAR = "MAP_COORDINATES_CLEAR";

/*
|  Tickets
*/
export const VISION_TICKET_REQUEST = "VISION_TICKET_REQUEST";
export const VISION_TICKET_SUCCESS = "VISION_TICKET_SUCCESS";
export const VISION_TICKET_FAIL = "VISION_TICKET_FAIL";
export const VISION_STORE_1_CLEAR = "VISION_STORE_1_CLEAR";
export const VISION_STORE_2_CLEAR = "VISION_STORE_2_CLEAR";
export const VISION_STORE_3_CLEAR = "VISION_STORE_3_CLEAR";

export const GET_PAYLINK_REQUEST = "GET_PAYLINK_REQUEST";
export const GET_PAYLINK_SUCCESS = "GET_PAYLINK_SUCCESS";
export const GET_PAYLINK_FAIL = "GET_PAYLINK_FAIL";

/*
|  Profile
*/

export const PROFILE_GET_REQUEST = "PROFILE_GET_REQUEST";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAIL = "PROFILE_GET_FAIL";


export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const PROFILE_DELETE_REQUEST = "PROFILE_DELETE_REQUEST";
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS";
export const PROFILE_DELETE_FAIL = "PROFILE_DELETE_FAIL";



/*
|  Damages
*/

export const damagesConstants = {
  DAMAGE_GET_REQUEST: 'DAMAGE_GET_REQUEST',
  DAMAGE_GET_SUCCESS: 'DAMAGE_GET_SUCCESS',
  DAMAGE_GET_FAIL: 'DAMAGE_GET_FAIL',

  DAMAGE_FILTER_GET_REQUEST: 'DAMAGE_FILTER_GET_REQUEST',
  DAMAGE_FILTER_GET_SUCCESS: 'DAMAGE_FILTER_GET_SUCCESS',
  DAMAGE_FILTER_GET_FAIL: 'DAMAGE_FILTER_GET_FAIL',

  DAMAGE_GET_MORE_REQUEST: 'DAMAGE_GET_MORE_REQUEST',
  DAMAGE_GET_MORE_SUCCESS: 'DAMAGE_GET_MORE_SUCCESS',
  DAMAGE_GET_MORE_FAIL: 'DAMAGE_GET_MORE_FAIL',

  DAMAGE_GET_BY_ID_REQUEST: 'DAMAGE_GET_BY_ID_REQUEST',
  DAMAGE_GET_BY_ID_SUCCESS: 'DAMAGE_GET_BY_ID_SUCCESS',
  DAMAGE_GET_BY_ID_FAIL: 'DAMAGE_GET_BY_ID_FAIL',

  DAMAGE_GET_BY_ID_DETAILED_REQUEST: 'DAMAGE_GET_BY_ID_DETAILED_REQUEST',
  DAMAGE_GET_BY_ID_DETAILED_SUCCESS: 'DAMAGE_GET_BY_ID_DETAILED_SUCCESS',
  DAMAGE_GET_BY_ID_DETAILED_FAIL: 'DAMAGE_GET_BY_ID_DETAILED_FAIL',

  DAMAGE_GET_BY_ID_IMG_DETAILED_CLEAR: 'DAMAGE_GET_BY_ID_IMG_DETAILED_CLEAR',

  DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST: 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST',
  DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS: 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS',
  DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL: 'DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL',

  DAMAGE_GET_BY_ID_IMG_DETAILED_REQUEST: 'DAMAGE_GET_BY_ID_IMG_DETAILED_REQUEST',
  DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS: 'DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS',
  DAMAGE_GET_BY_ID_IMG_DETAILED_FAIL: 'DAMAGE_GET_BY_ID_IMG_DETAILED_FAIL',

  DAMAGE_GET_INCOMPLETE_REQUEST: 'DAMAGE_GET_INCOMPLETE_REQUEST',
  DAMAGE_GET_INCOMPLETE_SUCCESS: 'DAMAGE_GET_INCOMPLETE_SUCCESS',
  DAMAGE_GET_INCOMPLETE_FAIL: 'DAMAGE_GET_INCOMPLETE_FAIL',

  DAMAGE_UPLOAD_ALL_IMAGES_REQUEST: 'DAMAGE_UPLOAD_ALL_IMAGES_REQUEST',
  DAMAGE_UPLOAD_ALL_IMAGES_SUCCESS: 'DAMAGE_UPLOAD_ALL_IMAGES_SUCCESS',
  DAMAGE_UPLOAD_ALL_IMAGES_FAIL: 'DAMAGE_UPLOAD_ALL_IMAGES_FAIL',

  DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST: 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST',
  DAMAGE_UPLOAD_IMAGE_INCOMPLETE_SUCCESS: 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_SUCCESS',
  DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL: 'DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL',

  DAMAGE_COMPLETE_REQUEST: 'DAMAGE_COMPLETE_REQUEST',
  DAMAGE_COMPLETE_SUCCESS: 'DAMAGE_COMPLETE_SUCCESS',
  DAMAGE_COMPLETE_FAIL: 'DAMAGE_COMPLETE_FAIL',

  GET_EXPORT_DAMAGE_REQUEST: 'GET_EXPORT_DAMAGE_REQUEST',
  GET_EXPORT_DAMAGE_SUCCESS: 'GET_EXPORT_DAMAGE_SUCCESS',
  GET_EXPORT_DAMAGE_FAIL: 'GET_EXPORT_DAMAGE_FAIL',

  GET_IMG_REQUEST: 'GET_IMG_REQUEST',
  GET_IMG_REQUEST_SUCCESS: 'GET_IMG_REQUEST_SUCCESS',
  GET_IMG_REQUEST_EMPTY: 'GET_IMG_REQUEST_EMPTY',
  GET_IMG_REQUEST_FAIL: 'GET_IMG_REQUEST_FAIL',

  GET_IMG_REQUEST_ARRAY: 'GET_IMG_REQUEST_ARRAY',
  GET_IMG_REQUEST_ARRAY_SUCCESS:'GET_IMG_REQUEST_ARRAY_SUCCESS',
  GET_IMG_REQUEST_ARRAY_EMPTY: 'GET_IMG_REQUEST_ARRAY_EMPTY',
  GET_IMG_REQUEST_ARRAY_FAIL:'GET_IMG_REQUEST_ARRAY_FAIL',

  CLEAR_IMG_REQUEST: 'CLEAR_IMG_REQUEST',
  SET_CURRENT_DAMAGE: 'SET_CURRENT_DAMAGE',
  CLEAN_CURRENT_DAMAGE: 'CLEAN_CURRENT_DAMAGE',

  REPORT_DAMAGE_REQUEST: 'REPORT_DAMAGE_REQUEST',
  REPORT_DAMAGE_SUCCESS: 'REPORT_DAMAGE_SUCCESS',
  REPORT_DAMAGE_FAIL: 'REPORT_DAMAGE_FAIL',

  SEND_RECLAMATION_RESET: 'SEND_RECLAMATION_RESET',
  SEND_RECLAMATION_REQUEST: 'SEND_RECLAMATION_REQUEST',
  SEND_RECLAMATION_SUCCESS: 'SEND_RECLAMATION_SUCCESS',
  SEND_RECLAMATION_FAIL: 'SEND_RECLAMATION_FAIL',

  REMOVE_DAMAGE_SUCCESS: 'REMOVE_DAMAGE_SUCCESS'
};


export const VEHICLE_GET_REQUEST = "VEHICLE_GET_REQUEST";
export const VEHICLE_GET_SUCCESS = "VEHICLE_GET_SUCCESS";
export const VEHICLE_GET_FAIL = "VEHICLE_GET_FAIL";



//components/VehiclesInfo
export const GET_TRIPS_REQUEST = "GET_TRIPS_REQUEST";
export const GET_TRIPS_SUCCESS = "GET_TRIPS_SUCCESS";
export const GET_TRIPS_FAIL = "GET_TRIPS_FAIL";
export const SELECT_VEHICLES = "SELECT_VEHICLES";
export const SELECT_EMPLOYEE = "SELECT_EMPLOYEE";
export const SELECT_SERVICE_MAP = "SELECT_SERVICE_MAP";
export const SELECT_STATION_MAP = "SELECT_STATION_MAP";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const SELECT_ID = "SELECT_ID";
export const SET_MARKER = "SET_MARKER";
export const CLEAR_MARKER = "CLEAR_MARKER";
export const HOVER_MARKER = "HOVER_MARKER";
export const HOVER_MAKER_STATION = "HOVER_MAKER_STATION";
export const HOVER_MAKER_SERVICE = "HOVER_MAKER_SERVICE";



/*
| Map
*/
export const SET_ALL_STATIONS = "SET_ALL_STATIONS";


//VehiclesInfo/Setting
export const SET_MAP_SATELLITE = "SET_MAP_SATELLITE";
export const SET_MAP_SERVICE_PARTNERS = "SET_MAP_SERVICE_PARTNERS";
export const SET_MAP_STATION = "SET_MAP_STATION";
export const SET_SELECT_STATIONS = "SET_SELECT_STATIONS";
export const SET_TYPE_FUEL = "SET_TYPE_FUEL";
export const SET_COUNT_FILTER = "SET_COUNT_FILTER";

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const CLEAR_CURRENT_TAB = "CLEAR_CURRENT_TAB";

export const DAMAGES = "DAMAGES";
export const SET_STATUS_CALENDAR = "SET_STATUS_CALENDAR";
export const SET_STATUS_SIDEBAR = "SET_STATUS_SIDEBAR";
export const CLEAR_STATUS_SIDEBAR = "CLEAR_STATUS_SIDEBAR";

export const PUT_AVATAR_REQUEST = "PUT_AVATAR_REQUEST";
export const PUT_AVATAR_CLEAR = "PUT_AVATAR_CLEAR";

/*
* Reporting
*/

export const GET_REPORTING_DATA_REQUEST = 'GET_REPORTING_DATA_REQUEST';
export const GET_REPORTING_DATA_SUCCESS = 'GET_REPORTING_DATA_SUCCESS';
export const GET_REPORTING_DATA_FAILURE = 'GET_REPORTING_DATA_FAILURE';

export const UPDATE_REPORTING_CONSTANTS_REQUEST = 'UPDATE_REPORTING_CONSTANTS_REQUEST';
export const UPDATE_REPORTING_CONSTANTS_SUCCESS = 'UPDATE_REPORTING_CONSTANTS_SUCCESS';
export const UPDATE_REPORTING_CONSTANTS_FAILURE = 'UPDATE_REPORTING_CONSTANTS_FAILURE';

/*
|  Markers
*/

export const PRICE_LEVELS = {
  HIGH: "high",
  MIDDLE: "middle",
  LOW: "low",
};



export const DAMAGE_CARD_TYPE = {
  0: ["offen"],
  1: [
    "in bearbeitung /versand offen",
    "in bearbeitung /cc",
    "in bearbeitung /position/en fehl/t/en",
    "in bearbeitung /abschließen",
    "haftung strittig",
    "warten auf gutachten",
    "in bearbeitung",
    "in bearbeitung / kundenreklamation",
    "regulierung / prozess / klage",
    "dringende bearbeitung/prüfung",
    "in bearbeitung/bilder fehlen"
  ],
  2: ["in werkstatt"],
  3: [
    "reguliert/reparatur offen",
    "reguliert/teilweise repariert",
    "reguliert/repariert",
    "in regulierung",
    "in regulierung mit gegnerischer vers",
    "in regulierung mit fl versicherung",
    "regulierung offen/repariert",
    'regulierung offen / repariert',
    'regulierung offen / reparatur offen'
  ],
  4: ["abgeschlossen"],
  5: [
    "in bearbeitung/kundenmeldung fehlt",
    "in bearbeitung /gegnerdaten fehlen",
    "in bearbeitung /bilder fehlen",
    "in bearbeitung /daten für ra fehlen",
  ],
};

export const DAMAGE_APP_STATES = [
  'pending',
  'in_process',
  'in_workshop',
  'regulated',
  'completed',
  'missing_data',
  'reclamation'
];



export const FILTER_BY_FINISHED = new RegExp("finished|manual");
export const RED = new RegExp("(^|\\W)red($|\\W)");
export const SUCCESSFUL = "(^|\\W)green_tick($|\\W)";

export const FRESH = "(^|\\W)new($|\\W)";
export const OPEN = "(^|\\W)open($|\\W)";
export const OBJECTED_TICKET = "(^|\\W)objected($|\\W)";
export const FINISHED =
  "(^|\\W)completed(^|\\W)|(^|\\W)manual(^|\\W)|(^|\\W)assigned(^|\\W)";
export const DRIVERS_LICENSE_NUMBER_REGEX = /^[A-z0-9][0-9]{2}[A-z0-9]{6}[0-9]{1}[A-z0-9]{1}$/;


export const TICKET_NEW = new RegExp("(^|\\W)new($|\\W)");
export const TICKET_OPEN = new RegExp("(^|\\W)open($|\\W)");
export const TICKET_OBJECTED = new RegExp("(^|\\W)objected($|\\W)");
export const TICKET_FINISH = new RegExp(
  "(^|\\W)completed(^|\\W)|(^|\\W)manual(^|\\W)|(^|\\W)assigned(^|\\W)"
);

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DEFAULT_DATE = "0000-00-00 00:00:00";
export const DEFAULT_DATE_ZERO = "0000-00-00";
export const NO_DATE = "--.--.----";
export const IBAN_REGEX = /\b[a-zA-Z]{2}(\d){1,30}$/;
export const AMOUNT_RE = /(?=.*?\d)^\$?(([1-9]\d{0,2}(\.\d{3})*)|\d+)?(\,\d{1,2})?$/;
export const PHONE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const PASSWORD = /(?=^.{6,}$)/;

export const allowedTyreValues = [undefined, null, '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const APPROVED = "confirmed";
export const PATH = "path";
export const USERNAME_EXISTING = "COM_FLMS_USERNAME_ALREADY_EXISTING";
export const CUSTOMER_NUMBER = "COM_FLMS_CUSTOMER_NUMBER_NOT_CORRECT";
export const VEHICLES = "vehicles";
export const STATION = "station";
export const SERVICES = "service";
export const EMPTY = "";
export const TICKET_STATUS_NEW = "new";
export const AMOUNT = "amount";
export const DATE = "date";
export const LICENSE_PLATE = "license_plate";
export const REFERENCE = "reference";
export const IBAN = "iban";
export const EMAIL = "email_address";
export const PDF = "application/pdf";
export const PNG = "image/png";
export const JPEG = "image/jpeg";
export const JPG = "image/jpeg";
export const DRIVER = "driver";
export const MANAGER = "manager";

export const ACCUSE = "accuse";
export const OBJECT = "object";

export const FINISH_COMPLETED = "completed";
export const FINISH_MANUAL = "manual";
export const FINISH_ASSIGNED = "assigned";
export const OBJECTED = "objected";

export const BRANDS_TYPE = [
  "Dacia",
  "Ford",
  "Hyundai",
  "MAN",
  "Renault",
  "KIA",
  "OPEl",
  "Volvo",
  "Mazda",
  "SEAT",
  "Skoda",
  "Volkswagen",
  "Toyota",
  "Porsche",
  "Ford",
  "Mercedes-Benz",
];

export const VEHICLE_TYPES = {
  TRANSPORTER: "transporter",
  PASSENGER_CAR: "passenger_car",
};

export const DAMAGE_SIDES = {
  FRONT: "front",
  REAR: "rear",
  LEFT: "left",
  RIGHT: "right",
  INTERIOR: "interior",
};

export const CARD_TYPES = {
  RED_WITH_BACKGROUND: "red-with-background",
  RED_ARCHIVE: "red_archive",
  GREEN_WHITE: "green_white",
  YELLOW_WARN: "yellow_warn",
  GREEN_TICK: "green_tick",
  OBJECTED: "objected",
  GREEN_CHECK: "red-with-check",
  YELLOW: "yellow",
  GREEN: "green",
  WHITE: "white",
  EMPTY: "empty",
  BLUE: "blue",
  GRAY: "grey",
  RED: "red",
};

export const BUTTON_TYPES = {
  PRIMARY: "is-primary",
  LINK: "is-link",
  INFO: "is-info",
  SUCCESS: "is-success",
  WARNING: "is-warning",
  DANGER: "is-danger",
  WHITE: "is-white",
  OPEN: "is-danger delete-btn open",
  DISABLED: "is-disabled"
};

export const appointmentTypesMapping = {
  '1': 'SelectTireChange',
  '2': 'SelectInspection',
  '3': 'SelectBreaks',
  '4': 'SelectOther'
};
