import React from 'react';

/** load components */
import ProfileLink from './ProfileLink/ProfileLink.component';
import Grid from '@mui/material/Grid';

import './ProfileNavigation.styles.scss';

const ProfileNavigation = ({ routes, activeIndex }) => {
  return (
    <div className={'top-navigation'}>
      <Grid container spacing={2}>
        {routes.map((route, index) => {
          return <ProfileLink key={index} route={route} index={index} activeIndex={activeIndex} />;
        })}
      </Grid>
    </div>
  );
};

export default ProfileNavigation;
