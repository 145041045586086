import Flag from 'mdi-react/FlagVariantIcon';
import Info from 'mdi-react/InformationIcon';
import { withRouter } from 'react-router-dom';
import Alert from 'mdi-react/AlertIcon';
import Email from 'mdi-react/EmailIcon';
import Marker from 'mdi-react/MapMarkerIcon';
import { format } from 'date-fns';
import { t } from 'helpers/i18n';
import React from 'react';
import { DEFAULT_DATE, APPROVED } from '../../constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

export const CARD_TYPES = {
  RED_WITH_BACKGROUND: 'red-with-background',
  RED_ARCHIVE: 'red_archive',
  GREEN_WHITE: 'green_white',
  YELLOW_WARN: 'yellow_warn',
  GREEN_TICK: 'green_tick',
  OBJECTED: 'objected',
  GREEN_CHECK: 'red-with-check',
  YELLOW: 'yellow',
  GREEN: 'green',
  WHITE: 'white',
  EMPTY: 'empty',
  BLUE: 'blue',
  GRAY: 'grey',
  RED: 'red'
};

@withRouter
@connect((state) => ({
  state: state,
  auth: state.auth
}))
class Card extends React.Component {
  state = {
    cheeseIsReady: true
  };

  static propTypes = {
    type: PropTypes.oneOf(Object.values(CARD_TYPES)),
    secondaryText: PropTypes.string,
    renderBottom: PropTypes.node,
    locationMap: PropTypes.bool,
    renderRight: PropTypes.node,
    renderLeft: PropTypes.node,
    onClick: PropTypes.func,
    id: PropTypes.string
  };

  static defaultProps = {
    id: '',
    primaryText: '',
    secondaryText: '',
    locationMap: false,
    type: CARD_TYPES.GREEN,
    renderLeft: null,
    renderRight: null,
    renderBottom: null,
    onClick: null
  };

  renderBottom() {
    return <div className="bottom-section">{this.props.renderBottom}</div>;
  }

  renderTags(tags) {
    // const limit = 18;
    // const tagText = "";
    const longitude = tags.length;
    if (!longitude) {
      return '-';
    }
    return tags
      .map((tag) => {
        return tag.name;
      })
      .join(', ');
  }

  render() {
    const {
      id,
      // type,
      tags,
      trips,
      event,
      stateOpen,
      subText,
      onClick,
      // position,
      renderLeft,
      primaryText,
      renderRight,
      renderBottom,
      // marker = [],
      typeComponent,
      secondaryText,
      appointment,
      // currentMarkerId,
      kilometrageToday = false
    } = this.props;

    const hasBottom = renderBottom !== null;
    const date = format(new Date(), 'dd.MM.yyyy');
    const km = Math.round(kilometrageToday);

    // const { settings } = this.props.state;
    // const openCard = settings.marker;
    // const open =
    //   (currentMarkerId && (settings.selectId === id || openCard[0] === id)) ||
    //   openCard[0] === id;
    // const { currentCard } = this.props.state.appointments;

    const getDataYesterday = () => {
      const { per_date } = trips;

      if (per_date && per_date[1]?.date) {
        return format(new Date(per_date[1].date), 'dd.MM.yyyy');
      }

      const today = new Date();
      const yesterday = new Date(today);

      yesterday.setDate(yesterday.getDate() - 1);

      return format(yesterday, 'dd.MM.yyyy');
    };

    const getKmYesterday = () => {
      const { auth } = this.props.state;
      const language = auth?.language ? auth.language : 'en-GB';

      const { per_date } = trips;

      let kmYesterday = '';

      if (per_date && per_date[1]?.total) {
        kmYesterday = Math.round((parseFloat(per_date[1].total) * 10) / 10);
        const km =
          String(kmYesterday).length > 3
            ? `${kmYesterday.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1.')}`
            : `${kmYesterday}`;

        return language === 'de-DE' ? `${km.toLocaleString('de-DE')} km` : `${km.toLocaleString(language)} km`;
      }

      return '0 km';
    };

    const get3daysDataYesterday = () => {
      const today = new Date();

      const dayBeforeYesterday = new Date(today);

      dayBeforeYesterday.setDate(today.getDate() - 2);

      return `${format(dayBeforeYesterday, 'dd.MM')}-${format(today, 'dd.MM.yyyy')}`;
    };

    const get3daysKmYesterday = () => {
      const { auth } = this.props.state;
      const language = auth?.language ? auth.language : 'en-GB';
      let kmDays = '';
      if (trips && Object.prototype.hasOwnProperty.call(trips, 'per_date')) {
        const { per_date } = trips;

        kmDays = Math.round((parseFloat(per_date.reduce((current, next) => current + next.total, 0)) * 10) / 10);
        const km =
          String(kmDays).length > 3
            ? `${kmDays.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1.')}`
            : `${kmDays}`;
        if (language === 'de-DE') {
          return Number(km) === 0 ? '- km' : `${km.toLocaleString('de-DE')} km`;
        }
        return Number(km) === 0 ? '0 km' : `${km.toLocaleString(language)} km`;
      } else {
        return '0 km';
      }
    };

    const checkDueDays = () => {
      if (appointment) {
        if (!!appointment.date && appointment.date !== DEFAULT_DATE && appointment.status === APPROVED) {
          const date = new Date(appointment.date);
          const dateNow = new Date();
          const daysLag = Math.floor(Math.ceil(date.getTime() - dateNow.getTime()) / (1000 * 3600 * 24));

          if (daysLag <= 0) {
            return false;
          } else {
            return Math.round(daysLag);
          }
        } else {
          return false;
        }
      }
    };

    const getEmail = () => {
      return (
        !!appointment.garage.mail && (
          <a href={`mailto:${appointment.mail}`}>
            <span className="icons_marker">
              <Email />
            </span>
          </a>
        )
      );
    };

    const getMarker = () => {
      const { appointments } = this.props.state.appointments;
      let icon = '';
      appointments.map((el, key) => {
        const lat = appointment.garage.address.latitude;
        const long = appointment.garage.address.longitude;
        const elStreet = `${el.garage.address.street}${el.garage.address.number}`;
        const appointmentsStreet = `${appointment.garage.address.street}${appointment.garage.address.number}`;

        if (elStreet === appointmentsStreet && !!lat && !!long) {
          icon = (
            <span key={key} className="icons_marker">
              <Marker />
            </span>
          );
        }
      });
      return icon;
    };

    const getInformation = () => {
      if (Object.prototype.hasOwnProperty.call(appointment, 'garage')) {
        const {
          name,
          address: { street, number, zipcode, city }
        } = appointment.garage;
        return (
          <div className="appointment_row">
            <p>{name}</p>
            <p>
              {street && street} {number && number}
            </p>
            <p>
              {zipcode && zipcode} {city && city}
            </p>
          </div>
        );
      }
    };

    const getDriver = () => {
      const { driverName, driverSurname } = appointment;
      return (
        <div className="appointment_row">
          <div className="appointment_driver">
            Driver:
            <p>{driverName ? driverName : '-'}</p>
            <p>{driverSurname ? driverSurname : '-'}</p>
          </div>
        </div>
      );
    };

    const getAllInformation = () => {
      if (appointment) {
        return (
          <>
            <div className="appointment">
              {getInformation()}
              {getDriver()}
            </div>
            <div className="icons">
              <div className="icons_row">
                {!!appointment && getEmail()}
                {!!appointment && getMarker()}
              </div>
              {checkDueDays() && (
                <div className="appointment_due">
                  <Alert />
                  <span className="appointment_title">{`Due in ${checkDueDays()} days`}</span>
                </div>
              )}
            </div>
          </>
        );
      }
    };

    const parsingKm = (km) => {
      const param = Number(km);
      const { auth } = this.props.state;
      const language = auth?.language ? auth.language : 'en-GB';

      if (language === 'de-DE') {
        return param === 0 ? '- km' : `${param.toLocaleString('de-DE')} km`;
      }
      return param === 0 ? '- km' : `${param.toLocaleString(language)} km`;
    };

    return (
      <div
        data-id={id}
        // className={classnames(
        //   "card",
        //   `type-${type}`,
        //   position ? "enabled" : "disabled more",
        //   id === currentCard.id ? "app-show" : "app-hide"
        // )}
        onClick={typeComponent ? () => onClick && onClick(event) : onClick}>
        <div className={classnames('card-inner', { 'has-bottom': hasBottom })}>
          <div className="top-section">
            <div className="left-section">
              {renderLeft}

              <div className="text-container">
                <p className="primary-text">{primaryText}</p>
                {secondaryText && <p className="secondary-text">{secondaryText}</p>}
                <div className="tags">{tags && this.renderTags(tags)}</div>
                {subText && <p className="sub-text">{subText}</p>}
              </div>
            </div>

            <div className="right-section">{renderRight}</div>
          </div>
          {stateOpen && (
            <>
              {/* todo will be added in next iteration */}
              {/*  <p>Inspection Status: </p>*/}
              {/*  <div className={'row'}>*/}
              {/*    <p>Next inspection expected in: </p>*/}
              {/*    <p> </p>*/}
              {/*  </div>*/}
              <p className="trip">{t('trip_history')}</p>
              <div className="description">
                <div className="info-left">
                  <Flag />
                  <span className="today">
                    {t('today')} {date}
                  </span>
                </div>
                <div className="info-right">
                  <span className="km">{parsingKm(km)}</span>
                  <Info />
                </div>
              </div>

              <div className="description">
                <div className="info-left">
                  <Flag />
                  <span className="today">
                    {t('yesterday')} {trips ? getDataYesterday() : 'not date'}
                  </span>
                </div>
                <div className="info-right">
                  <span className="km">{getKmYesterday()}</span>
                  <Info />
                </div>
              </div>
              <div className="description">
                <div className="info-left">
                  <Flag />
                  <span className="today">
                    {t('last3days')} {get3daysDataYesterday()}
                  </span>
                </div>
                <div className="info-right">
                  <span className="km">{get3daysKmYesterday()}</span>
                  <Info />
                </div>
              </div>
            </>
          )}
          {getAllInformation()}
          {hasBottom && this.renderBottom()}
        </div>
      </div>
    );
  }
}

export { Card };
