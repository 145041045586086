import React, { useMemo, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Document, Page } from 'react-pdf';
import './TicketPdfPreview.style.scss';

function TicketPdfPreviewComponent({ pdfUrl }) {
  const pdfViewerRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const previousPageButton = useMemo(
    () => (
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}>
        <ArrowCircleLeftIcon fontSize={'large'} />
      </IconButton>
    ),
    [numPages, pageNumber]
  );

  const nextPageButton = useMemo(
    () => (
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        disabled={pageNumber >= numPages}
        onClick={() => setPageNumber(pageNumber + 1)}>
        <ArrowCircleRightIcon fontSize={'large'} />
      </IconButton>
    ),
    [numPages, pageNumber]
  );

  return (
    <div className={'pdf-container'}>
      {previousPageButton}
      <Document
        className={'pdf-preview'}
        file={{ url: pdfUrl }}
        inputRef={pdfViewerRef}
        onLoadError={(err) => console.log(err)}
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>

      {nextPageButton}
    </div>
  );
}

export default TicketPdfPreviewComponent;
