
const hasLocation = (locationObj) => {
  return !!(locationObj && locationObj.latitude && locationObj.longitude);
};

const getLocation = (locationObj) => {
  let object = false;
  if (!!locationObj) {
    switch (locationObj) {
      case 'lat':
        object = locationObj.latitude;
        break;
      case 'lng':
        object = locationObj.longitude;
        break;
      default:
        object = {lat: locationObj.latitude, lng: locationObj.longitude};
    }
    return object;
  }
};

export {
  hasLocation,
  getLocation
};
