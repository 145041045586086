import React, {useEffect, useRef, useState, memo} from "react";
import {Formik} from "formik";

/** Loading components **/
import BlockingOverlayComponent from "@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component";
import MultipleUploadComponent from "@components/Common/MultipleUpload";
import Slider from "@components/Common/Slider";
import {LicenseDropdown} from "@components/Common/LicensePlate";
import Alert from "@components/Common/Alert/Alert.component";

/** Loading MUI components **/
import {Grid} from "@mui/material";
import Close from "mdi-react/CloseIcon";

/** Loading helper functions and formatters**/
import {onImageRemove, onImageUpload, toBase64} from "@services/utils";
import {t} from "@helpers/i18n";
import {BUTTON_TYPES} from "@constants";

/** Loading styles **/
import './DateDetails.styles.scss';
import DateTimePickerGrid from "@components/Appointment/DateTimePickerGrid/DateTimePickerGrid.component";

/** Declaring a constant variable called EMPTY_LICENCE and assigning it the value of '__-__ ___' **/
const EMPTY_LICENCE = '__-__ ___';
const TWO_WEEKS = 14;
const TWO_WEEKS_PLUS_ONE_DAY = 15;
const TWO_WEEKS_PLUS_TWO_DAY = 16;

/**
 * Inits date time after certain number of days
 * @param {*} days
 * @returns
 */
const initDateTime = (days) => {
  const dayAfterFewDays = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  dayAfterFewDays.setHours(8, 0);
  return dayAfterFewDays;
};

/** Setting the initial state of the form. **/
const initialState = {
  licensePlate:'',
  vehicleRegistrationImages: [],
  appointmentDate: initDateTime(TWO_WEEKS),
  wishDate1: initDateTime(TWO_WEEKS),
  wishDate2: initDateTime(TWO_WEEKS_PLUS_ONE_DAY),
  wishDate3: initDateTime(TWO_WEEKS_PLUS_TWO_DAY),
};


const DateDetails = ({stepData, onChange, isDisabled, profile, error, isRequest}) => {

  /**
   * Local state for all changes in the step.
   * On mount should be set to global data from parent,
   * so the form can be prefilled with data when going in between steps
   */
  const imgSliderRef = useRef();
  const [slides, setSlides] = useState(new Array([]).fill(null));
  const [vehicles, setVehicles] = useState([]);
  const [data, setData] = useState(stepData || initialState);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);

  const dateTimeConfigs = [
    {
      isRequest: false,
      label: t("appointment_date"),
      key: "appointmentDate",
    },
    {
      isRequest: true,
      label: `1. ${t("preferred_date")}`,
      key: "wishDate1",
    },
    {
      isRequest: true,
      label: `2. ${t("preferred_date")}`,
      key: "wishDate2",
    },
    {
      isRequest: true,
      label: `3. ${t("preferred_date")}`,
      key: "wishDate3",
    },
  ];

  /**
   * Extract license plates from fetched vehicles
   * @returns {{label: *, id, value: *}[]}
   */
  const getLicencePlates = () => {
    const list = vehicles.map((vehicle, i) => ({licensePlate: vehicle.licencePlate, value: vehicle.licencePlate, id: i + 1}));
    list.unshift({licensePlate: EMPTY_LICENCE, value: EMPTY_LICENCE, id: 0});
    return list;
  };

  /**
   * Fetch vehicles for license plate picker
   */
  useEffect(() => {
    setVehicles(profile?.cars || []);
  }, []);

  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  /**
   * It returns an array of objects that contain the image data for the uploaded vehicle registration images
   * @returns An array of objects.
   */
  const renderUploadedVehicleRegistrationImages = () => {
    return stepData.vehicleRegistrationImages;
  };

  /**
   * "This function takes an object as an argument and returns a paragraph element with the license plate
   * property of the object as the text."
   * @param option - The option object that is being rendered.
   * @returns A function that returns a JSX element.
   */
  const itemListView = (option) => {
    return (<p>{ option.licensePlate }</p>);
  };

  /**
   * Initial slider call when user clicks on image
   * @param {number} index
   * @param imgSliderRef
   **/
  const onPreviewImage = (index, imgSliderRef) => {
    imgSliderRef.current.previewImage(index);
  };

  /**
   * It takes an index of an image in the array of uploaded images, converts it to base64,
   * and returns an object with the base64 string as the blob
   * @param index - The index of the image you want to load.
   * @returns an object with a data property that has a blob property.
   */
  const loadFullImageVehicleRegistration = async (index) => {
    const filteredImages = renderUploadedVehicleRegistrationImages();
    const image = await toBase64(filteredImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };

  /**
   * @param {string} propertyName
   * @param value
   * @param press
   */
  const onFieldChange = (propertyName, value, press = 0) => {
    if(propertyName === 'licensePlate') {
      if (value === EMPTY_LICENCE) {
        setData({...data, licensePlate: ''});
      }else if (press){
        setData({...data, licensePlate: value, vehicleRegistrationImages: []});
      }
      else {
        setData({... data, licensePlate: value});
      }
      return;
    }
    setData({... data, [propertyName]: value});
  };

  const checkImageUpload = () => {
    return !!data.vehicleRegistrationImages.length;
  };

  const alertClose = () => {
    setAlertVisible(false);
  };

  /**
   * Validates an image file for upload
   * @param {File} file - The file to validate
   * @returns {boolean} Whether the file is a valid image
   */
  const validateFile = file => {
    if (!file.type.startsWith('image/')) {
      setAlertVisible(true);
      return false;
    }
    return true;
  };

  /**
   * Handles changes in the uploaded images
   * @param {Event} e - The DOM event generated by the file input
   * @param {Object} values - Formik values
   */
  const onUploadImageChange = (e, values) => {
    const files = Array.from(event.target.files);

    if (files.every(validateFile)) {
      onImageUpload(e.target.files, values.vehicleRegistrationImages || [], onFieldChange, stepData.vehicleRegistrationImages, setSlides, 'vehicleRegistrationImages');
    }
  };

  return (
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent />}

      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={
          {
            licensePlate: data.licensePlate,
            vehicleRegistrationImages: data.vehicleRegistrationImages,
            appointmentDate: data.appointmentDate,
            wishDate1: data.wishDate1,
            wishDate2: data.wishDate2,
            wishDate3: data.wishDate3
          }
        }>
        {({values}) => {
          return (
            <div style={{marginTop:"15px"}}>
              <h3 className={'card-title'}>{t('date_details_title')}</h3>
              <Grid container spacing={1} rowSpacing={3} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12}  style={{marginBottom: '20px', marginTop:'20px'}}>
                  <p className="input-label">{`${t("license-plate")} *`}</p>
                  <LicenseDropdown
                    license={values.licensePlate}
                    options={getLicencePlates() || []} itemListView={itemListView}
                    licenseChange={value => onFieldChange('licensePlate', value, 0)}
                    onOptionPress={(value) => { onFieldChange('licensePlate', value.licensePlate, 1); }} />
                  {error?.licensePlate && <p className={'error-message'} style={{marginTop: '10px'}}>{t(error?.licensePlate)}</p>}
                </Grid>

                {!getLicencePlates().some(license => license.licensePlate === data.licensePlate) &&
                <Grid item xs={12}>
                  <p className="input-label" style={{textAlign: 'center', color: '#000'}}>{t("upload_vehicle_registration_photo")}</p>
                  {!!values?.vehicleRegistrationImages?.length &&
                  <>
                    <div className={'damage-image-list appointments-license-plate-img-wrapper'}>
                      {renderUploadedVehicleRegistrationImages().map((file, index) => (
                        <div className={'image-container'} key={index.toString()}>
                          <img alt={'damage-image'} onClick={() => onPreviewImage(index, imgSliderRef)}
                            src={window.URL.createObjectURL(file)}/>
                          <span className={'img-remove'} onClick={() => onImageRemove(index, onFieldChange, stepData.vehicleRegistrationImages, 'vehicleRegistrationImages',setSlides)}><Close/></span>
                        </div>
                      ))}
                    </div>
                  </>
                  }

                  {!data.vehicleRegistrationImages.length && <MultipleUploadComponent
                    name={'vehicleRegistrationImages'}
                    withoutPreview
                    value={values.vehicleRegistrationImages || []}
                    onChange={(e) => onUploadImageChange(e, values)}
                    onRemove={(index) => setRemovedFiles([...removedFiles, index])}
                    disabled={checkImageUpload()}
                    multiple={false}
                  />}
                </Grid>
                }

                {dateTimeConfigs.map((config, index) => {
                  if (config.isRequest === isRequest) {
                    return (
                      <DateTimePickerGrid
                        key={index}
                        label={`${config.label} *`}
                        value={data[config.key]}
                        onChange={newValue => setData({...data, [config.key]: newValue})}
                        error={error?.[config.key]}
                        language={profile?.language}
                      />
                    );
                  }
                  return null;
                })}

              </Grid>
            </div>
          );
        }}
      </Formik>
      {
        <Slider
          ref={imgSliderRef}
          slides={slides}
          setSlides={setSlides}
          imageAPI={loadFullImageVehicleRegistration}
        />
      }
      <Alert visible={alertVisible} title={t('image_upload_warning')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS}/>
    </div>
  );
};

export default memo(DateDetails);
