import React from 'react';
import classnames from 'classnames';

import './PageContent.styles.scss';

const PageContent = ({ children, className, noPaddings }) => {
  return (
    <div
      className={classnames('page-content', {
        'content-paddings': !noPaddings,
        [className]: !!className
      })}>
      {children}
    </div>
  );
};

export default PageContent;
