import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DriverSearch from '@components/Vehicle/General/DriverSelect/DriverSelect.component';
import AddDriver from '@components/Vehicle/General/AddDriver/AddDriver.component';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import { Api } from '@api';
import { t } from '@helpers/i18n';

const SelectDriverCmp = ({ onDriverSelected, showAddDriver = true }) => {
  const { auth } = useSelector((state) => state.auth); // todo add as prop

  const [addDriver, setAddDriver] = useState(null);
  const [licenseImage, setLicenseImage] = useState(null);
  const [addDriverModalVisibility, setAddDriverModalVisibility] = useState(false);
  const [showLicenseImg, setShowLicenseImg] = useState(false);

  const getDrivers = async ({ search, page }) => {
    // here we get dropdown list elements & can use it as state in parent
    const { data } = await Api.vehicles.loadDrivers(auth.user.id, `search=${search}&page=${page}`);
    // load drivers that only have name & surname
    const filteredDrivers = data.filter((driver) => driver.name && driver.surname);
    return filteredDrivers;
  };

  const onAddDriver = () => {
    setAddDriverModalVisibility(true);
  };

  const driverSelected = async (item) => {
    const { userId, licenceIdentifier } = item;
    if (licenceIdentifier) {
      setShowLicenseImg(true);
      setLicenseImage(null);
      const { data } = await Api.vehicles.loadDriverRegistration(userId);
      const licenseImg = data.driverLicence.frontImage.blob;
      setLicenseImage(licenseImg);
    }
    onDriverSelected(item);
  };

  const closePopup = () => {
    setShowLicenseImg(false);
  };

  const onCreateNewDriver = async (userId) => {
    const { data } = await Api.vehicles.loadDriverRegistration(userId);
    // const licenseImg = data.driverLicence.frontImage.blob;
    onDriverSelected({ name: data.name, surname: data.surname, userId: data.id });
    setShowLicenseImg(false);
    setAddDriver({ name: data.name, surname: data.surname });
  };

  return (
    <div className={'driver-select-cmp'}>
      <DriverSearch
        getDrivers={getDrivers}
        driverSelected={driverSelected}
        closePopup={closePopup}
        licenseImage={licenseImage}
        showLicenseImg={showLicenseImg}
        addDriver={addDriver}
      />
      {showAddDriver && (
        <div className={'add-driver-clickable'} onClick={onAddDriver}>
          <span>{t('add_new_driver')}</span>
          <PlusCircleIcon color={'#91c11e'} />
        </div>
      )}
      <AddDriver
        visible={addDriverModalVisibility}
        onClose={() => setAddDriverModalVisibility(false)}
        addDriverLicense={onCreateNewDriver}
      />
    </div>
  );
};

export default SelectDriverCmp;
