export const VEHICLE_GET_REQUEST = "VEHICLE_GET_REQUEST";
export const VEHICLE_GET_SUCCESS = "VEHICLE_GET_SUCCESS";
export const VEHICLE_GET_FAIL = "VEHICLE_GET_FAIL";

export const VEHICLE_GENERAL_INFORMATION_REQUEST = "VEHICLE_GENERAL_INFORMATION_REQUEST";
export const VEHICLE_GENERAL_INFORMATION_SUCCESS = "VEHICLE_GENERAL_INFORMATION_SUCCESS";
export const VEHICLE_GENERAL_INFORMATION_FAIL = "VEHICLE_GENERAL_INFORMATION_FAIL";

export const VEHICLE_GET_CAR_CHECKS_BY_ID_REQUEST = "VEHICLE_GET_CAR_CHECKS_BY_ID_REQUEST";
export const VEHICLE_GET_CAR_CHECKS_BY_ID_SUCCESS = "VEHICLE_GET_CAR_CHECKS_BY_ID_SUCCESS";
export const VEHICLE_GET_CAR_CHECKS_BY_ID_FAIL = "VEHICLE_GET_CAR_CHECKS_BY_ID_FAIL";

export const VEHICLE_GET_DAMAGES_BY_ID_REQUEST = "VEHICLE_GET_DAMAGES_BY_ID_REQUEST";
export const VEHICLE_GET_DAMAGES_BY_ID_SUCCESS = "VEHICLE_GET_DAMAGES_BY_ID_SUCCESS";
export const VEHICLE_GET_DAMAGES_BY_ID_FAIL = "VEHICLE_GET_DAMAGES_BY_ID_FAIL";

// --> Vehicles/Tabs
export const GET_VEHICLE_EXTENDED_INFORMATION_REQUEST = "GET_VEHICLE_EXTENDED_INFORMATION_REQUEST";
export const GET_VEHICLE_EXTENDED_INFORMATION_SUCCESS = "GET_VEHICLE_EXTENDED_INFORMATION_SUCCESS";
export const GET_VEHICLE_EXTENDED_INFORMATION_FAIL = "GET_VEHICLE_EXTENDED_INFORMATION_FAIL";

//components/VehiclesInfo
export const GET_TRIPS_REQUEST = "GET_TRIPS_REQUEST";
export const GET_TRIPS_SUCCESS = "GET_TRIPS_SUCCESS";
export const GET_TRIPS_FAIL = "GET_TRIPS_FAIL";
export const SELECT_VEHICLES = "SELECT_VEHICLES";

export const GET_TRIPS_POSITIONS_REQUEST = "GET_TRIPS_POSITIONS_REQUEST";
export const GET_TRIPS_POSITIONS_SUCCESS = "GET_TRIPS_POSITIONS_SUCCESS";
export const GET_TRIPS_POSITIONS_FAIL = "GET_TRIPS_POSITIONS_FAIL";

/** Load Single Vehicle **/
export const GET_SINGLE_VEHICLE_REQUEST = 'GET_SINGLE_VEHICLE_REQUEST';
export const GET_SINGLE_VEHICLE_SUCCESS = 'GET_SINGLE_VEHICLE_SUCCESS';
export const GET_SINGLE_VEHICLE__FAIL = 'GET_SINGLE_VEHICLE_FAIL';

/** * Download Vehicle Registration **/
export const DOWNLOAD_VEHICLE_REGISTRATION = 'DOWNLOAD_VEHICLE_REGISTRATION';
export const DOWNLOAD_VEHICLE_REGISTRATION_SUCCESS = 'DOWNLOAD_VEHICLE_REGISTRATION_SUCCESS';
export const DOWNLOAD_VEHICLE_REGISTRATION__FAIL = 'DOWNLOAD_VEHICLE_REGISTRATION_FAIL';

/** * Load Vehicle registration info **/
export const GET_SINGLE_VEHICLE_REGISTRATION = 'GET_SINGLE_VEHICLE_REGISTRATION';
export const GET_SINGLE_VEHICLE_REGISTRATION_SUCCESS = 'GET_SINGLE_VEHICLE_REGISTRATION_SUCCESS';
export const GET_SINGLE_VEHICLE_REGISTRATION__FAIL = 'GET_SINGLE_VEHICLE_REGISTRATION__FAIL';

/** Load more Vehicles action, used for loading more vehicles **/
export const VEHICLE_LOAD_MORE_REQUEST = 'VEHICLE_LOAD_MORE_REQUEST';
export const VEHICLE_LOAD_MORE_GET_SUCCESS = 'VEHICLE_LOAD_MORE_GET_SUCCESS';
export const VEHICLE_LOAD_MORE_FAIL = 'VEHICLE_LOAD_MORE_FAIL';

/** Load More for Damages per Vehicle */
export const VEHICLE_DAMAGES_LOAD_MORE = 'VEHICLE_DAMAGES_LOAD_MORE';
export const VEHICLE_DAMAGES_LOAD_MORE_SUCCESS = 'VEHICLE_DAMAGES_LOAD_MORE_SUCCESS';
export const VEHICLE_DAMAGES_LOAD_MORE_FAIL = 'VEHICLE_DAMAGES_LOAD_MORE_FAIL';

/** Load more Vehicles action, used for loading more car checks per vehicle **/
export const VEHICLE_CAR_CHECK_LOAD_MORE = 'VEHICLE_CAR_CHECK_LOAD_MORE';
export const VEHICLE_CAR_CHECK_LOAD_MORE_SUCCESS = 'VEHICLE_CAR_CHECK_LOAD_MORE_SUCCESS';
export const VEHICLE_CAR_CHECK_LOAD_MORE_FAIL = 'VEHICLE_CAR_CHECK_LOAD_MORE_FAIL';

/** Load Single Car Check per Vehicle */
export const LOAD_SINGLE_CAR_CHECK = 'LOAD_SINGLE_CAR_CHECK';
export const LOAD_SINGLE_CAR_CHECK_SUCCESS = 'LOAD_SINGLE_CAR_CHECK_SUCCESS';
export const LOAD_SINGLE_CAR_CHECK_FAIL = 'LOAD_SINGLE_CAR_CHECK_FAIL';

export const GET_VEHICLE_DETAILS_REQUEST = 'GET_VEHICLE_DETAILS_REQUEST';
export const GET_VEHICLE_DETAILS_SUCCESS = 'GET_VEHICLE_DETAILS_SUCCESS';
export const GET_VEHICLE_DETAILS_FAIL = 'GET_VEHICLE_DETAILS_FAIL';

export const GET_VEHICLE_LOCATION = 'GET_VEHICLE_LOCATION';
export const GET_VEHICLE_LOCATION_SUCCESS = 'GET_VEHICLE_LOCATION_SUCCESS';
export const GET_VEHICLE_LOCATION_FAIL = 'GET_VEHICLE_LOCATION_FAIL';

export const SAVE_VEHICLE_TAGS_REQUEST = 'SAVE_VEHICLE_TAGS_REQUEST';
export const SAVE_VEHICLE_TAGS_SUCCESS = 'SAVE_VEHICLE_TAGS_SUCCESS';
export const SAVE_VEHICLE_TAGS_FAIL = 'SAVE_VEHICLE_TAGS_FAIL';

export const UPDATE_VEHICLE_TAGS = 'UPDATE_VEHICLE_TAGS';

export const VEHICLE_ASSIGN_DRIVER = 'VEHICLE_ASSIGN_DRIVER';
export const VEHICLE_ASSIGN_DRIVER_SUCCESS = 'VEHICLE_ASSIGN_DRIVER_SUCCESS';
export const VEHICLE_ASSIGN_DRIVER_FAIL = 'VEHICLE_ASSIGN_DRIVER_FAIL';
