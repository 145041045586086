import React from 'react';

import './DeleteAccount.styles.scss';

const DeleteAccount = () => {

  return (
    <div className="delete_account_row">
      <p className={"delete_account_line"}>
        <span className="delete_account_circle"></span>
        <span className="delete_account_range"></span>
      </p>
      <div className="delete_account_box">
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
        <p className="delete_account_st"></p>
      </div>
    </div>
  );
};

export default DeleteAccount;
