import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,

  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
  AUTH_RESET_PASSWORD_CLEAR,

  AUTH_LOGOUT_SUCCESS,
  AUTH_CHALLENGE_RESET_PASSWORD_REQUEST,
  AUTH_CHALLENGE_RESET_PASSWORD_SUCCESS,
  AUTH_CHALLENGE_RESET_PASSWORD_FAIL,
  PROFILE_GET_SUCCESS, AUTH_INITIAL_LINK_SET,
} from "./constants";

const initialState = {
  auth: {},
  loggedIn: false,
  loading: false,
  resetPassword: null,
  resetPasswordError: '',
  language: '',
  initialRoute: ''
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
    case AUTH_RESET_PASSWORD_REQUEST:
    case AUTH_CHALLENGE_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        auth: action.payload,
        language: action.payload.info.language
      };

    case PROFILE_GET_SUCCESS:
      return {
        language: action.payload.language,
        ...state,
      };

    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        loggedIn: false,
        loading: false
      };

    case AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        loggedIn: false,
        loading: false
      };

    case AUTH_RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPassword: false,
        resetPasswordError: action.payload,
        loggedIn: false,
        loading: false
      };

    case AUTH_INITIAL_LINK_SET:
      return {
        ...state,
        initialRoute: action.payload
      };

    case AUTH_RESET_PASSWORD_CLEAR:
      return {
        ...state,
        resetPassword: null,
        resetPasswordError: ''
      };

    case AUTH_LOGOUT_SUCCESS:
    case AUTH_CHALLENGE_RESET_PASSWORD_SUCCESS:
    case AUTH_CHALLENGE_RESET_PASSWORD_FAIL:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
