const getOverviewObject = (reportTableData) => {
  const parsedData = {};

  reportTableData.forEach(vehicleData => {
    parsedData[vehicleData.licencePlate] = vehicleData;
  });
  return parsedData;
};


export {getOverviewObject};
