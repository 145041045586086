import React from 'react';
import { DATE_FORMAT_DMY_DOT, dateFormatter } from '@services/formatters/util/DateTime.formatters';
import { extractTime, toHoursMinSec } from '@helpers/common/tracking';
import './TimeTrackingHeader.styles.scss';

const TimeTrackingHeader = ({ date = '', begin = '', end = '', seconds, inProgress }) => {
  const timeForTimeTrackingInProgress = extractTime(begin);
  const timeForTimeTrackingFinished = `${extractTime(begin)} - ${extractTime(end)}`;
  return (
    <div className={'tracking-details-header'}>
      <p>{dateFormatter(date, DATE_FORMAT_DMY_DOT)}</p>
      <p>
        {inProgress ? timeForTimeTrackingInProgress : timeForTimeTrackingFinished}
        <span className={'minutes'}>{toHoursMinSec(seconds)}</span>
      </p>
    </div>
  );
};

export default TimeTrackingHeader;
