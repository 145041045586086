import React from 'react';
import SelectSearch from './SelectSearchUpdate.component';
import Avatar from '@components/User/Avatar';

const DriverSelectSearch = ({ search, setSearch, itemSelected, getDrivers, addDriver }) => {
  const renderDriver = (driver) => {
    if (driver) {
      return (
        <>
          <Avatar src={driver.avatar} micro />
          <p className={'driver-dropdown-item'}>
            {driver.name} {driver.surname}
          </p>
        </>
      );
    }
  };

  const setSearchTerm = (driver) => {
    const searchTerm = `${driver.name} ${driver.surname}`;

    return searchTerm;
  };

  const itemClicked = (driver, index) => {
    itemSelected(driver, index);
  };

  return (
    <div className="driver-select">
      <SelectSearch
        search={search}
        setSearch={setSearch}
        apiCall={getDrivers}
        renderListItem={renderDriver}
        itemClicked={itemClicked}
        setSearchTerm={setSearchTerm}
        addDriver={addDriver}
      />
    </div>
  );
};

export default DriverSelectSearch;
