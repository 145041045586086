import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { GoogleMap, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { TailSpin } from 'react-loader-spinner';

const MAX_WAYPOINTS_PER_REQUEST = 20;

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954
};

const defaultZoom = 11;

const libraries = ['geometry', 'drawing', 'places'];

const TripMap = forwardRef((props, ref) => {
  const { drawTrip } = props;
  const [directions, setDirections] = useState([]);
  const [mapLoaderVisible, setMapLoaderVisible] = useState(false);
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process?.env?.REACT_APP_GOOGLE_MAPS_KEY || '',
    libraries
  });

  useEffect(() => {
    if (isLoaded) {
      drawTrip();
    }
  }, [isLoaded, drawTrip]);

  const fitMapToBounds = (directionResults) => {
    if (!map || !directionResults || !directionResults.length) return;
    const bounds = new window.google.maps.LatLngBounds();

    directionResults.forEach((directionResult) => {
      directionResult.routes.forEach((route) => {
        if (route.bounds) {
          bounds.union(route.bounds);
        }
      });
    });

    map.fitBounds(bounds);
  };

  // Once map and directions are ready, fit the map to the route
  useEffect(() => {
    if (map && directions && directions.length) {
      fitMapToBounds(directions);
    }
  }, [map, directions]);

  const getNextDirections = (waypoints, startingIndex) => {
    const returnPoints = []; // array of points to return

    if (startingIndex > waypoints.length - 1) {
      return [returnPoints, null];
    } // no more waypoints to process

    let endIndex = startingIndex + MAX_WAYPOINTS_PER_REQUEST;

    // adjust waypoints, because Google allows us to include the start and destination latlongs for free!
    endIndex += 2;

    if (endIndex > waypoints.length - 1) {
      endIndex = waypoints.length;
    }

    for (let i = startingIndex; i < endIndex; i++) {
      returnPoints.push(waypoints[i]);
    }

    if (endIndex !== waypoints.length) {
      return [returnPoints, endIndex - 1];
    } else {
      return [returnPoints, null];
    }
  };

  const loadDirections = (waypoints, callbackFunc, waypointIndex = 0, path = []) => {
    setMapLoaderVisible(true);

    // Destructure the array returned by getNextDirections
    const [points, nextWaypointIndex] = getNextDirections(waypoints, waypointIndex);

    if (!points.length) {
      callbackFunc([]);
      return;
    }

    const startl = points.shift().location;
    const endl = points.pop().location;
    const request = {
      origin: startl,
      destination: endl,
      waypoints: points,
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
      provideRouteAlternatives: false
    };

    const service = new window.google.maps.DirectionsService();
    service.route(request, (response, status) => {
      if (status === 'OK') {
        path = path.concat(response);

        if (nextWaypointIndex != null) {
          setTimeout(() => {
            loadDirections(waypoints, callbackFunc, nextWaypointIndex, path);
          }, 700);
        } else {
          // All segments fetched
          callbackFunc(path);
          setMapLoaderVisible(false);
          // Do NOT call fitMapToBounds here, we'll rely on useEffect to do that after map is ready
        }
      } else if (status === 'OVER_QUERY_LIMIT') {
        setTimeout(() => {
          loadDirections(waypoints, callbackFunc, nextWaypointIndex, path);
        }, 800);
      } else {
        console.error(`Directions request failed due to ${status}`);
      }
    });
  };

  /**
   * Draw route directions on Map
   * @param {array} waypoints - array of all formatted waypoints
   */
  const drawDirections = (waypoints) => {
    if (waypoints && waypoints.length) {
      const mappedDirections = waypoints.map((p) => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: true
      }));

      loadDirections(mappedDirections, (path) => {
        setDirections(path);
      });
    } else {
      setDirections([]);
    }
  };

  useImperativeHandle(ref, () => ({
    // called from parent, used to draw directions for whole trip as well as single track
    drawDirections: (value) => drawDirections(value)
  }));

  const mapLoader = () => (
    <div className="map-loader">
      <div className="list-loader">
        <TailSpin color="#FFF" height={50} width={50} />
      </div>
    </div>
  );

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      onLoad={(mapInstance) => setMap(mapInstance)}
      mapContainerClassName="h-100 relative"
      zoom={defaultZoom}
      center={defaultCenter}
      options={{ disableDefaultUI: true }}>
      {mapLoaderVisible && mapLoader()}
      {directions.map((direction, index) => (
        <DirectionsRenderer
          key={index}
          options={{
            suppressMarkers: true,
            directions: direction,
            preserveViewport: true
          }}
        />
      ))}
    </GoogleMap>
  );
});

TripMap.displayName = 'TripMap';

export default TripMap;
