import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import Timer from "mdi-react/TimerSandIcon";
import React from "react";

/**
 * load state icon for license
 **/
const getIcon = (userLicenseState) => {
  if(!userLicenseState) {
    return null;
  }
  const stateLower = userLicenseState.toLowerCase();
  return stateLower==='overdue' || stateLower==='open' || stateLower==='rejected' ? <CloseCircleIcon size={18}/> :
        stateLower==='approved' ? <CheckCircleIcon size={18}/> :
            stateLower==='performed' ? <Timer className={'timer'} /> : null;
};

export {getIcon};
