import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Api } from '@api';

import CheckIcon from 'mdi-react/CheckIcon';
import SelectSearch from '@components/Common/SearchSelect/SelectSearchWrapper.component';

import { t } from '@helpers/i18n';

import './TagsSelect.styles.scss';

const TagsSelect = ({ onAddTag }) => {
  /** holds select tag from dropdown */
  const [selectedTag, setSelectedTag] = useState(null);

  const [hasSearchTerm, setHasSearchTerm] = useState(false);

  const { auth } = useSelector((state) => state.auth);

  const tagRef = useRef(null);

  const getTags = async ({ search }) => {
    const { data } = await Api.vehicles.getTagsPerUser(auth.user.id);
    // detect if we should show checkmark icon in input field
    setHasSearchTerm(!!search);
    setSelectedTag(search);
    // filter tags based on search
    const filterTags = data.filter((tag) => tag.name.includes(search));
    // here we get dropdown list elements & can use it as state in parent
    return filterTags;
  };

  const renderTag = (tag) => {
    return <p>{tag.name}</p>;
  };

  const setSearchTerm = (tag) => {
    const searchTerm = `${tag.name}`;
    return searchTerm;
  };

  const itemClicked = (item) => {
    setSelectedTag(item);
    setHasSearchTerm(true);
  };

  const addDriverTag = () => {
    onAddTag(selectedTag);
    setHasSearchTerm(false);
    tagRef.current.setSearchParam('');
    tagRef.current.setDropDown(false);
  };

  const inputSpecificProps = { placeholder: t('tag-placeholder').slice(0, 48).concat('...') };

  return (
    <div className="tags-select">
      <SelectSearch
        apiCall={getTags}
        ref={tagRef}
        inputProps={inputSpecificProps}
        renderListItem={renderTag}
        itemClicked={itemClicked}
        setSearchTerm={setSearchTerm}>
        {hasSearchTerm && (
          <div className={`checkmark`} onClick={addDriverTag}>
            <CheckIcon />
          </div>
        )}
      </SelectSearch>
    </div>
  );
};

export default TagsSelect;
