import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import {useSelector} from "react-redux";

const PublicRoute = (props) => {
  const {component: Component, isLogged, startingRoute = '/home', ...rest} = props;
  // get logged user from store
  const {auth: { user: loggedUser } } = useSelector(state => state.auth);

  /**
   * If the startingRoute is '/' or is not defined,
   * return '/home', otherwise return the startingRoute
   * @returns The startingRoute is being returned.
   */
  const getRedirectRoute = () => {
    return startingRoute === '/' || !startingRoute ? '/home' : startingRoute;
  };

  return (
  // restricted = false meaning public route
  // restricted = true meaning restricted route
    <Route {...rest} render={props => (
        loggedUser ?
              <Redirect to={getRedirectRoute()} />
              : <Component {...props} />
    )} />
  );
};


export default PublicRoute;
