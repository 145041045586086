export const TICKETS_GET_REQUEST = "TICKET_GET_REQUEST";
export const TICKETS_GET_SUCCESS = "TICKET_GET_SUCCESS";
export const TICKETS_GET_FAIL = "TICKET_GET_FAIL";

export const TICKETS_LOAD_MORE = 'TICKETS_LOAD_MORE';
export const TICKETS_LOAD_MORE_SUCCESS = 'TICKETS_LOAD_MORE_SUCCESS';
export const TICKETS_LOAD_MORE_FAIL = 'TICKETS_LOAD_MORE_FAIL';

export const VISION_TICKET_REQUEST = "VISION_TICKET_REQUEST";
export const VISION_TICKET_SUCCESS = "VISION_TICKET_SUCCESS";
export const VISION_TICKET_FAIL = "VISION_TICKET_FAIL";
export const VISION_STORE_1_CLEAR = "VISION_STORE_1_CLEAR";
export const VISION_STORE_2_CLEAR = "VISION_STORE_2_CLEAR";
export const VISION_STORE_3_CLEAR = "VISION_STORE_3_CLEAR";

export const GET_PAYLINK_REQUEST = "GET_PAYLINK_REQUEST";
export const GET_PAYLINK_SUCCESS = "GET_PAYLINK_SUCCESS";
export const GET_PAYLINK_FAIL = "GET_PAYLINK_FAIL";


export const TICKET_SINGLE_GET_REQUEST = "TICKET_SINGLE_GET_REQUEST";
export const TICKET_SINGLE_GET_SUCCESS = "TICKET_SINGLE_GET_SUCCESS";
export const TICKET_SINGLE_GET_FAIL = "TICKET_SINGLE_GET_FAIL";

export const TICKET_READ_REQUEST = "TICKET_READ_REQUEST";
export const TICKET_READ_SUCCESS = "TICKET_READ_SUCCESS";
export const TICKET_READ_FAIL = "TICKET_READ_FAIL";

export const TICKET_DELETE_REQUEST = "TICKET_DELETE_REQUEST";
export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAIL = "TICKET_DELETE_FAIL";

export const TICKET_RESET_REQUEST = "TICKET_RESET_REQUEST";
export const TICKET_RESET_SUCCESS = "TICKET_RESET_SUCCESS";
export const TICKET_RESET_FAIL = "TICKET_RESET_FAIL";

export const TICKET_MANUAL_REQUEST = "TICKET_MANUAL_REQUEST";
export const TICKET_MANUAL_SUCCESS = "TICKET_MANUAL_SUCCESS";
export const TICKET_MANUAL_FAIL = "TICKET_MANUAL_FAIL";

export const TICKET_COMPLETE_REQUEST = "TICKET_COMPLETE_REQUEST";
export const TICKET_COMPLETE_SUCCESS = "TICKET_COMPLETE_SUCCESS";
export const TICKET_COMPLETE_FAIL = "TICKET_COMPLETE_FAIL";

export const TICKET_OBJECT_REQUEST = "TICKET_OBJECT_REQUEST";
export const TICKET_OBJECT_SUCCESS = "TICKET_OBJECT_SUCCESS";
export const TICKET_OBJECT_FAIL = "TICKET_OBJECT_FAIL";

export const TICKET_ASSIGN_REQUEST = "TICKET_ASSIGN_REQUEST";
export const TICKET_ASSIGN_SUCCESS = "TICKET_ASSIGN_SUCCESS";
export const TICKET_ASSIGN_FAIL = "TICKET_ASSIGN_FAIL";

export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";
export const CREATE_TICKET_CLEAR = "CREATE_TICKET_CLEAR";
