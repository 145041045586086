import React from 'react';

/** Load components **/
import Loader from '../../Common/Loader';
import ExpandableCard from '../../Card/ExpandableCard';
import CarCheckTabs from './DetailedView/CarCheckTabs';
import CarCheckBaseDetails from './BaseView/CarCheckBaseDetails';
import CarCheckRightInfoBlock from './BaseView/RightInfoBlock';
import CarCheckLeftInfoBlock from './BaseView/LeftInfoBlock';
import CarCheckPrimaryContent from './BaseView/PrimaryContent';

/** Store methods **/
import { useDispatch, useSelector } from 'react-redux';
import * as carCheckActions from '@store/carcheck/actions';

/** Load component styles **/
import './index.scss';

const CarCheckCard = ({ carCheck = {}, initiallyExpended = false }) => {
  /** store selectors **/
  const { detailedCarChecks, singleLoading } = useSelector((state) => state.carchecks);
  /** initiate dispatch action **/
  const dispatch = useDispatch();

  /** Destructure Car Check Object **/
  const {
    isMaster, // detect if carCheck is master one possible values "0"/"1"
    state,
    carAvatar, // avatar of vehicle
    isStatusOkay, // detect if master CarCheck is ok possible values true/false
    vehicleId, // vehicle licence plate
    creationDate, // carCheck creation date,
    damageCount, // detect how many damages are on carCheck
    isTyreOkay, // detect if all tires are ok on carCheck possible values true/false
    name, // name of user who reported carCheck
    surname, // surname of user who reported carCheck
    comment, // comment for carCheck possible values '' or 'rand comment'
    mileage, // carCheck mileage
    commentFleetlink, // fleetlink comment can be ''
    status,
    damages // damages array
  } = carCheck;

  /** Render Car Check Card Primary content
   * @returns {JSX.Element}
   **/
  const renderPrimaryContent = () => {
    return <CarCheckPrimaryContent name={name} surname={surname} />;
  };

  /** Render Car Check Card Left Block
   * @returns {JSX.Element}
   **/
  const renderLeftInfoBlock = () => {
    return <CarCheckLeftInfoBlock state={state} carAvatar={carAvatar} />;
  };

  /** Render Car Check Card Right Block
   * @returns {JSX.Element}
   **/
  const renderRightInfoBlock = () => {
    return (
      <CarCheckRightInfoBlock
        creationDate={creationDate}
        vehicleId={vehicleId}
        damageCount={damageCount}
        isStatusOkay={isStatusOkay}
        isTyreOk={isTyreOkay}
        isMaster={isMaster}
      />
    );
  };

  /** preform api call fetch car check details
   * @param {string} carCheckId
   **/
  const fetchCarCheckDetails = (carCheckId) => {
    const carCheckDetails = detailedCarChecks.find((check) => +check.id === +carCheckId);
    if (!carCheckDetails) {
      dispatch(
        carCheckActions.carCheckGetById({
          id: carCheckId
        })
      );
    }
  };

  /** Render Car Check Card Loader
   * @returns {JSX.Element}
   **/
  const renderDetailSkeletonLoader = () => {
    return (
      <div className={'flex items-center justify-center'}>
        <Loader />
      </div>
    );
  };

  /** Render Car Check Card Base Details
   * @returns {JSX.Element}
   **/
  const renderBasicCarCheckDetails = () => {
    return (
      <CarCheckBaseDetails
        damageCount={damages.length}
        name={name}
        surname={surname}
        status={status}
        comment={comment}
        commentFleetlink={commentFleetlink}
        isTyreOk={isTyreOkay}
        mileage={mileage}
      />
    );
  };

  /** Render Car Check Tab component
   * used for Car Check detailed View
   * @returns {JSX.Element}
   */
  const renderCarCheckDetailsTabs = (details) => {
    return <CarCheckTabs carCheck={details} />;
  };

  /** Render Car Check Detailed view
   * rendered when user click on Car Check Card
   * @returns {JSX.Element}
   **/
  const renderCarCheckDetails = () => {
    // initiallyExpended is used only for displaying 'single' car check card, and in that case
    // there is no need to find 'selected' car checks in 'detailedCarChecks'
    const detailedCarCheck = initiallyExpended
      ? carCheck
      : detailedCarChecks.find((check) => +check.id === +carCheck.id);
    // car check details are not loaded show loading animation
    if (singleLoading && !detailedCarCheck) {
      return renderDetailSkeletonLoader();
    } else {
      return (
        // car check details are available
        <div className={'full-width'}>
          {/* show car check details */}
          {renderBasicCarCheckDetails()}
          {/* show car check tab details */}
          {detailedCarCheck && renderCarCheckDetailsTabs(detailedCarCheck)}
        </div>
      );
    }
  };

  /** Render Car Check Car
   * @returns {JSX.Element}
   **/
  return (
    <article className={'car-check-card '} onClick={() => fetchCarCheckDetails(carCheck.id)}>
      <ExpandableCard
        id={carCheck.id}
        state={state}
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeftInfoBlock}
        renderRight={renderRightInfoBlock}
        initiallyExpended={initiallyExpended}>
        {/* car check details rendered when user clicks on car check card */}
        {renderCarCheckDetails()}
      </ExpandableCard>
    </article>
  );
};

export default CarCheckCard;
