import React from 'react';
import { toHoursMinSec } from '@helpers/common/tracking';
import FilterDatePicker from '@components/Common/FilterDatePicker';
import { t } from '@helpers/i18n';
import './EmployeeTimeTrackingFilter.styles.scss';

const EmployeeTimeTrackingFilter = ({ trackingDates = {}, onDateChange = () => {}, totalTime = 0 }) => {
  return (
    <div className={'employee-time-tracking-filter-container'}>
      <p className={'total-hours'}>
        {t('total_hours')} {toHoursMinSec(totalTime)}
      </p>
      <div className="date-picker">
        <p className={'between'}>{t('between')}</p>
        <FilterDatePicker dateFrom={trackingDates.dateFrom} dateTo={trackingDates.dateTo} onDateChange={onDateChange} />
      </div>
    </div>
  );
};

export default React.memo(EmployeeTimeTrackingFilter);
