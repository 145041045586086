import React from 'react';
/** load components **/
import AppointmentFilterForm from '@components/Appointment/Filter/FilterForm/AppointmentFilterForm.component';
import ApplyButton from '@components/Common/Button/ApplyButton';
/** load services **/
import { AppointmentsFilterFormatter } from '@services/formatters/appointments/AppointmentFilter.formatter';
import { t } from '@helpers/i18n';

const AppointmentFilter = ({
  filterValues = [],
  visible = false,
  filterChanged = () => {},
  applyFilter = () => {}
}) => {
  const filterChange = (e) => {
    const updatedFilters = AppointmentsFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible && (
      <div className={'list-filter'}>
        <AppointmentFilterForm filters={filterValues} onChange={filterChange} />
        <ApplyButton onClick={applyFilter} text={t('apply')}></ApplyButton>
      </div>
    )
  );
};

export default AppointmentFilter;
