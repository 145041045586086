import { withLegacyToken } from "@helpers/api";
import { format } from "date-fns";
import { requestMs } from '@helpers/api';

export const vehicles = {
  getVehiclesExtended: (query) => requestMs.get(`api3/user/${query}`),
  getVehicleTrips: (id) => {
    const date = format(new Date(), "yyyy-MM-dd");
    const dt = new Date().setDate(new Date().getDate() - 2);
    const dateNext = format(new Date(dt), "yyyy-MM-dd");
    return requestMs.get(`api3/car/${id}/telemetry/summary?dateFrom=${dateNext}&dateTo=${date}`);
  },
  getVehicleTripsPositions: (id) => {
    const date = format(new Date(), "yyyy-MM-dd");
    const dt = new Date().setDate(new Date().getDate() - 2);
    const dateNext = format(new Date(dt), "yyyy-MM-dd");
    return withLegacyToken((legacyToken) => {
      return requestMs.get(`api3/radar/subjects/${id}/positions?from=${dateNext}T00:00:00Z&to=${date}T23:59:59Z&resolution=5&limit=10000`,
        {
          headers: { Authorization: legacyToken },
        });
    });
  },
  getSingleVehicleLocation: (vehicleId) => requestMs.get(`api3/car/${vehicleId}/telemetry/status`),
  getVehicleGeneralInformation: (vehicleId) => requestMs.get(`api3/car/${vehicleId}/extended`),
  getCarchecksOfVehicle: ({ id, page }) => requestMs.get(`api3/car/${id}/carcheck?page=${page}`, {}),
  getVehicleExtendedInformation: (id) => requestMs.get(`api3/car/${id}/extended`),
  saveTags: (carId, tags) => requestMs.put(`/api3/car/${carId}/tag`, tags),
  getDamagesOfVehicle: ({ vehicleId, page }) => requestMs.get(`api3/car/${vehicleId}/damage?page=${page}`, {}),
  loadDrivers: (userId, queryParams) => requestMs.get(`api3/user/${userId}/drivers?${queryParams}`),
  loadDriverRegistration: (driverId) => requestMs.get(`api3/user/${driverId}/driver/extended`),
  assignDriver: ({ driverId, carId }) => requestMs.put(`api3/driver/${driverId}/car`, { carId }),
  getTagsPerUser: (userId) => requestMs.get(`api3/user/${userId}/tag`),
  createUserTag: (userId, tagData) => requestMs.post(`/api3/user/${userId}/tag`, tagData),
};
