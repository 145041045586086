import {
  SET_MAP_SERVICE_PARTNERS,
  CLEAR_STATUS_SIDEBAR,
  SET_SELECT_STATIONS,
  MAP_COORDINATES_SET,
  MAP_COORDINATES_CLEAR,
  HOVER_MAKER_STATION,
  HOVER_MAKER_SERVICE,
  SET_STATUS_CALENDAR,
  SET_STATUS_SIDEBAR,
  SELECT_SERVICE_MAP,
  SELECT_STATION_MAP,
  SET_MAP_SATELLITE,
  CLEAR_CURRENT_TAB,
  SET_ALL_STATIONS,
  SET_COUNT_FILTER,
  SET_CURRENT_TAB,
  SELECT_VEHICLES,
  SET_MAP_STATION,
  SELECT_EMPLOYEE,
  SET_TYPE_FUEL,
  CLEAR_SEARCH,
  CLEAR_MARKER,
  HOVER_MARKER,
  SET_MARKER,
  SELECT_ID,
} from "@constants";
import { damagesConstants } from "@constants";

const initialState = {
  hoverMarkerStation: false,
  hoverMarkerService: false,
  selectAppointment: false,
  statusSideBar: false,
  statusCalendar: true,
  selectStation: null,
  hoverMarker: false,
  selectStationMap: "",
  selectServiceMap: "",
  selectEmployee: "",
  selectVehicles: "",
  currentDamage: "",
  allStations: "",
  isVehicles: "",
  isEmployee: "",
  isStation: "",
  isService: "",
  typeFuel: "diesel",
  count: "",
  satellite: false,
  selectId: false,
  partner: true,
  station: true,
  marker: false,
  currentTab: 2,
  coordinates: {},
  assignDriver: false,
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case MAP_COORDINATES_SET:
      return {
        ...state,
        coordinates: action.payload,
      };

    case MAP_COORDINATES_CLEAR:
      return {
        ...state,
        coordinates: {},
      };

    case SET_MAP_SATELLITE:
      return {
        ...state,
        satellite: action.payload,
      };

    case SET_MAP_SERVICE_PARTNERS:
      return {
        ...state,
        partner: action.payload,
      };

    case SET_STATUS_CALENDAR:
      return {
        ...state,
        statusCalendar: !state.statusCalendar,
      };

    case SET_STATUS_SIDEBAR:
      return {
        ...state,
        statusSideBar: !state.statusSideBar,
      };

    case CLEAR_STATUS_SIDEBAR:
      return {
        ...state,
        statusSideBar: false,
      };

    case SET_MAP_STATION:
      return {
        ...state,
        station: action.payload,
      };

    case damagesConstants.SET_CURRENT_DAMAGE:
      return {
        ...state,
        currentDamage: action.payload,
      };

    case SET_ALL_STATIONS:
      return {
        ...state,
        allStations: action.payload,
      };

    case SET_SELECT_STATIONS:
      return {
        ...state,
        selectStation: action.payload,
      };

    case SET_TYPE_FUEL:
      return {
        ...state,
        typeFuel: action.payload,
      };

    case SELECT_ID:
      return {
        ...state,
        marker: false,
        selectId: action.payload,
      };

    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };

    case CLEAR_CURRENT_TAB:
      return {
        ...state,
        currentTab: 2,
      };

    case SET_COUNT_FILTER:
      return {
        ...state,
        count: action.payload,
      };

    case SET_MARKER:
      return {
        ...state,
        marker: action.payload,
        hoverMarker: action.payload,
        hoverMarkerStation: false,
        hoverMarkerService: false,
        selectId: false,
      };

    case HOVER_MARKER:
      return {
        ...state,
        hoverMarker: action.payload,
        hoverMarkerStation: false,
        hoverMarkerService: false,
      };

    case HOVER_MAKER_STATION:
      return {
        ...state,
        hoverMarkerStation: action.payload,
        hoverMarker: false,
        hoverMarkerService: false,
      };

    case HOVER_MAKER_SERVICE:
      return {
        ...state,
        hoverMarkerService: action.payload,
        hoverMarker: false,
        hoverMarkerStation: false,
      };

    case CLEAR_MARKER:
      return {
        ...state,
        marker: false,
      };

    case SELECT_VEHICLES:
      return {
        ...state,
        selectVehicles: action.payload,
        isVehicles: true,
        isEmployee: false,
        hoverMarker: false,
      };

    case SELECT_EMPLOYEE:
      return {
        ...state,
        selectEmployee: action.payload,
        isVehicles: false,
        selectStationMap: false,
        isEmployee: true,
        isStation: false,
        hoverMarker: false,
      };

    case SELECT_STATION_MAP:
      return {
        ...state,
        selectStationMap: action.payload,
        selectEmployee: false,
        selectServiceMap: false,
        selectVehicles: false,
        isService: false,
        isStation: true,
        isVehicles: false,
        isEmployee: false,
        hoverMarker: false,
      };

    case SELECT_SERVICE_MAP:
      return {
        ...state,
        selectServiceMap: action.payload,
        selectVehicles: false,
        selectStationMap: false,
        selectEmployee: false,
        isService: true,
        isStation: false,
        isVehicles: false,
        isEmployee: false,
        hoverMarker: false,
      };

    case CLEAR_SEARCH:
      return {
        ...state,
        selectEmployee: "",
        selectVehicles: "",
        selectStationMap: "",
        selectServiceMap: "",
        isVehicles: false,
        isEmployee: false,
        isStation: false,
        isService: false,
        hoverMarker: false,
        marker: false,
        selectId: false,
        hoverMarkerStation: false,
        hoverMarkerService: false,
      };

    default:
      return state;
  }
}
