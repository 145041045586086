export const EMPLOYEE_GET_REQUEST = "EMPLOYEE_GET_REQUEST";
export const EMPLOYEE_GET_SUCCESS = "EMPLOYEE_GET_SUCCESS";
export const EMPLOYEE_GET_FAIL = "EMPLOYEE_GET_FAIL";

export const EMPLOYEE_GET_BY_ID_REQUEST = "EMPLOYEE_GET_BY_ID_REQUEST";
export const EMPLOYEE_GET_BY_ID_SUCCESS = "EMPLOYEE_GET_BY_ID_SUCCESS";
export const EMPLOYEE_GET_BY_ID_FAIL = "EMPLOYEE_GET_BY_ID_FAIL";

export const EMPLOYEE_LOAD_MORE_REQUEST = "EMPLOYEE_LOAD_MORE_REQUEST";
export const EMPLOYEE_LOAD_MORE_SUCCESS = "EMPLOYEE_LOAD_MORE_SUCCESS";
export const EMPLOYEE_LOAD_MORE_FAIL = "EMPLOYEE_LOAD_MORE_FAIL";

export const EMPLOYEE_GET_CAR_CHECKS_REQUEST = 'EMPLOYEE_GET_CAR_CHECKS_REQUEST';
export const EMPLOYEE_GET_CAR_CHECKS_SUCCESS = 'EMPLOYEE_GET_CAR_CHECKS_SUCCESS';
export const EMPLOYEE_GET_CAR_CHECKS_FAIL = 'EMPLOYEE_GET_CAR_CHECKS_FAIL';

export const EMPLOYEE_LOAD_MORE_CAR_CHECKS_REQUEST = 'EMPLOYEE_LOAD_MORE_CAR_CHECKS_REQUEST';
export const EMPLOYEE_LOAD_MORE_CAR_CHECKS_SUCCESS = 'EMPLOYEE_LOAD_MORE_CAR_CHECKS_SUCCESS';
export const EMPLOYEE_LOAD_MORE_CAR_CHECKS_FAIL = 'EMPLOYEE_LOAD_MORE_CAR_CHECKS_FAIL';

export const EMPLOYEE_GET_DAMAGES_REQUEST = 'EMPLOYEE_GET_DAMAGES_REQUEST';
export const EMPLOYEE_GET_DAMAGES_SUCCESS = 'EMPLOYEE_GET_DAMAGES_SUCCESS';
export const EMPLOYEE_GET_DAMAGES_FAIL = 'EMPLOYEE_GET_DAMAGES_FAIL';

export const EMPLOYEE_LOAD_MORE_DAMAGES_REQUEST = 'EMPLOYEE_LOAD_MORE_DAMAGES_REQUEST';
export const EMPLOYEE_LOAD_MORE_DAMAGES_SUCCESS = 'EMPLOYEE_LOAD_MORE_DAMAGES_SUCCESS';
export const EMPLOYEE_LOAD_MORE_DAMAGES_FAIL = 'EMPLOYEE_LOAD_MORE_DAMAGES_FAIL';