import React, { useState } from 'react';

import LicenseInput from '../License/LicensePlate.component';
import LicenseListItem from './LicenseListItemView.component';
import ArrowRightIcon from 'mdi-react/ChevronDownIcon';

import './LicenseDropdown.styles.scss';

const LicenseDropdown = ({ license, licenseChange, itemListView, onOptionPress, options }) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisibility(!dropdownVisibility);
  };

  const onLicenseSelect = (option, index) => {
    setDropdownVisibility(false);
    onOptionPress(option, index);
  };

  return (
    <div className={`license-selector ${dropdownVisibility ? 'select-active' : 'select-deactive'}`}>
      <LicenseInput license={license} licenseChange={licenseChange} />
      <div className={`arrow ${dropdownVisibility ? 'rotate' : ''}`} onClick={toggleDropdown}>
        <ArrowRightIcon />
      </div>
      <div className={'select__options'}>
        {options.map((option, index) => {
          return (
            <div key={index} className="select__option" onClick={() => onLicenseSelect(option, index)}>
              {itemListView ? itemListView(option) : LicenseListItem(option)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LicenseDropdown;
