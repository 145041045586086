import React from 'react';

/** Load components **/
import Toggler from '@components/Common/Toggler';
import FilterDatePicker from '@components/Common/FilterDatePicker';

/** Load helper functions **/
import { t } from '@helpers/i18n';

const TicketFilterForm = ({ filters = [], onChange = () => {}, dateFrom, dateTo, onDateChange = () => {} }) => {
  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        {filters.map((filter) => (
          <label key={filter.id}>
            <p>{t(filter.title)}</p>
            <Toggler id={filter.id.toString()} checked={filter.value} onChange={() => onChange(filter.title)} />
          </label>
        ))}
        {filters[filters.length - 1].value && (
          <div className="date-picker">
            <FilterDatePicker dateFrom={dateFrom} dateTo={dateTo} onDateChange={onDateChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketFilterForm;
