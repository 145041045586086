export const START_TIME_TRACKING_REQUEST = 'START_TIME_TRACKING_REQUEST';
export const START_TIME_TRACKING_SUCCESS = 'START_TIME_TRACKING_SUCCESS';
export const START_TIME_TRACKING_FAIL = 'START_TIME_TRACKING_FAIL';

export const STOP_TIME_TRACKING_REQUEST = 'STOP_TIME_TRACKING_REQUEST';
export const STOP_TIME_TRACKING_SUCCESS = 'STOP_TIME_TRACKING_SUCCESS';
export const STOP_TIME_TRACKING_FAIL = 'STOP_TIME_TRACKING_FAIL';

export const REFRESH_TRACKING_STATUS_REQUEST = 'REFRESH_TRACKING_STATUS_REQUEST';
export const REFRESH_TRACKING_STATUS_SUCCESS = 'REFRESH_TRACKING_STATUS_SUCCESS';
export const REFRESH_TRACKING_STATUS_FAIL = 'REFRESH_TRACKING_STATUS_FAIL';

export const TIME_TRACKING_GET_REQUEST = 'TIME_TRACKING_GET_REQUEST';
export const TIME_TRACKING_GET_SUCCESS = 'TIME_TRACKING_GET_SUCCESS';
export const TIME_TRACKING_GET_FAIL = 'TIME_TRACKING_GET_FAIL';

export const TIME_TRACKING_USER_GET_REQUEST = 'TIME_TRACKING_USER_GET_REQUEST';
export const TIME_TRACKING_USER_GET_SUCCESS = 'TIME_TRACKING_USER_GET_SUCCESS';
export const TIME_TRACKING_USER_GET_FAIL = 'TIME_TRACKING_USER_GET_FAIL';

export const TIME_TRACKING_USER_LOAD_MORE_REQUEST = 'TIME_TRACKING_USER_LOAD_MORE_REQUEST';
export const TIME_TRACKING_USER_LOAD_MORE_SUCCESS = 'TIME_TRACKING_USER_LOAD_MORE_SUCCESS';
export const TIME_TRACKING_USER_LOAD_MORE_FAIL = 'TIME_TRACKING_USER_LOAD_MORE_FAIL';

export const TIME_TRACKING_LOAD_MORE_REQUEST = 'TIME_TRACKING_LOAD_MORE_REQUEST';
export const TIME_TRACKING_LOAD_MORE_SUCCESS = 'TIME_TRACKING_LOAD_MORE_SUCCESS';
export const TIME_TRACKING_LOAD_MORE_FAIL = 'TIME_TRACKING_LOAD_MORE_FAIL';

export const TIME_TRACKING_DELETE_REQUEST = 'TIME_TRACKING_DELETE_REQUEST';
export const TIME_TRACKING_DELETE_SUCCESS = 'TIME_TRACKING_DELETE_SUCCESS';
export const TIME_TRACKING_DELETE_FAIL = 'TIME_TRACKING_DELETE_FAIL';

export const TIME_TRACKING_GET_DETAILS_REQUEST = 'TIME_TRACKING_GET_DETAILS_REQUEST';
export const TIME_TRACKING_GET_DETAILS_SUCCESS = 'TIME_TRACKING_GET_DETAILS_SUCCESS';
export const TIME_TRACKING_GET_DETAILS_FAIL = 'TIME_TRACKING_GET_DETAILS_FAIL';
