import React from 'react';
import './LabelWithValue.styles.scss';

const LabelWithValue = ({ label = '', value = '', vertical = false, customClass = '' }) => {
  return (
    <div className={`${vertical ? 'flexColumn' : 'flexRow'} ${customClass}`}>
      <p className={'label'}>{label}:</p>
      <div className={'text'}>{value}</div>
    </div>
  );
};

export default LabelWithValue;
