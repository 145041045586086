import React from 'react';
import DamageAdditionListItem from './AdditionalDamageListItem/AdditionalDamageListItem.component';
import {t} from "@helpers/i18n";
import './DamageAdditionalInformation.styles.scss';

const DamageAdditionalInformation = ({ info }) => {

  return (
    <div className={'damage-additional-information'}>
      <h4 className={'title'}>{ t('damage_additional_info')}</h4>
      { info?.map((item, index) => {
        return (
          <DamageAdditionListItem key={index} item={item} />
        );
      })}
    </div>
  );
};

export default DamageAdditionalInformation;
