import React, { useMemo } from 'react';
import LazyLoadingList from '@components/LazyLoadingList';
import TimeTrackingCard from '@components/TimeTracking/Card/TimeTrackingCard.component';

const TimeTrackingCardList = ({
  loading = false,
  trackings = [],
  isLastPage = false,
  isEndReached = () => {},
  onTrackingClick = () => {}
}) => {
  const renderTimeTrackingCard = (tracking, i) => (
    <TimeTrackingCard tracking={tracking} key={i} onTrackingClick={onTrackingClick} />
  );

  const renderContent = useMemo(
    () => (
      <LazyLoadingList
        data={trackings || []}
        loading={loading}
        isLastPage={isLastPage}
        onEndReached={isEndReached}
        renderItem={renderTimeTrackingCard}
      />
    ),
    [loading, trackings]
  );

  return <>{renderContent}</>;
};

export default React.memo(TimeTrackingCardList);
