export const APPOINTMENT_GET_ALL_REQUEST = "APPOINTMENT_GET_ALL_REQUEST";
export const APPOINTMENT_GET_ALL_SUCCESS = "APPOINTMENT_GET_ALL_SUCCESS";
export const APPOINTMENT_GET_ALL_FAIL = "APPOINTMENT_GET_ALL_FAIL";

export const APPOINTMENTS_LOAD_MORE_REQUEST = "APPOINTMENTS_LOAD_MORE_REQUEST";
export const APPOINTMENTS_LOAD_MORE_SUCCESS = "APPOINTMENTS_LOAD_MORE_SUCCESS";
export const APPOINTMENTS_LOAD_MORE_FAIL = "APPOINTMENTS_LOAD_MORE_FAIL";

export const SET_MAP_APPOINTMENT = "SET_MAP_APPOINTMENT";
export const UPDATE_MAP_APPOINTMENT = "UPDATE_MAP_APPOINTMENT";

export const APPOINTMENT_CANCEL_REQUEST = "APPOINTMENT_CANCEL_REQUEST";
export const APPOINTMENT_CANCEL_SUCCESS =  "APPOINTMENT_CANCEL_SUCCESS";
export const  APPOINTMENT_CANCEL_FAIL = "APPOINTMENT_CANCEL_FAIL";

export const APPOINTMENT_CREATE_REQUEST = "APPOINTMENT_CREATE_REQUEST";
export const APPOINTMENT_CREATE_SUCCESS = "APPOINTMENT_CREATE_SUCCESS";
export const APPOINTMENT_CREATE_FAIL = "APPOINTMENT_CREATE_FAIL";

export const APPOINTMENT_CALENDAR_REQUEST = "APPOINTMENT_CALENDAR_GET";
export const APPOINTMENT_CALENDAR_SUCCESS = "APPOINTMENT_CALENDAR_SUCCESS";
export const APPOINTMENT_CALENDAR_FAIL = "APPOINTMENT_CALENDAR_FAIL";
