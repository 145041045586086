import "react-toggle/style.css";
import React from "react";
import "./index.scss";

const Station = ({ station, typeFuel, onStationClick }) => {

  const getPrice = (price) => {
    return !!price ? price.toString().slice(0, -1) : "-,--";
  };

  const getSubPrice = (price) => {
    return !!price ? price.toString().slice(-1) : "-";
  };

  const trimText = (text, textLength = 24) => {
    return text.length >= textLength ? `${text.substring(0, 24)} ...` : text;
  };

  const renderStreet = () => {
    // eslint-disable-next-line prefer-destructuring
    let text = station.address.split(", ")[0];

    if (text.length >= 18) {
      text = text.substring(0, 18);
    }

    // Change this (this is stupid way!!!)
    return (
      text && (
        <>
          <span>{text[0]}</span>
          <span>{`${text.slice(1)}...`}</span>
        </>
      )
    );
  };

  const getZipCOde = () => {
    // eslint-disable-next-line prefer-destructuring
    const zipAndCity = station.address.split(", ")[1];
    return zipAndCity.split(" ")[0];
  };

  const getCity = () => {
    // eslint-disable-next-line prefer-destructuring
    const zipAndCity = station.address.split(", ")[1];
    return zipAndCity.split(" ")[1];
  };

  const renderPrice = () => {
    // eslint-disable-next-line prefer-destructuring
    const price = station[`price-${typeFuel ?? "price-diesel"}`];

    return (
      <p>
        {getPrice(price)}
        <span>{getSubPrice(price)}</span>
      </p>
    );
  };

  return (
    station && (
      <div className="station" onClick={() => onStationClick(station)}>
        <div className="price">{renderPrice()}</div>
        <div className="row">
          <div className="data justify-between full-width">
            <div className="street">
              <p className="street_st">{renderStreet()}</p>
              <div className="address">
                <span className="zip">{getZipCOde()} </span>{" "}
                {trimText(getCity(), 12)}
              </div>
            </div>
            <div className="brand">
              {station.brand ? trimText(station.brand, 10) : "-"}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Station;
