import React, { useState } from 'react';
/** Loading components **/
import TripHeading from '../TripHeaderInfo';
import MainTrip from '@containers/Trips/Trip/TripDetails/MainTrip/MainTrip.component';
import TripEditActions from '@components/Trip/SingleTrip/TripEditActions/TripEditActions.component';
import TripTrackList from '@components/Trip/Card/TripTrackList/TripTrackList.component';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';

/** Loading MDI Icons **/
import CloseIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';

/** Loading translation helper **/
import { t } from '@helpers/i18n';

const TripEdit = ({ trip = {}, onClose = () => {}, onSaveTrip = () => {} }) => {
  const { tracks, drivers } = trip;

  /**
   * local states
   */
  const [mainTripEdit, setMainTripEdit] = useState(true);
  const [tripDriver, setTripDriver] = useState(null);
  const [tripType, setTripType] = useState(null);
  const [search, setSearch] = useState(null);
  const [editedTrips, setEditedTrips] = useState([]);

  /**
   * set local states on Main trip click
   * @param isMainTripSelected
   */
  const onMainTripClick = (isMainTripSelected) => {
    setEditedTrips([]);
    setMainTripEdit(isMainTripSelected);
    setTripDriver(null);
    setTripType(null);
    setSearch(null);
  };

  /**
   * Change trip type
   * @param updatedType
   */
  const onTripTypeChange = (updatedType) => {
    if (mainTripEdit || editedTrips.length) {
      const type = updatedType === tripType ? '' : updatedType;
      setTripType(type);
    }
  };

  /**
   * On single track click event
   * @param {object} trip - trip/track object
   * @param {boolean} isSameOpened - flag to detect if same trip is opened
   */
  const onTripClick = (trip, isSameOpened) => {
    if (trip) {
      setMainTripEdit(null);
      if (!editedTrips.length || (editedTrips.length === 1 && isSameOpened)) {
        setTripDriver(null);
        setTripType(null);
        setSearch(null);
      }
      if (isSameOpened) {
        const filtered = editedTrips.filter((tripId) => tripId !== trip.id);
        setEditedTrips(filtered);
      } else {
        setEditedTrips([...editedTrips, trip.id]);
      }
    }
  };

  /**
   * create save object for trip edit action
   */
  const onSaveClick = () => {
    const saveObj = {
      driver: tripDriver,
      type: tripType,
      editedTrips: editedTrips,
      wholeTrip: mainTripEdit
    };
    onSaveTrip(saveObj);
  };

  return (
    <>
      <div className="close-button" onClick={onClose}>
        <CloseIcon size={18} />
      </div>
      <TripHeading trip={trip} />
      <MainTrip tracks={tracks} isMainSelected={mainTripEdit} onMainTripClick={onMainTripClick} />
      <TripEditActions
        editFormActive={!(!mainTripEdit && !editedTrips.length)}
        search={search}
        setSearch={setSearch}
        tripType={tripType}
        onDriverSelect={setTripDriver}
        onTripTypeChange={onTripTypeChange}
      />
      <TripTrackList tracks={tracks} drivers={drivers} selectedSubtrips={editedTrips} onTripClick={onTripClick} />
      <Button
        isDisabled={!mainTripEdit && !editedTrips.length}
        type={BUTTON_TYPES.SUCCESS}
        icon={<CheckIcon />}
        text={t('confirm')}
        className={'trip-btn self-center'}
        onClick={onSaveClick}
      />
    </>
  );
};

export default TripEdit;
