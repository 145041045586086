import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
/** load components **/
import TripHeading from '@containers/Trips/Trip/TripDetails/TripHeaderInfo';
import MainTrip from '@containers/Trips/Trip/TripDetails/MainTrip/MainTrip.component';
import TripDriverInfo from '@containers/Trips/Trip/TripDetails/TripDriverInfo';
import NoResultsPopup from '@components/Common/NoResultsPopup';
import TripTrackList from '@components/Trip/Card/TripTrackList/TripTrackList.component';
import NoticeBlock from '@components/Common/NoticeBlock';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';
/** load icons **/
import CameraTimerIcon from 'mdi-react/CameraTimerIcon';
/** load formatters **/
import dayjs from 'dayjs';
import { t } from '@helpers/i18n';

const TripView = ({ trip = {}, drawDirections = () => {}, onEditClick = () => {} }) => {
  const { tripDate } = useParams();

  /**
   * extracting props
   */
  const { tracks, drivers } = trip;

  /**
   * local states
   */
  const [editedTrips, setEditedTrips] = useState([]);
  const [mainTripEdit, setMainTripEdit] = useState(true);

  /**
   * Check if trip is older than 7 days
   */
  const isNewerTrip = () => {
    if (tripDate) {
      const todayDate = dayjs();
      return todayDate.diff(tripDate, 'day') < 7;
    }
    return false;
  };

  const tripIsNew = isNewerTrip();

  /**
   * Main Trip Click draw on Map
   * @param {boolean} flag
   */
  const onMainTripClick = (flag) => {
    setEditedTrips([]);
    setMainTripEdit(flag);
    drawDirections();
  };

  /**
   * Regular Trip Click draw Map
   * @param {object} trip
   */
  const onTripClick = (trip) => {
    setMainTripEdit(null);
    if (editedTrips.includes(trip.id)) {
      setEditedTrips([]);
      drawDirections(null);
    } else {
      setEditedTrips(trip.id);
      drawDirections(trip);
    }
  };

  /**
   * Shows message when there are no tracks
   * @returns JSX.Element
   */
  if (!tracks?.length) {
    return (
      <div className={'flex justify-center'}>
        <NoResultsPopup />
      </div>
    );
  }

  return (
    <>
      <TripHeading trip={trip} />
      <MainTrip tracks={tracks} isMainSelected={mainTripEdit} onMainTripClick={onMainTripClick} />
      <TripDriverInfo tracks={tracks} drivers={drivers} />
      <TripTrackList
        tracks={tracks}
        drivers={drivers}
        editMode={false}
        selectedSubtrips={editedTrips}
        onTripClick={onTripClick}
      />
      {!tripIsNew && (
        <NoticeBlock className={'trip-notice'}>
          <div className="notice-container">
            <div className="notice-text">{t('lbl_trip_old')}</div>
          </div>
        </NoticeBlock>
      )}
      <Button
        isDisabled={!tripIsNew}
        type={BUTTON_TYPES.WARNING}
        icon={<CameraTimerIcon />}
        text={t('edit')}
        className={'trip-btn self-center'}
        onClick={onEditClick}
      />
    </>
  );
};

export default TripView;
