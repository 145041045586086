import * as vehiclesConstants from "@store/vehicles/constants";

export const vehicleGet = (payload) => ({type: vehiclesConstants.VEHICLE_GET_REQUEST, payload});
export const vehicleGetSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_GET_SUCCESS, payload});
export const vehicleGetFail = (error) => ({type: vehiclesConstants.VEHICLE_GET_FAIL, error});

export const vehicleLoadMore = (payload) => ({type: vehiclesConstants.VEHICLE_LOAD_MORE_REQUEST, payload});
export const vehicleLoadMoreSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_LOAD_MORE_GET_SUCCESS, payload});
export const vehicleLoadFail = (payload) => ({type: vehiclesConstants.VEHICLE_LOAD_MORE_FAIL, payload});

export const vehicleGeneralInfo = (payload) => ({type: vehiclesConstants.VEHICLE_GENERAL_INFORMATION_REQUEST, payload});
export const vehicleGeneralInfoSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_GENERAL_INFORMATION_SUCCESS, payload});
export const vehicleGeneralInfoFail = (payload) => ({type: vehiclesConstants.VEHICLE_GENERAL_INFORMATION_FAIL, payload});

export const loadVehicleLocation = (payload) => ({type: vehiclesConstants.GET_VEHICLE_LOCATION, payload});
export const loadVehicleLocationSuccess = (payload) => ({type: vehiclesConstants.GET_VEHICLE_LOCATION_SUCCESS, payload});
export const loadVehicleLocationFail = (payload) => ({type: vehiclesConstants.GET_VEHICLE_LOCATION_FAIL, payload});

export const saveVehiclesTagsRequest = (payload) => ({type: vehiclesConstants.SAVE_VEHICLE_TAGS_REQUEST, payload});
export const saveVehiclesTagsSuccess = (payload) => ({type: vehiclesConstants.SAVE_VEHICLE_TAGS_SUCCESS, payload});
export const saveVehiclesTagsFail = (payload) => ({type: vehiclesConstants.SAVE_VEHICLE_TAGS_FAIL, payload});

export const getVehiclesCarChecksRequest = (payload) => ({type: vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_REQUEST, payload});
export const getVehiclesCarChecksSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_SUCCESS, payload});
export const getVehiclesCarChecksFail = (payload) => ({type: vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_FAIL, payload});

export const loadMoreVehiclesCarChecksRequest = (payload) => ({type: vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE, payload});
export const loadMoreVehiclesCarChecksSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE_SUCCESS, payload});
export const loadMoreVehiclesCarChecksFail = (payload) => ({type: vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE_FAIL, payload});

export const vehiclesGetDamagesById = (payload) => ({type: vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_REQUEST, payload});
export const vehiclesGetDamagesByIdSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_SUCCESS, payload});
export const vehiclesGetDamagesByIdFail = (payload) => ({type: vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_FAIL, payload});

export const vehicleDamagesLoadMore = (payload) => ({type: vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE, payload});
export const vehicleDamagesSuccess = (payload) => ({type: vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_SUCCESS, payload});
export const vehicleDamagesFail = (payload) => ({type: vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_FAIL, payload});

export const vehicleAssignDriver = (payload) => ({ type: vehiclesConstants.VEHICLE_ASSIGN_DRIVER, payload });
export const vehicleAssignDriverSuccess = (payload) => ({ type: vehiclesConstants.VEHICLE_ASSIGN_DRIVER_SUCCESS, payload });
export const vehicleAssignDriverFail = (payload) => ({ type: vehiclesConstants.VEHICLE_ASSIGN_DRIVER_FAIL, payload });
