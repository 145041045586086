import React, {useEffect, useRef, useState, memo} from "react";

/** Loading components **/
import BlockingOverlayComponent from "@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component";
import {Input} from "@components/Common/Input";
import Slider from "@components/Common/Slider";
import MultipleUploadComponent from "@components/Common/MultipleUpload";
import Alert from "@components/Common/Alert/Alert.component";

/** Loading MUI components **/
import Close from "mdi-react/CloseIcon";

/** Loading helper functions and formik components **/
import {t} from "@helpers/i18n";
import {onImageRemove, onImageUpload, toBase64} from "@services/utils";
import {Field, Formik} from "formik";
import {BUTTON_TYPES} from "@constants";

/** Loading styles **/
import './ServiceOrder.styles.scss';

/** Setting the initial state of the form. **/
const initialState = {
  dashboardImages: [],
  orderDescription:'',
  message:''
};


const ServiceOrder = ({stepData, onChange, isDisabled, error, otherSelected}) => {

  const imgSliderRef = useRef();
  const [slides, setSlides] = useState(new Array([]).fill(null));
  const [alertVisible, setAlertVisible] = useState(false);

  /**
   * Gets order description from translation files
   * @type {{label: string, id: string, value: string}[]}
   */
  const orderDescriptions = Object.entries(t('appointment_options'))
    .map(([key, value]) => ({value, label: value, id: key}));

  /**
   * Local state for all changes in the step.
   *
   * On mount should be set to global data from parent,
   * so the form can be prefilled with data when going in between steps
   */
  const [data, setData] = useState(stepData || initialState);
  const [removedFiles, setRemovedFiles] = useState([]);

  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  /**
   * It returns the dashboardImages array from the stepData object
   * @returns An array of objects.
   */
  const renderUploadedDashboardImages = () => {
    return stepData.dashboardImages;
  };

  /**
   * Initial slider call when user clicks on image
   * @param {number} index
   * @param imgSliderRef
   **/
  const onPreviewImage = (index, imgSliderRef) => {
    imgSliderRef.current.previewImage(index);
  };

  /**
   * It takes an index, finds the image at that index in the array of images, converts it to base64,
   * and returns an object with the base64 string as the value of the blob key
   * @param index - the index of the image in the array of images
   * @returns an object with a data property that has a blob property.
   */
  const loadFullImageDashboard = async (index) => {
    const filteredImages = renderUploadedDashboardImages();
    const image = await toBase64(filteredImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };

  /**
   * It returns true if the dashboardImages array has at least one element
   * @returns A boolean value.
   */
  const checkImageUpload = () => {
    return !!data.dashboardImages.length;
  };

  /**
   * @param {string} propertyName
   * @param value
   */
  const onFieldChange = (propertyName, value) => {
    setData({... data, [propertyName]: value});
  };

  const alertClose = () => {
    setAlertVisible(false);
  };

  const validateFile = file => {
    if (!file.type.startsWith('image/')) {
      setAlertVisible(true);
      return false;
    }
    return true;
  };

  const onUploadImageChange = (e, values) => {
    const files = Array.from(event.target.files);

    if (files.every(validateFile)) {
      onImageUpload(e.target.files, values.dashboardImages || [], onFieldChange, stepData.dashboardImages, setSlides, 'dashboardImages');
    }
  };

  return (
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent />}

      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={data}>
        {({values}) => {
          return (
            <div className={'service-order'}>
              <h3 className={'card-title'}>{t('service_inspection_title')}</h3>

              <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"20px", marginBottom:"20px"}}>
                <h4 style={{marginBottom:"10px"}}>{t('service_inspection_dashboard_title')}</h4>
              </div>


              {!!values?.dashboardImages?.length &&
                  <>
                    <div className={'damage-image-list'}>
                      {renderUploadedDashboardImages().map((file, index) => (
                        <div className={'image-container'} key={index.toString()}>
                          <img alt={'damage-image'} onClick={() => onPreviewImage(index, imgSliderRef)}
                            src={window.URL.createObjectURL(file)}/>
                          <span className={'img-remove'} onClick={() => onImageRemove(index, onFieldChange, stepData.dashboardImages, 'dashboardImages',setSlides)}><Close/></span>
                        </div>
                      ))}
                    </div>
                    {error?.dashboardImages && <p className={'error-message'}>{t(error?.dashboardImages)}</p>}
                  </>
              }

              {!data.dashboardImages.length &&
                <MultipleUploadComponent
                  name={'dashboardImages'}
                  withoutPreview
                  value={values.dashboardImages || []}
                  onChange={(e) => onUploadImageChange(e, values)}
                  onRemove={(index) => setRemovedFiles([...removedFiles, index])}
                  disabled={checkImageUpload()}
                  multiple={false}
                />}
              {error?.dashboardImages && <p className={'error-message'} style={{marginTop: 0}}>{t(error?.dashboardImages)}</p>}

              <p className="input-label">{otherSelected ? `${t("service_inspection_order_description")} *` : t("service_inspection_order_description")}</p>
              <Field
                value={values.orderDescription}
                className={'select-box dark-blue-input'}
                style={{marginBottom: '20px', maxWidth: '100%'}}
                component={"select"}
                name="orderDescription"
                onChange={(e) => onFieldChange('orderDescription', e.target.value)}
              >
                <option key={'empty'} value={'default'}>{t("please_select")}</option>

                {orderDescriptions.map(order => (
                  <option key={order.id} value={order.value}>{order.label}</option>
                ))}
              </Field>
              {error?.orderDescription && <p className={'error-message'}>{t(error?.orderDescription)}</p>}

              <Field
                label={t("service_inspection_message_to_us")}
                name="message"
                component={Input}
                type={'textArea'}
                rows={5}
                className={'dark-blue-input'}
                style={{marginBottom: '20px', fontSize:'16px'}}
                value={values.message}
                onChange={(e) => onFieldChange('message', e.target.value)}
              />
              {error?.message && <p className={'error-message'}>{t(error?.message)}</p>}

            </div>
          );
        }}
      </Formik>
      {
        <Slider
          ref={imgSliderRef}
          slides={slides}
          setSlides={setSlides}
          imageAPI={loadFullImageDashboard}
        />
      }
      <Alert visible={alertVisible} title={t('image_upload_warning')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS}/>
    </div>
  );
};

export default memo(ServiceOrder);
