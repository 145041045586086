import React from 'react';
import { useSelector } from 'react-redux';
import Download from 'mdi-react/DownloadOutlineIcon';
import './index.scss';
import { Spinner } from '@components/Spinner';
import { t } from '@helpers/i18n';

const VehicleRegistration = () => {
  /**
   * Redux selectors
   */
  const { vehicle, singleLoading } = useSelector((state) => state.vehicles);
  const isVehicleRegistrationAvailable = vehicle?.vehicleRegistrationImages[0]?.blob !== false;

  /**
   * Depending on file type render pdf or img
   * @returns {JSX.Element}
   */
  const rederVehicleRegistration = () => {
    // file types
    const fileTypes = {
      J: 'pdf',
      '/': 'jpg',
      i: 'png'
    };
    const fileType = fileTypes[vehicle?.vehicleRegistrationImages[0]?.blob.charAt(0)];

    if (fileType === 'pdf') {
      return (
        <div className="vehicle-row" style={{ height: '100%', display: 'flex' }}>
          <div className={'image-popup'}>
            <iframe
              src={`data:application/pdf;base64,${vehicle?.vehicleRegistrationImages[0].blob}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
              className={'pdf-frame'}
            />
          </div>
          <a
            className="download"
            download={`${vehicle?.licencePlate.replaceAll(' ', '-')}.pdf`}
            href={`data:application/pdf;base64, ${vehicle?.vehicleRegistrationImages[0].blob}`}>
            <Download />
            <span>{t('registration-download')}</span>
          </a>
        </div>
      );
    } else {
      return (
        <div className="vehicle-row" style={{ height: '100%', display: 'flex' }}>
          <img
            className="image-popup"
            src={`data:image/${fileType};base64,${vehicle?.vehicleRegistrationImages[0]?.blob}`}
            alt="license"
          />
          <a
            className="download"
            download={`${vehicle?.licencePlate.replaceAll(' ', '-')}.jpg`}
            href={`data:image/png;base64, ${vehicle?.vehicleRegistrationImages[0]?.blob}`}>
            <Download /> <span>{t('registration-download')} </span>
          </a>
        </div>
      );
    }
  };

  return (
    <div className="vehicles-container details-content p-b-2">
      {singleLoading && <Spinner />}
      {!isVehicleRegistrationAvailable && (
        <div className={'vehicle-row'} id={'no-pdf'}>
          <span className={'no-pdf'}>{t('no_pdf_data')}</span>
        </div>
      )}
      {!singleLoading &&
        !!vehicle?.vehicleRegistrationImages?.length &&
        isVehicleRegistrationAvailable &&
        rederVehicleRegistration()}
    </div>
  );
};

export default VehicleRegistration;
