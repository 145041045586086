import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { msAxios } from "@helpers/api";
import { toast } from "react-toastify";
import { Api } from "@api";
import store from "store2";
import md5 from "js-md5";
import { t } from "@helpers/i18n";

import * as authConstants from "./constants";
import {translationKeys} from '@constants';
import {
  authChallengeResetPasswordFail,
  authChallengeResetPasswordSuccess
} from "@store/auth/actions";

function* login(action) {
  try {
    const { username, password } = action.payload;
    const { data: { salt } } = yield call(Api.auth.getSalt, { username });

    const encryption = md5(`${ password }${ salt }`);
    const encryptedPassword = `${ encryption }:${ salt }`;

    const auth = yield call(Api.auth.login, { username, password: encryptedPassword, language: 'en-GB' });

    if (auth.data.accesslevel === 'normal') {
      toast.error(t('error_upgrade_account'), { position: "bottom-center" });
      yield put({ type: authConstants.AUTH_LOGIN_FAIL });
      return;
    }

    msAxios.defaults.headers.common.authentification = `Bearer ${auth.data.authentification}`;
    const info = yield call(Api.auth.getInfo, auth.data.id);

    const userData = info.data;
    userData.language = translationKeys[userData.language.substring(0, 2)];

    const combined = { info: userData, user: auth.data };

    const profileData = { ...userData, avatar: auth.data.avatar };

    yield put({ type: authConstants.AUTH_LOGIN_SUCCESS, payload: combined });
    yield put({ type: authConstants.PROFILE_GET_SUCCESS, payload: profileData });
    store.set("auth", combined);
    store.set('profile', profileData);
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: authConstants.AUTH_LOGIN_FAIL });
    toast.error(t('error_login'), { position: "bottom-center" });
  }
}

function* logout() {
  try {
    store.clearAll();
    yield put({ type: authConstants.AUTH_LOGOUT_SUCCESS });
  } catch (err) {
    yield call(errorHandler, err);
  }
}

function* resetPassword(action) {
  try {
    const { username } = action.payload;
    const { data } = yield call(Api.users.resetPassword, username);
    yield put({ type: authConstants.AUTH_RESET_PASSWORD_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: authConstants.AUTH_RESET_PASSWORD_FAIL, payload: err });
  }
}

function* challengeResetPassword (action) {
  try {
    const { email: username, password, token, callback } = action.payload;
    const { data: { salt } } = yield call(Api.auth.getSalt, { username });
    const encryption = md5(`${password}${salt}`);
    const encryptedPassword = `${encryption}:${salt}`;
    const { data } = yield call(Api.users.challengeReset, { username, password: encryptedPassword, token });
    yield put(authChallengeResetPasswordSuccess(data));
    if (callback) {
      yield call(callback);
    }
  } catch (err) {

    // Error key to message mapping
    const errorMessages = {
      COM_FLMS_PASSWORD_EMPTY: t('password_error.password_empty_error'),
      COM_FLMS_USERNAME_EMPTY: t('password_error.username_empty_error'),
      COM_FLMS_TOKEN_EMPTY: t('password_error.token_empty_error'),
      COM_FLMS_TOKEN_EXPIRED: t('password_error.token_expired_error'),
      COM_FLMS_TOKEN_NOT_VALID: t('password_error.token_not_valid_error'),
      COM_FLMS_TOKEN_ALREADY_USED: t('password_error.token_already_used_error')
    };

    const errorMessage = errorMessages[err?.response?.data?.error_key] || t('password_error.password_reset_error');
    yield call(toast.error, errorMessage, { position: "bottom-center" });

    yield call(errorHandler, err);
    yield put(authChallengeResetPasswordFail(err));
  }
}


export function* authSagas() {
  yield takeLatest(authConstants.AUTH_LOGIN_REQUEST, login);
  yield takeLatest(authConstants.AUTH_LOGOUT_REQUEST, logout);
  yield takeLatest(authConstants.AUTH_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(authConstants.AUTH_CHALLENGE_RESET_PASSWORD_REQUEST, challengeResetPassword);
}
