import * as timeTrackingConstants from './constants';

export const startTimeTracking = (payload) => ({type: timeTrackingConstants.START_TIME_TRACKING_REQUEST, payload});
export const startTimeTrackingSuccess = (payload) => ({type: timeTrackingConstants.START_TIME_TRACKING_SUCCESS, payload});
export const startTimeTrackingFailure = (payload) => ({type: timeTrackingConstants.START_TIME_TRACKING_FAIL, payload});

export const stopTimeTracking = (payload) => ({type: timeTrackingConstants.STOP_TIME_TRACKING_REQUEST, payload});
export const stopTimeTrackingSuccess = (payload) => ({type: timeTrackingConstants.STOP_TIME_TRACKING_SUCCESS, payload});
export const stopTimeTrackingFailure = (payload) => ({type: timeTrackingConstants.STOP_TIME_TRACKING_FAIL, payload});

export const refreshTrackingStatus = (payload) => ({type: timeTrackingConstants.REFRESH_TRACKING_STATUS_REQUEST, payload});
export const refreshTrackingStatusSuccess = (payload) => ({type: timeTrackingConstants.REFRESH_TRACKING_STATUS_SUCCESS, payload});
export const refreshTrackingStatusFail = (error) => ({type: timeTrackingConstants.REFRESH_TRACKING_STATUS_FAIL, error});

export const timeTrackingGet = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_GET_REQUEST, payload});
export const timeTrackingSuccess = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_GET_SUCCESS, payload});
export const timeTrackingFail = (error) => ({type: timeTrackingConstants.TIME_TRACKING_GET_FAIL, error});

export const timeTrackingUserGet = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_USER_GET_REQUEST, payload});
export const timeTrackingUserSuccess = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_USER_GET_SUCCESS, payload});
export const timeTrackingUserFail = (error) => ({type: timeTrackingConstants.TIME_TRACKING_USER_GET_FAIL, error});

export const timeTrackingLoadMore = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_LOAD_MORE_REQUEST, payload});
export const timeTrackingLoadMoreSuccess = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_LOAD_MORE_SUCCESS, payload});
export const timeTrackingLoadMoreFail = (error) => ({type: timeTrackingConstants.TIME_TRACKING_LOAD_MORE_FAIL, error});

export const timeTrackingUserLoadMore = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_REQUEST, payload});
export const timeTrackingUserLoadMoreSuccess = (payload) => ({type: timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_SUCCESS, payload});
export const timeTrackingUserLoadMoreFail = (error) => ({type: timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_FAIL, error});

export const timeTrackingDetailsGet = payload => ({type: timeTrackingConstants.TIME_TRACKING_GET_DETAILS_REQUEST, payload});
export const timeTrackingDetailsGetSuccess = payload => ({type: timeTrackingConstants.TIME_TRACKING_GET_DETAILS_SUCCESS, payload});
export const timeTrackingDetailsGetFail = error => ({type: timeTrackingConstants.TIME_TRACKING_GET_DETAILS_FAIL, error});
