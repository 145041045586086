import * as appointmentConstants from './constants';

const initialState = {
  appointments: [],
  calendarAppointments: [],
  loading: false,
  loadingMore: false,
  createLoading: false,
  lastPage: 0,
  mapAppointment: null,
  mapAppointmentLoader: null,
};

const appointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case appointmentConstants.APPOINTMENT_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appointmentConstants.APPOINTMENT_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: action.payload,
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : state.lastPage,
      };

    case appointmentConstants.APPOINTMENT_GET_ALL_FAIL: {
      return {
        ...state,
        loading: false,
        appointments: []
      };
    }
    case appointmentConstants.APPOINTMENTS_LOAD_MORE_REQUEST: {
      return {
        ...state,
        loadingMore: true
      };
    }
    case appointmentConstants.APPOINTMENTS_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        appointments: [...state.appointments, ...action.payload],
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1,
        loadingMore: false
      };
    }

    case appointmentConstants.APPOINTMENTS_LOAD_MORE_FAIL: {
      return {
        ...state,
        loadingMore: false
      };
    }

    case appointmentConstants.SET_MAP_APPOINTMENT: {
      return {
        ...state,
        mapAppointment: action.payload,
        mapAppointmentLoader: true,
      };
    }

    case appointmentConstants.UPDATE_MAP_APPOINTMENT: {
      return {
        ...state,
        mapAppointmentLoader: false,
      };
    }

    case appointmentConstants.APPOINTMENT_CANCEL_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case appointmentConstants.APPOINTMENT_CREATE_REQUEST: {
      return {
        createLoading: true
      };
    }

    case appointmentConstants.APPOINTMENT_CREATE_SUCCESS: {
      return {
        createLoading: false
      };
    }

    case appointmentConstants.APPOINTMENT_CREATE_FAIL: {
      return {
        createLoading: false
      };
    }

    case appointmentConstants.APPOINTMENT_CALENDAR_SUCCESS: {
      return {
        ...state,
        calendarAppointments: action.payload,
      };
    }

    case appointmentConstants.APPOINTMENT_CALENDAR_FAIL: {
      return {
        ...state,
        calendarAppointments: [],
      };
    }

    default:
      return state;
  }
};

export default appointmentsReducer;
