import React, { useEffect } from 'react';
import { useField } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { t } from '@helpers/i18n';
import '../index.scss';

const NationalitySelect = ({ nationality = null, countries }) => {
  const [field, meta, helpers] = useField('nationality');
  useEffect(() => {
    if (nationality) {
      // If the nationality exists in the countries array, set it as the selected value
      const initialValue = countries.find((country) => country.value === nationality.value) || null;
      helpers.setValue(initialValue);
    }
  }, [nationality, countries, helpers]);
  return (
    <div>
      <p
        style={{
          fontSize: '14px',
          lineHeight: '1.21',
          marginBottom: '10px',
          marginLeft: '10px',
          color: '#00587f'
        }}>
        {t('nationality')}
      </p>
      <Autocomplete
        {...field}
        onChange={(event, newValue) => {
          helpers.setValue(newValue || null);
        }}
        value={field.value || null}
        options={countries}
        isOptionEqualToValue={(options, value) => options.value === value.value}
        sx={{
          backgroundColor: '#eef5f8',
          borderRadius: '5px',
          '& fieldset': { border: 'none', borderRadius: '5px' },
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': { padding: '1px' }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="nationality"
            placeholder={t('chooseNationalityPlaceholder')}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && Boolean(meta.error)}
          />
        )}
        fullWidth
        size="small"
        disablePortal
        id="combo-box-demo"
      />
      {meta.error && meta.touched ? <p className="error-text">{t(`${meta.error}`)}</p> : null}
    </div>
  );
};

export default NationalitySelect;
