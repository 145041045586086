import React from 'react';
/** load components**/
import SelectDriverCmp from '@components/Common/DriverSelect/DriverDropdownUpdate.component';
import TripType from '@components/Trip/SingleTrip/TripType/TripType.component';
/** load tt tool **/
import { t } from '@helpers/i18n';

import './TripEditActions.styles.scss';

const TripEditActions = ({
  onDriverSelect = () => {},
  editFormActive = false,
  search = '',
  setSearch = () => {},
  tripType = '',
  onTripTypeChange = () => {}
}) => {
  /**
   * Trip Type click event
   * @param {string} type - trip type string ['business', 'private']
   */
  const onTripTypeClick = (type) => {
    onTripTypeChange(type);
  };

  return (
    <div className={'trip-actions'}>
      <fieldset disabled={!editFormActive}>
        <div className={'flex type'}>
          <span style={{ marginRight: '10px' }}>{t('lbl_type')}</span>
          <TripType type={'business'} currentType={tripType} onClick={onTripTypeClick} />
          <TripType type={'private'} currentType={tripType} onClick={onTripTypeClick} />
        </div>
        <div className={'driver-logbook'}>
          <span>{t('driver')}</span>
          <SelectDriverCmp search={search} setSearch={setSearch} onDriverSelected={onDriverSelect} />
        </div>
      </fieldset>
    </div>
  );
};

export default TripEditActions;
