import React from 'react';
/** load tt tool **/
import { t } from '@helpers/i18n';

const InvoicePrimaryContent = ({ invoice = {} }) => {
  const { invoiceType, invoiceNumber } = invoice;

  return (
    <div className={'invoice-main-content'}>
      <p>{invoiceType}</p>
      <p>
        {t('invoice_nr')} {invoiceNumber}{' '}
      </p>
    </div>
  );
};

export default InvoicePrimaryContent;
