import React from 'react';

import './MapOverlay.styles.scss';

import { t } from '@helpers/i18n';

const MapOverlay = () => {
  return (
    <div className="map-overlay">
      <div className="map-text"> {t('vehicle-map-no-data')}</div>
    </div>
  );
};

export default MapOverlay;
