import React, { useEffect, useState, createRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Map from '@components/Map';
import SidePage from '@components/Layout/SidePage';
import ServiceInfo from '@components/ServiceInfo';
import MapSidebarContainer from '@containers/MapSidebar';
import useDebounce from '@services/hooks/useDebounce';

import './Map.styles.scss';

import {
  MAP_GET_STATION_LOCATIONS_REQUEST,
  VEHICLES,
  MAP_COORDINATES_SET,
  STATION,
  SERVICES,
  CLEAR_STATUS_SIDEBAR,
  SET_MARKER,
  SET_CURRENT_TAB,
  CLEAR_SEARCH,
  HOVER_MAKER_SERVICE,
  HOVER_MAKER_STATION,
  HOVER_MARKER
} from '@constants';

// Coordinates of Berlin
const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954
};

const defaultZoom = 11;
const defaultRadius = 22;

const MapContainer = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settings);
  const { mapAppointment } = useSelector((state) => state.appointments);

  const {
    vehicles = [],
    stations,
    servicePartners,
    drivers,
    activeVehicleTrip,
    activeVehicleTripLoad
  } = useSelector((state) => state.map);

  const [mapProperties, setMapProperties] = useState({
    center: defaultCenter,
    zoom: defaultZoom,
    radius: defaultRadius
  });

  const [selectedMarker, setSelectedMarker] = useState({});
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);

  const mapPropertiesDebounce = useDebounce(mapProperties, 1000);
  const sidePageRef = createRef();
  const getActiveVehicleRef = useRef(null);
  const REFRESH_RATE = 15 * 1000 * 60; //15 minutes

  const changeStatusSb = () => {
    dispatch({
      type: CLEAR_STATUS_SIDEBAR
    });
  };

  const getStationLocations = () => {
    const {
      center: { lat, lng }
    } = mapProperties;
    const { radius } = mapProperties;
    const { ne, sw } = mapProperties;

    if (ne && sw) {
      const coordinates = {
        neMap: {
          latNe: ne.lat,
          lngNe: ne.lng
        },
        swMap: {
          latSw: sw.lat,
          lngSw: sw.lng
        }
      };

      dispatch({
        type: MAP_COORDINATES_SET,
        payload: coordinates
      });
    }

    const payload = {
      longitude: lng,
      latitude: lat,
      radius,
      'sort-by-fuel-type': settings.typeFuel,
      'brands[]': settings.selectStation?.value.toLowerCase(),
      id: auth.info.id
    };

    if (radius >= 31 || !settings.station) {
      payload['show-gas-stations'] = false;
    }

    if (!settings.partner) {
      payload['show-service-partners'] = false;
    }

    dispatch({
      type: MAP_GET_STATION_LOCATIONS_REQUEST,
      payload
    });
  };

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const onMapBoundsChange = (properties) => {
    const lat1 = toRadians(properties.center.lat);
    const lng1 = toRadians(properties.center.lng);

    const lat2 = toRadians(properties.bounds.nw.lat);
    const lng2 = toRadians(properties.center.lng);

    const radius =
      Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2)) * 6371;

    const formattedRadius = radius > 30 ? 31 : Math.round(radius);

    setMapProperties({ ...properties, radius: formattedRadius });
  };

  const extractCoordinates = (type, object) => {
    if (type === SERVICES) {
      const { lat, lng } = object.address;

      return { lat: +lat, lng: +lng };
    }

    const { latitude, longitude } = object;

    return { lat: +latitude, lng: +longitude };
  };

  const hoverMarker = (type, data) => {
    switch (type) {
      case VEHICLES:
        dispatch({
          type: HOVER_MAKER_SERVICE,
          payload: data
        });
        break;

      case SERVICES:
        dispatch({
          type: HOVER_MAKER_SERVICE,
          payload: data
        });
        break;

      case STATION:
        dispatch({
          type: HOVER_MAKER_STATION,
          payload: data
        });
        break;

      default:
        break;
    }
  };

  const clearMarker = () => {
    setSelectedService(null);
    setSelectedStation(null);
    setSelectedCar(null);
    setSelectedMarker({ type: '', id: null, object: null });

    dispatch({
      type: HOVER_MARKER,
      payload: null
    });
    dispatch({
      type: HOVER_MAKER_SERVICE,
      payload: null
    });
    dispatch({
      type: HOVER_MAKER_STATION,
      payload: null
    });
    dispatch({
      type: CLEAR_SEARCH
    });
  };

  const onMarkerClick = (type, id, object) => {
    let currentTab = 2;

    dispatch({
      type: SET_MARKER,
      payload: [id, type, object]
    });
    setSelectedMarker({ type, id, object });

    switch (type) {
      case VEHICLES:
        currentTab = 2;
        break;

      case SERVICES:
        currentTab = 3;
        break;

      case STATION:
        currentTab = 4;
        break;

      default:
        currentTab = 2;
        break;
    }

    dispatch({
      type: MAP_COORDINATES_SET,
      payload: extractCoordinates(type, object)
    });

    dispatch({
      type: SET_CURRENT_TAB,
      payload: currentTab
    });

    sidePageRef.current.openSidebar();

    if (selectedMarker?.id === id) {
      clearMarker();
    } else {
      hoverMarker(type, object);
    }
  };

  const getEmployees = () => {
    return drivers?.map((driver) => {
      return {
        name: driver.name,
        surname: driver.surname,
        avatar: driver.avatar,
        state: driver.state,
        manufacturer: driver.car.manufacturer,
        car: { licencePlate: driver.car.licencePlate },
        dataCompleted: driver.dataCompleted
      };
    });
  };

  const renderSideBar = () => {
    if (settings?.selectAppointment) {
      return <ServiceInfo id={settings.selectAppointment.attributes.id} />;
    }

    const mapSidebarContainerProps = {
      selectedMarker,
      settings,
      vehicles,
      employees: getEmployees(),
      servicePartners,
      stations,
      activeVehicleTrip,
      selectedService,
      selectedStation,
      selectedCar,
      setSelectedService,
      setSelectedStation,
      setSelectedCar,
      ref: getActiveVehicleRef
    };

    return <MapSidebarContainer {...mapSidebarContainerProps} />;
  };

  useEffect(() => {
    const refresh = setInterval(() => {
      getStationLocations();
      getActiveVehicleRef.current.getRefreshVehicleTrip();
    }, REFRESH_RATE);

    return () => clearInterval(refresh);
  }, []);

  useEffect(() => {
    getStationLocations();
  }, [
    mapPropertiesDebounce,
    settings.station,
    settings.partner,
    settings.typeFuel,
    settings.selectStation?.value,
    auth
  ]);

  useEffect(() => {
    if (mapAppointment) {
      sidePageRef.current.openSidebar();
      setSelectedService(mapAppointment);
      dispatch({
        type: HOVER_MAKER_SERVICE,
        payload: mapAppointment
      });
      dispatch({
        type: SET_CURRENT_TAB,
        payload: 3
      });
    }
  }, [mapAppointment]);

  return (
    <div className="maps-container">
      <Map
        defaultCenter={defaultCenter}
        center={defaultCenter}
        defaultZoom={defaultZoom}
        vehicles={vehicles}
        stations={stations}
        servicePartners={servicePartners}
        trip={activeVehicleTrip}
        onMapBoundsChange={onMapBoundsChange}
        onMarkerClick={onMarkerClick}
        settings={settings}
        loadingTrip={activeVehicleTripLoad}
        activeVehicle={selectedCar}
      />
      <div className={settings?.statusSideBar ? 'status-open' : 'status-close'}>
        <SidePage ref={sidePageRef} change={changeStatusSb} close={!settings?.statusSideBar}>
          {renderSideBar()}
        </SidePage>
      </div>
    </div>
  );
};

export default MapContainer;
