import React, {useState} from 'react';
import {Field, Formik} from "formik";
import { Input } from "@components/Common/Input";
import PhotoIcon from "mdi-react/CameraOutlineIcon";
import Trash from "mdi-react/TrashCanOutlineIcon";
import Avatar from "@components/User/Avatar";
import PersonIcon from "mdi-react/AccountIcon";
import CheckIcon from "mdi-react/CheckIcon";
import { Button } from "@components/Common/Button";
import {BUTTON_TYPES} from "@constants";
import { t } from "@helpers/i18n";
import CmpDatePicker from "@components/Common/DatePicker";
import ProfileImageUpload from './ProfileImageUpload';
import {toBase64} from "@services/utils";
import {Grid} from "@mui/material";
import {CmpInput} from "@components/Common/CmpInput/CmpInput.component";
import {isValidDate} from "@services/formatters/util/DateTime.formatters";

const EditProfile = ({ profile, loading, saveProfile }) => {

  const [showIcons, setShowIcons] = useState(false);

  const [avatar, setAvatar] = useState(profile.avatar);

  /** show edit & remove avatar image btns **/
  const onShowImageEdit = () => {
    setShowIcons(!showIcons);
  };

  /** change profile image **/
  const changeProfileImage = async (files) => {
    const [uploadedImage] = files;
    const imgBase64 = await toBase64(uploadedImage);

    const startIndex = imgBase64.indexOf(',');
    const updatedAvatar = imgBase64.substring(startIndex + 1);

    setAvatar(updatedAvatar);
  };
  /** remove current profile image **/
  const removeProfileImage = (handleChange) => {
    setAvatar('');
  };

  /** save all profile changes **/
  const onProfileSave = (values) => {
    saveProfile({  ...values, avatar, id: profile.id, language: profile.language });
  };

  return (
    <section className={'edit-profile-section details-content p-b-2'}>
      <Formik
        enableReinitialize
        initialValues={
          {
            salutation: profile.salutation,
            surname: profile.surname,
            name: profile.name,
            customerNumber: profile.customerNumber,
            address: {street: profile.address?.street ?? '',
              number: profile.address?.number ?? '',
              zipcode: profile.address?.zipcode ?? '',
              city: profile.address?.city ?? ''},
            birthday: (isValidDate(profile.birthday) ? new Date(profile.birthday) : null),
            phone: profile.phone,
            email: profile.email
          }
        }>
        {({values, submitForm, handleChange, setFieldValue }) => (
          <form className="edit-user-info user" onSubmit={(e) => { e.preventDefault();}}>
            <div className="edit-row-left">
              <div className={'img-row'}>
                <span className="camera" onClick={onShowImageEdit}><PhotoIcon /></span>
                {showIcons && <span className="icons">
                  <span className="icon-bg">
                    <PhotoIcon />
                    <ProfileImageUpload
                      name={'profileImage'}
                      onChange={(event) => changeProfileImage(event.target.files)}/>
                  </span>
                  <span className="icon-bg-remove" onClick={() => removeProfileImage(handleChange)}><Trash /></span>
                </span>}
                <Avatar
                  id={'avatar'}
                  icon={<PersonIcon color="white" />}
                  src={avatar}
                />
              </div>
              <Field
                className="customer-number-info"
                label={t("customer-number")}
                name="customerNumber"
                component={Input}
              />
            </div>
            <div className="edit-row-center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label className="input-label">
                    <p className="title" style={{marginLeft:"10px"}}>{t("title")}</p>
                  </label>
                  <Field
                    className={'select-box'}
                    label={t("title")}
                    component={"select"}
                    name="salutation"
                  >
                    <option value="mr">{t("herr")}</option>
                    <option value="mrs">{t("frau")}</option>
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label={t("name")}
                    name="name"
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label={t("surname")}
                    name="surname"
                    component={CmpInput}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Field
                    label={t("street")}
                    name="address.street"
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    label={t("house-number")}
                    name="address.number"
                    component={CmpInput}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Field
                    label={t("zipcode")}
                    name="address.zipcode"
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    label={t("city")}
                    name="address.city"
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CmpDatePicker
                    name="birthday"
                    label={t("birthday")}
                    value={values.birthday}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={t("phone")}
                    name="phone"
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={t("Email")}
                    name="email"
                    component={CmpInput}
                  />
                </Grid>
              </Grid>
              <Button
                className="is-success"
                icon={<CheckIcon />}
                name="success"
                value="btn-success"
                type={BUTTON_TYPES.SUCCESS}
                text={t("save")}
                isLoading={loading}
                onClick={() => onProfileSave(values)}
              />
            </div>
          </form>
        )}
      </Formik>
    </section>);
};

export default EditProfile;
