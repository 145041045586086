import React from 'react';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import { t } from '@helpers/i18n';
import TrackingFilterForm from '@components/TimeTracking/Filter/FilterForm';

const TrackingFilter = ({
  filterValues = {},
  visible = false,
  trackingDates,
  onDateChange = () => {},
  filterChange = () => {},
  applyFilter = () => {}
}) => {
  return (
    visible && (
      <div className={'list-filter'}>
        <TrackingFilterForm
          trackingDates={trackingDates}
          filter={filterValues}
          onChange={(filterTitle) => filterChange(filterTitle)}
          onDateChange={onDateChange}
        />
        <ApplyButton onClick={applyFilter} text={t('apply')} />
      </div>
    )
  );
};

export default React.memo(TrackingFilter);
