import { store } from 'index';
import axios from 'axios';
const LEGACY_LOGIN_USERNAME = 'mobile@fleetlink.de';
const LEGACY_LOGIN_PASSWORD = 'MzY0NmMyNzktNGZiYy00NzFlLWE4ODgtMzg3ODU2MWRiMTFi';

const ENDPOINT_FLCC = 'https://production.msfleetlink.de/flcc';
const ENDPOINT_MSFLEETLINK = 'https://production.msfleetlink.de/';

export const to = (path) => {
  return `${ENDPOINT_FLCC}${path}`;
};

export const toMs = (path) => {
  return `${ENDPOINT_MSFLEETLINK}${path}`;
};

// This functions grabs the legacy token
export const withLegacyToken = async (callback) => {
  try {
    const res = await axios.post(to('auth/login'), {
      username: LEGACY_LOGIN_USERNAME,
      password: LEGACY_LOGIN_PASSWORD
    });

    return callback.apply(this, [res.data.token]);
  } catch (err) {
    return err;
  }
};

export function getBearer() {
  // @todo Check if we can do better.
  const { auth } = store.getState().auth;
  if (auth?.user?.authentification) {
    return `Bearer ${auth.user.authentification}`;
  }
}

export const msAxios = axios.create({
  baseURL: ENDPOINT_MSFLEETLINK
});

export const flAxios = axios.create({
  baseURL: ENDPOINT_FLCC
});

msAxios.interceptors.request.use((config) => {
  const bearer = getBearer();

  if (bearer) {
    config.headers['authentification'] = bearer;
  }

  return config;
});

export const requestMs = {
  get: (path, params = {}) => msAxios.get(path, { params, cancelToken: params.cancelToken }),
  post: (path, params = {}) => msAxios.post(path, params),
  put: (path, params = {}) => msAxios.put(path, params),
  patch: (path, params = {}) => msAxios.patch(path, params),
  delete: (path, params = {}) => msAxios.delete(path, params)
};

export const requestFl = {
  get: (path, params = {}) => flAxios.get(path, { params, cancelToken: params.cancelToken }),
  post: (path, params = {}) => flAxios.post(path, params),
  put: (path, params = {}) => flAxios.put(path, params),
  patch: (path, params = {}) => flAxios.patch(path, params),
  delete: (path, params = {}) => flAxios.delete(path, params)
};
