import { requestMs } from "@helpers/api";

export const map = {
  getStationsFromHome: (params) => requestMs.get("api3/home", { ...params }),
  getDrivers: (params) =>
    requestMs.get(
      `/api3/user/${params}/drivers?filterShowOnlyWithCar=1`,
      {}
    ),
};
