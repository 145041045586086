import React from 'react';

/** Load components **/
import FilterDatePicker from '@components/Common/FilterDatePicker';

/** Load helper functions **/
import { t } from '@helpers/i18n';

const TrackingFilterForm = ({ filter, trackingDates, onDateChange }) => {
  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        <label key={filter.id}>
          <p>{t(filter.title)}</p>
          {/*<Toggler*/}
          {/*  id={filter.id.toString()}*/}
          {/*  checked={filter.value}*/}
          {/*  onChange={() => onChange(filter.title)} />*/}
        </label>
        {filter.value && (
          <div className="date-picker">
            <FilterDatePicker
              dateFrom={trackingDates.dateFrom}
              dateTo={trackingDates.dateTo}
              onDateChange={onDateChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TrackingFilterForm);
