import React, { useState } from 'react';
import { t } from '@helpers/i18n';
import Close from 'mdi-react/CloseIcon';
import './MultipleUpload.styles.scss';

const MultipleUploadComponent = ({
  value = null,
  name = 'file',
  allowFileType = 'image/png, image/jpeg',
  onChange = () => {},
  onRemove = () => {},
  withoutPreview,
  disabled = false,
  multiple = true
}) => {
  const [removedFiles, setRemovedFiles] = useState([]);

  const onImageRemove = (index) => {
    setRemovedFiles([...removedFiles, index]);
    onRemove(index);
  };

  return (
    <div className="Upload">
      <div className={'upload-cmp'}>
        <p className={'title'}>{t('file_upload_title')}</p>
        <input
          className={'file-upload'}
          name={name}
          type="file"
          multiple={multiple}
          accept={allowFileType}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {!withoutPreview && value && (
        <div className={'img-preview'}>
          {value.map((file, index) =>
            removedFiles.includes(index) ? (
              <></>
            ) : (
              <div className={'img-container'} key={index.toString()}>
                <img src={window.URL.createObjectURL(file)} />
                <span className={'img-remove'} onClick={() => onImageRemove(index)}>
                  <Close />
                </span>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MultipleUploadComponent;
