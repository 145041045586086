import React from 'react';
import { t } from '@helpers/i18n';

import DriverSearchSelect from './DriverSelectSearch';
import LicensePopup from '@components/Vehicle/General/VehicleForm/DriverLicensePopup';

import './DriverSelect.styles.scss';

const DriverSelect = ({ getDrivers, driverSelected, showLicenseImg, licenseImage, closePopup, addDriver }) => {
  const licensePopupProps = { visible: showLicenseImg, licenseImg: licenseImage, closePopup };
  return (
    <>
      <p className="label select-driver-label">{t('select-driver')}:</p>
      <div className={'driver-select-wrapper'}>
        <DriverSearchSelect itemSelected={driverSelected} getDrivers={getDrivers} addDriver={addDriver} />
        <LicensePopup {...licensePopupProps} />
      </div>
    </>
  );
};

export default DriverSelect;
