import React from 'react';
import Avatar from '@components/User/Avatar';
import SimpleCard from '@components/Card/SimpleCard';
import { LicensePlate } from '@components/Car/LicensePlate';
import { CARD_TYPES } from '@components/Card';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';

/** Load css styles **/
import './index.scss';

const EmployeeCard = ({ employee = {}, onClick = () => {} }) => {
  const { name, surname, avatar, dataCompleted, manufacturer, model } = employee;
  const { licencePlate = '' } = employee.car;

  /**
   * return proper class for right border of Employee card
   **/
  const vehicleCardColor = () => {
    if (dataCompleted) {
      return `damage_border_${CARD_TYPES.GREEN}`;
    } else {
      return `damage_border_${CARD_TYPES.RED}`;
    }
  };

  /**
   * return Icon for Employee card
   * @returns {{}}
   */
  const getIcons = () => {
    if (!dataCompleted) {
      return <CardAccountDetailsOutlineIcon color={'red'} />;
    } else {
      return <CheckIcon color={'#95C11E'} />;
    }
  };

  /**
   * Render Employee Card Left Block
   **/
  const renderLeft = () => {
    return (
      <>
        <Avatar src={avatar} iconBackgroundColor={'transparent'} small={true} />
        <div className={'employee-info'}>
          <p>{name}</p>
          <p>{surname}</p>
        </div>
      </>
    );
  };

  const renderCarManufacturer = (
    <div>
      {manufacturer} {model}
    </div>
  );

  /**
   * Render Employee Card Right Block
   **/
  const renderRight = () => {
    return (
      <>
        {manufacturer && renderCarManufacturer}
        {getIcons()}
        <LicensePlate country="D" license={licencePlate} />
      </>
    );
  };

  return (
    <div className="employee">
      <SimpleCard
        className={`damage-card ${vehicleCardColor()}`}
        renderLeft={renderLeft}
        renderRight={renderRight}
        onClick={onClick}
      />
    </div>
  );
};

export default EmployeeCard;
