import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import WithSidebar from "../withSidebar";
import SideBar from '../../containers/Sidebar';

import {useSelector} from "react-redux";

const PrivateRoute = ({component: Component, isLogged, hasSidebar, ...rest}) => {

  // get logged user from store
  const {auth: { user: loggedUser } } = useSelector(state => state.auth);

  return (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to login page
    <Route {...rest} render={props => (
        loggedUser ?
              <WithSidebar component={Component} {...props}>
                <SideBar />
              </WithSidebar>
              : <Redirect to="/" />
    )} />
  );
};

export default PrivateRoute;
