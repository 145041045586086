import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {DRIVER, MANAGER, PROFILE_DELETE_REQUEST, PROFILE_GET_REQUEST, PROFILE_UPDATE_REQUEST} from "@constants";
import AlertCmp from "@components/Common/Alert";
import AnimatedLoader from '@components/Common/AnimatedLoader';
import { t } from "@helpers/i18n";
import EditPersonalInformation from "@containers/Profile/PersonalInformationTab/EditPersonalInformation";
import "./SettingsTab.styles.scss";

const SettingsTabContainer = () => {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector(state => state.profile);
  const [isAssign, setIsAssign] = useState(false);
  const { auth } = useSelector(state => state.auth);

  const [confirmPopup, setConfirmPopup] = useState(false);

  useEffect(() => {
    if (profile) {
      setIsAssign(profile.driverAssignmentRole === DRIVER);
    }
  }, [profile]);

  const onSaveClick = (userData) => {
    userData.driverAssignmentRole = isAssign
        ? DRIVER
        : MANAGER;


    dispatch({
      type: PROFILE_UPDATE_REQUEST,
      payload: {
        userData
      }
    });
    setConfirmPopup(true);
  };

  const onDeleteClick = (userId) => {
    dispatch({
      type: PROFILE_DELETE_REQUEST,
      payload: {
        userId: userId
      }
    });
    dispatch({
      type: PROFILE_GET_REQUEST,
      payload: {
        userId: userId
      }
    });
    setConfirmPopup(true);
  };

  const onToggleChange = () => {
    setIsAssign(!isAssign);
  };

  const closePopup = () => {
    setConfirmPopup(false);
  };

  const editPersonalInformationProps = { profile, auth, loading, onSaveClick, onDeleteClick, isAssign, onToggleChange };

  if (loading) {
    return <AnimatedLoader />;
  }

  return (
    <div className={'details-content p-b-2'}>
      <EditPersonalInformation {...editPersonalInformationProps} />
      <AlertCmp
        visible={confirmPopup}
        title={t('profile_updated')}
        onClick={closePopup}
      />
    </div>
  );
};

export default SettingsTabContainer;
