import * as invoicesConstants from "./constants";

export const invoiceGet = (payload) => ({type: invoicesConstants.INVOICE_GET_REQUEST, payload});
export const invoiceGetSuccess = (payload) => ({type: invoicesConstants.INVOICE_GET_SUCCESS, payload});
export const invoiceGetFail = (error) => ({type: invoicesConstants.INVOICE_GET_FAIL, error});

export const invoiceGetMore = (payload) => ({type: invoicesConstants.INVOICE_GET_MORE_REQUEST, payload});
export const invoiceGetMoreSuccess = (payload) => ({type: invoicesConstants.INVOICE_GET_MORE_SUCCESS, payload});
export const invoiceGetMoreFail = (error) => ({type: invoicesConstants.INVOICE_GET_MORE_FAIL, error});

export const invoiceDetailGet = (payload) => ({type: invoicesConstants.INVOICE_DETAIL_GET_REQUEST, payload});
export const invoiceDetailGetSuccess = (payload) => ({type: invoicesConstants.INVOICE_DETAIL_GET_SUCCESS, payload});
export const invoiceDetailGetFail = (error) => ({type: invoicesConstants.INVOICE_DETAIL_GET_FAIL, error});

export const invoicePDFGet = (payload) => ({type: invoicesConstants.INVOICE_PDF_GET_REQUEST, payload});
export const invoicePDFGetSuccess = (payload) => ({type: invoicesConstants.INVOICE_PDF_GET_SUCCESS, payload});
export const invoicePDFGetFail = (error) => ({type: invoicesConstants.INVOICE_PDF_GET_FAIL, error});
