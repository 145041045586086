import Timer from "mdi-react/TimerSandIcon";
import Recycle from "mdi-react/RecycleIcon";
import Brief from "mdi-react/BriefcaseClockOutlineIcon";
import WrenchIcon from "mdi-react/BuildIcon";
import CheckIcon from "mdi-react/CheckIcon";
import Alert from "mdi-react/AlertOutlineIcon";

/**
 * Damage icon depending on damage state
 * @param state - damage state
 * @returns {string}
 */
import {CARD_TYPES, DAMAGE_APP_STATES} from '@constants';

export const getDamageStateIcon = (state) => {
  if (state) {
    switch (DAMAGE_APP_STATES.indexOf(state.toLowerCase())) {
      case 0: return <Timer/>;
      case 1: return <Brief/>;
      case 2: return <WrenchIcon/>;
      case 3:
      case 4:
        return <CheckIcon/>;
      case 5: return <Alert/>;
      case 6: return <Recycle/>;
      default: return null;
    }
  }
  return null;
};


/**
 * Card color depending on damage state
 * @param state - damage state
 * @returns {string}
 */
export const getDamageStateCardColor = (state) => {
  if (state) {
    switch (DAMAGE_APP_STATES.indexOf(state.toLowerCase())) {
      case 0: return CARD_TYPES.RED;
      case 1: return CARD_TYPES.YELLOW;
      case 2: return CARD_TYPES.YELLOW;
      case 3: return CARD_TYPES.WHITE;
      case 4: return CARD_TYPES.GREEN_WHITE;
      case 5: return CARD_TYPES.RED;
      case 6: return CARD_TYPES.YELLOW;
      default: return CARD_TYPES.WHITE;
    }
  }
  return CARD_TYPES.WHITE;
};
