
import * as employeeConstants from './constants';
import * as employeesConstants from '@store/employees/constants';

const initialState = {
  employees: [],
  loading: false,
  loadingMore: false,
  lastPage: false,
  singleLoading: false,
  employee: null,
  employeeCarChecks: [],
  carChecksLastPage: 1,
  loadingMoreCarChecks: false,
  employeeDamages: [],
  damagesLastPage: 1,
  loadingMoreDamages: false
};

export default function employees(state = initialState, action) {
  switch (action.type) {
    case employeesConstants.EMPLOYEE_GET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case employeesConstants.EMPLOYEE_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };
    case employeesConstants.EMPLOYEE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
        lastPage: action.payload.length === 0 || action.payload[0].pageTotal - 1 === action.payload[0].pageNumber
      };

    case employeesConstants.EMPLOYEE_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        employees: [...state.employees, ...action.payload],
        lastPage: action.payload.length === 0 || action.payload[0].pageTotal - 1 === action.payload[0].pageNumber

      };

    case employeesConstants.EMPLOYEE_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case employeesConstants.EMPLOYEE_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case employeeConstants.EMPLOYEE_GET_BY_ID_REQUEST:
      return {
        ...state,
        singleLoading: true
      };

    case employeeConstants.EMPLOYEE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        employee: action.payload,
        singleLoading: false,
      };

    case employeeConstants.EMPLOYEE_GET_BY_ID_FAIL:
      return {
        ...state,
        singleLoading: false
      };

    case employeesConstants.EMPLOYEE_GET_CAR_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        employeeCarChecks: []
      };
    case employeesConstants.EMPLOYEE_GET_CAR_CHECKS_SUCCESS:
      return {
        ...state,
        loading: false,
        carChecksLastPage: action.payload[0]?.pageTotal,
        employeeCarChecks: action.payload
      };
    case employeesConstants.EMPLOYEE_GET_CAR_CHECKS_FAIL:
      return {
        ...state,
        loading: false
      };

    case employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_REQUEST:
      return {
        ...state,
        loadingMoreCarChecks: true
      };
    case employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_SUCCESS:
      return {
        ...state,
        loadingMoreCarChecks: false,
        carChecksLastPage: action.payload[0]?.pageTotal,
        employeeCarChecks: [...state.employeeCarChecks, ...action.payload]
      };
    case employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_FAIL:
      return {
        ...state,
        loadingMoreCarChecks: false
      };

    case employeesConstants.EMPLOYEE_GET_DAMAGES_REQUEST:
      return {
        ...state,
        loading: true,
        employeeDamages: []
      };
    case employeesConstants.EMPLOYEE_GET_DAMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        damagesLastPage: action.payload[0]?.pageTotal,
        employeeDamages: action.payload
      };
    case employeesConstants.EMPLOYEE_GET_DAMAGES_FAIL:
      return {
        ...state,
        loading: false
      };

    case employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_REQUEST:
      return {
        ...state,
        loadingMoreDamages: true
      };
    case employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_SUCCESS:
      return {
        ...state,
        loadingMoreDamages: false,
        damagesLastPage: action.payload[0]?.pageTotal,
        employeeDamages: [...state.employeeDamages, ...action.payload]
      };
    case employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_FAIL:
      return {
        ...state,
        loadingMoreDamages: false
      };

    default:
      return state;
  }
}
