import { tickets } from "./tickets";
import { auth } from "./auth";
import { map } from "./map";
import { users } from "./users";
import { employees } from "./employees";
import { damages } from "./damages";
import { vehicles } from "./vehicles";
import { carchecks } from "./carchecks";
import { appointments } from "./appointments";
import { invoices } from "./invoices";
import { trips } from "./trips";
import { garages } from "./garages";
import { media } from "./media";
import {reporting} from './reporting';
import {timeTracking} from "./timeTracking";
import {dailyRent} from "./dailyRent";
import {contracts} from "./contracts";

export const Api = {
  tickets,
  auth,
  map,
  users,
  employees,
  damages,
  vehicles,
  carchecks,
  appointments,
  invoices,
  trips,
  media,
  reporting,
  garages,
  timeTracking,
  dailyRent,
  contracts
};
