import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";

import { ToastContainer } from "react-toastify";
import AppRouter from '../../routes/index';

import * as appActions from "@store/app/actions";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const AppContainer = () => {

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(appActions.appStart());
  }, []);

  return (
    <div className={'app-wrapper'}>
      <AppRouter />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
      />
    </div>
  );
};


export default AppContainer;
