import React from 'react';

import TagItem from './TagItem/TagItem.component';
import { t } from '@helpers/i18n';

const TagsList = ({ tags, onRemove }) => {
  return (
    <div className="tags-container">
      <p className={'tag-list-label'}>{t('tag-list-label')}</p>
      <div className={'tags-list'}>
        {tags.map((tag, index) => {
          return <TagItem key={index} tag={tag} onRemove={onRemove} />;
        })}
      </div>
    </div>
  );
};

export default TagsList;
