import React, { useState } from 'react';
import classNames from 'classnames';
import './ButtonGroup.styles.scss';

const ButtonGroup = ({ buttonGroup = [], className = '', showOptions = true }) => {
  const [firstAction, ...otherOptions] = buttonGroup;
  const [showButtonOptions, setShowButtonOptions] = useState(false);

  /** Toggle First Button list visibility **/
  const toggleVisibility = () => {
    setShowButtonOptions(!showButtonOptions);
  };

  return (
    <div className={classNames('split-cmp', className)}>
      <div
        id="split-btn-popover"
        className={classNames('split-btn-popover popover js-popover js-split-btn js-tab-focus', {
          'popover--is-visible': showButtonOptions
        })}
        role="dialog">
        <menu className="split-btn-popover__list">
          {otherOptions.map((option, index) => {
            return (
              <li key={index}>
                <button className="reset split-btn-popover__item" onClick={option.action}>
                  {option.icon}
                  <span className={'button-text'}>{option.label}</span>
                </button>
              </li>
            );
          })}
        </menu>
      </div>
      <div className={`split-btn flex`}>
        <button
          className={classNames('btn btn-primary assign-btn', {
            'bottom-button-visible-popover': showButtonOptions,
            singleButton: !showOptions
          })}
          onClick={firstAction.action}>
          {firstAction.icon}
          <span className={'button-text'}>{firstAction.label}</span>
        </button>

        <span className="split-btn__separator" aria-hidden="true" />

        {showOptions && (
          <button
            className={`btn btn--primary padding-x-xs block split-arrow`}
            onClick={toggleVisibility}
            aria-controls="split-btn-popover">
            <svg className={classNames('icon icon--xss', { 'icon-rotate': showButtonOptions })} viewBox="0 0 12 12">
              <polyline
                points="1 4 6 9 11 4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonGroup;
