import React from 'react';
import CmpDatePicker from '@components/Common/DatePicker';
import { t } from '@helpers/i18n';

const FilterDatePicker = (props) => {
  const { dateFrom, dateTo, onDateChange } = props;

  return (
    <>
      <CmpDatePicker
        filterDatePicker
        name={'dateFrom'}
        onChange={onDateChange}
        value={dateFrom}
        maxDate={new Date()}
        peekNextMonth
        isClearable
      />
      <span className="end">{t('filter_and')}</span>
      <CmpDatePicker
        filterDatePicker
        name={'dateTo'}
        onChange={onDateChange}
        value={dateTo}
        maxDate={new Date()}
        minDate={new Date(dateFrom)}
        peekNextMonth
        isClearable
      />
    </>
  );
};
export default FilterDatePicker;
