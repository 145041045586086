import * as contractConstants from './constants';

const initialState = {
  contracts: [],
  contractDetails: null,
  loading: false,
  loadingMore: false,
  lastPage: 0
};

export default function contracts(state = initialState, action) {
  switch (action.type) {
    case contractConstants.CONTRACT_GET_REQUEST:
      return {
        ...state,
        loading: true,
        contracts: []
      };

    case contractConstants.CONTRACT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        contracts: action.payload,
        lastPage: action.payload.length === 0 || action.payload[0]?.pageTotal - 1 === action.payload[0]?.pageNumber
      };

    case contractConstants.CONTRACT_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case contractConstants.CONTRACT_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case contractConstants.CONTRACT_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        contracts: [...state.contracts, ...action.payload],
        lastPage: action.payload.length === 0 || action.payload[0]?.pageTotal - 1 === action.payload[0]?.pageNumber
      };

    case contractConstants.CONTRACT_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case contractConstants.CONTRACT_GET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case contractConstants.CONTRACT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contractDetails: action.payload
      };

    case contractConstants.CONTRACT_GET_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        contractDetails: {}
      };

    case contractConstants.CONTRACT_DETAILS_RESET:
      return {
        ...state,
        contractDetails: null
      };

    default:
      return state;
  }
};
