import MapContainer from "@containers/Map";
import ContractListHolder from "@containers/Contracts/ContractList/ContractListHolder";

const ContractList = () => {

  return (
    <div id={'contract-list'} className={'list-wrapper'}>
      <MapContainer />
      <ContractListHolder />
    </div>
  );
};

export default ContractList;
