import React, { useEffect, useState } from 'react';
import SearchBarFilter from '@components/Common/SearchBar/withFilters';
import ContractCardList from '@components/Contracts/ContractCardList/ContractCardList.component';
import { ContractsQueryFormatter } from '@services/formatters/contracts/ContractsFilter.formatter';
import ContractFilter from '@components/Contracts/Filter/ContractFilter.component';
import { useHistory } from 'react-router-dom';

const filterInitialState = [
  { id: 0, filter: 'contractsFilters.show_all', value: true },
  { id: 1, filter: 'contractsFilters.active', value: true },
  { id: 2, filter: 'contractsFilters.past', value: true },
  { id: 3, filter: 'contractsFilters.contractsBetween', value: true }
];

const ContractListWrapper = ({
  contracts = [],
  loading = false,
  applyContractFilter = () => {},
  endReached = () => {},
  lastPage = false
}) => {
  const [contractsSearch, setContractsSearch] = useState('');
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [settings, setSettings] = useState(filterInitialState);
  const [contractDates, setContractDates] = useState({
    dateFrom: null,
    dateTo: null
  });
  const history = useHistory();

  useEffect(() => {
    applyContractFilter({
      page: page,
      filter: '',
      dateFrom: null,
      dateTo: null,
      search: ''
    });
  }, []);

  const filterVisibilityChange = () => {
    setShowFilter(!showFilter);
  };

  const onFilterChange = (values) => {
    setSettings(values);
    !settings[3].value && setContractDates({ dateFrom: null, dateTo: null });
  };

  const onContractsSearch = (search = '') => {
    setContractsSearch(search);
    setPage(0);
    const query = ContractsQueryFormatter(settings);
    applyContractFilter({
      page: 0,
      filter: query,
      search: search,
      dateFrom: contractDates.dateFrom,
      dateTo: contractDates.dateTo
    });
  };

  const applyFilter = () => {
    const query = ContractsQueryFormatter(settings);
    setPage(0);
    applyContractFilter({
      page: 0,
      filter: query,
      dateFrom: contractDates.dateFrom,
      dateTo: contractDates.dateTo,
      search: contractsSearch
    });
  };

  const onEndReached = () => {
    const query = ContractsQueryFormatter(settings);
    setPage((page) => page + 1);
    endReached({
      page: page + 1,
      filter: query,
      dateFrom: contractDates.dateFrom,
      dateTo: contractDates.dateTo,
      search: contractsSearch
    });
  };

  const onDateChange = (type, value) => {
    setContractDates({
      ...contractDates,
      [type]: value
    });
  };

  const onContractClick = (id) => {
    history.push(`/contracts/${id}`);
  };

  const contractFilterProps = {
    visible: showFilter,
    filterValues: settings,
    contractDates: contractDates,
    onDateChange: onDateChange,
    filterChanged: onFilterChange,
    applyFilter: applyFilter
  };

  return (
    <div className="list-view grow">
      <SearchBarFilter
        search={contractsSearch}
        onSearch={(e) => onContractsSearch(e.target.value)}
        onFilterPress={filterVisibilityChange}
        onClose={() => onContractsSearch('')}>
        <ContractFilter {...contractFilterProps} />
      </SearchBarFilter>
      <ContractCardList
        loading={loading}
        isLastPage={lastPage}
        contracts={contracts}
        isEndReached={onEndReached}
        onContractClick={onContractClick}
      />
    </div>
  );
};

export default ContractListWrapper;
