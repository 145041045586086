import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGE_BG, LANGUAGE_DE, LANGUAGE_EN, LANGUAGE_PL, LANGUAGE_RO, LANGUAGE_TR } from '@constants';

/** load components **/
import DateInput from '@components/CarCheck/Filter/DateInput';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
/** load styles **/
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.styles.scss';

import { de, enGB, bg, pl, tr, ro } from 'date-fns/locale';
import { DATE_FORMATS } from '@helpers/constants';
import classnames from 'classnames';

const CmpDatePicker = ({
  label = '',
  name,
  value,
  onChange = () => {},
  maxDate,
  minDate,
  filterDatePicker = false,
  peekNextMonth = false,
  isClearable = false,
  onClickOutside = () => {},
  customLabelStyle = {}
}) => {
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    registerLocale(LANGUAGE_DE, de);
    registerLocale(LANGUAGE_EN, enGB);
    registerLocale(LANGUAGE_BG, bg);
    registerLocale(LANGUAGE_PL, pl);
    registerLocale(LANGUAGE_TR, tr);
    registerLocale(LANGUAGE_RO, ro);

    setDefaultLocale(LANGUAGE_DE, de);
  }, []);

  return (
    <div className={classnames({ 'cmp-date-picker': !filterDatePicker })}>
      {label && (
        <p className={'label picker-label'} style={customLabelStyle}>
          {label}
        </p>
      )}
      <DatePicker
        selected={(value && value !== '0000-00-00' && new Date(value)) || null}
        onClickOutside={() => !value && onClickOutside()}
        showMonthDropdown
        showYearDropdown
        peekNextMonth={peekNextMonth}
        isClearable={isClearable}
        dropdownMode={'select'}
        maxDate={maxDate || null}
        minDate={minDate || null}
        customInput={<DateInput />}
        locale={profile.language}
        dateFormat={DATE_FORMATS.full_DMY_dot}
        required
        onChange={(val) => {
          onChange(name, val);
        }}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          }
        }}
      />
    </div>
  );
};

export default CmpDatePicker;
