import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import Grid from '@mui/material/Grid';

import { EmployeeDetails, EmployeeMap } from "@components/Employee";
import * as vehicleActions from "@store/vehicles/actions";

const EmployeeDetailsContainer = () => {
  const dispatch = useDispatch();
  const { employee } = useSelector(state => state.employees);
  const { generalInfo } = useSelector(state => state.vehicles);

  const {
    id,
    email,
    birthday,
    phone_company,
    car,
    address
  } = employee || { id: '', email: '', birthday: '', phone_company: '', car: null, address: null };

  const getAddress = (address) => {
    if (!address) {
      return '-';
    }

    const { street, number, city, zipcode } = address;

    if (street || number || city || zipcode) {
      return (
        <div className="personal-workplace_address">
          <p className="address_place">{`${street} ${number}`}</p>
          <p className="address_place">{`${zipcode} ${city}`}</p>
        </div>
      );
    } 
    
    return '-';
  };



  const employeeDetailsProps = { id, email, birthday, phone_company, car, builtAddress: getAddress(address) };
  const employeeMapProps = { vehicle: car, generalInfo };


  useEffect(() => {
    if (car?.id) {
      dispatch(vehicleActions.loadVehicleLocation({ id: car.id }));
      dispatch(vehicleActions.vehicleGeneralInfo({ id: car.id }));
    } else {
      dispatch(vehicleActions.loadVehicleLocationFail());
    }
  }, []);

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={7}>
        <EmployeeDetails {...employeeDetailsProps} />
      </Grid>
      <Grid item xs={5}>
        <EmployeeMap {...employeeMapProps} />
      </Grid>
    </Grid>
  );
};

export default EmployeeDetailsContainer;
