import * as employeesConstants from '@store/employees/constants';

export const employeeGet = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_REQUEST, payload });
export const employeeGetSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_SUCCESS, payload });
export const employeeGetFail = (error) => ({ type: employeesConstants.EMPLOYEE_GET_FAIL, error });

export const employeeLoadMore = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_REQUEST, payload });
export const employeeLoadMoreSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_SUCCESS, payload });
export const employeeLoadMoreFail = (error) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_FAIL, error });

export const employeeGetById = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_BY_ID_REQUEST, payload });
export const employeeGetByIdSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_BY_ID_SUCCESS, payload });
export const employeeGetByIdFail = (error) => ({ type: employeesConstants.EMPLOYEE_GET_BY_ID_FAIL, error });

export const employeeGetCarChecks = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_CAR_CHECKS_REQUEST, payload });
export const employeeGetCarChecksSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_CAR_CHECKS_SUCCESS, payload });
export const employeeGetCarChecksFail = (error) => ({ type: employeesConstants.EMPLOYEE_GET_CAR_CHECKS_FAIL, error });

export const employeeLoadMoreCarChecks = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_REQUEST, payload });
export const employeeLoadMoreCarChecksSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_SUCCESS, payload });
export const employeeLoadMoreCarChecksFail = (error) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_CAR_CHECKS_FAIL, error });

export const employeeGetDamages = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_DAMAGES_REQUEST, payload });
export const employeeGetDamagesSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_GET_DAMAGES_SUCCESS, payload });
export const employeeGetDamagesFail = (error) => ({ type: employeesConstants.EMPLOYEE_GET_DAMAGES_FAIL, error });

export const employeeLoadMoreDamages = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_REQUEST, payload });
export const employeeLoadMoreDamagesSuccess = (payload) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_SUCCESS, payload });
export const employeeLoadMoreDamagesFail = (error) => ({ type: employeesConstants.EMPLOYEE_LOAD_MORE_DAMAGES_FAIL, error });