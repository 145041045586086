import React from 'react';

/** load components **/
import InfoBlock from '@components/User/InfoBlock';
import Avatar from '@components/User/Avatar';
import { LicensePlate } from '@components/Car/LicensePlate';

const ProfileInfoBlock = ({
  name = '',
  surname = '',
  company = '',
  customerNumber = '',
  avatar = '',
  licensePlate = '',
  missingIcon
}) => {
  return (
    <InfoBlock
      name={`${name} ${surname}`}
      customerName={company}
      customerNumber={customerNumber}
      avatarElement={<Avatar admin={true} src={avatar ? avatar : ''} missingIcon={missingIcon ? missingIcon : ''} />}
      bottomElement={<LicensePlate country="D" license={licensePlate} />}
    />
  );
};

export default ProfileInfoBlock;
