import * as appointmentConstants from "@store/appointments/constants";

export const appointmentsGet = (payload) => ({type: appointmentConstants.APPOINTMENT_GET_ALL_REQUEST, payload});
export const appointmentsGetSuccess = (payload) => ({type: appointmentConstants.APPOINTMENT_GET_ALL_SUCCESS, payload});
export const appointmentsGetFail = (error) => ({type: appointmentConstants.APPOINTMENT_GET_ALL_FAIL, error});


export const appointmentsLoadMore = (payload) => ({ type: appointmentConstants.APPOINTMENTS_LOAD_MORE_REQUEST, payload });
export const appointmentsMoreSuccess = (payload) => ({ type: appointmentConstants.APPOINTMENTS_LOAD_MORE_SUCCESS, payload });
export const appointmentsLoadMoreFail = (error) => ({ type: appointmentConstants.APPOINTMENTS_LOAD_MORE_FAIL, error });

export const setMapAppointment = (payload) => ({ type: appointmentConstants.SET_MAP_APPOINTMENT, payload });
export const updateMapAppointment = (payload) => ({ type: appointmentConstants.UPDATE_MAP_APPOINTMENT, payload });

export const appointmentCancel = (payload) => ({ type: appointmentConstants.APPOINTMENT_CANCEL_REQUEST, payload });
export const appointmentCancelSuccess = (payload) => ({ type: appointmentConstants.APPOINTMENT_CANCEL_SUCCESS, payload });
export const appointmentCancelFail = (error) => ({ type: appointmentConstants.APPOINTMENT_CANCEL_FAIL, error });

export const appointmentCreate = (payload) => ({type: appointmentConstants.APPOINTMENT_CREATE_REQUEST, payload});
export const appointmentCreateSuccess = (payload) => ({type: appointmentConstants.APPOINTMENT_CREATE_SUCCESS, payload});
export const appointmentCreateFail = (error) => ({type: appointmentConstants.APPOINTMENT_CREATE_FAIL, error});

export const calendarAppointmentsGet = (payload) => ({type: appointmentConstants.APPOINTMENT_CALENDAR_REQUEST, payload});
export const calendarAppointmentsSuccess = (payload) => ({type: appointmentConstants.APPOINTMENT_CALENDAR_SUCCESS, payload});
export const calendarAppointmentsFail = (error) => ({type: appointmentConstants.APPOINTMENT_CALENDAR_FAIL, error});
