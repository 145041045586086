import {intervalToDuration, parseISO} from 'date-fns';

/** Formats Trip track into appropriate format
 * @param {number} firstDate
 * @param {number} secondDate
 * **/
const tripTimeFormatter = ( firstDate, secondDate) => {
  const { days, hours, minutes } = intervalToDuration({ start: parseISO(firstDate), end: parseISO(secondDate) });
  const day = days ? `${days} d` : '';
  const hour = hours ? `${hours} h` : '';
  const min = minutes ? `${minutes} min` : '0 min';
  return `${day} ${hour} ${min}`;
};

/** Formats Trip track into appropriate format
 * @param {number} mileage
 * **/
const tripMileageFormatter = (mileage) => {
  if (mileage) {
    const formattedMileage = mileage.toString().replace(/\./g, "");
    return `${formattedMileage}`;
  }
  return `0`;
};
export {
  tripTimeFormatter,
  tripMileageFormatter
};
